import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './SelfAssessmentHeader.scss'

const SelfAssessmentHeader = () => {
  return (
    <header className='self-assessment-header'>
      <Navbar expand="lg" bg="dark">
          <Navbar.Brand>OTOS</Navbar.Brand>
      </Navbar>
    </header>
  );
}


export default SelfAssessmentHeader;
