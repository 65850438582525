import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import "./MultiStepProgressBar.scss";

const MultiStepProgressBar = props => {

  const stepPercentage = (props.currentStep - 1)/(props.numberOfSteps - 1) * 100;

  return (
    <ProgressBar percent={stepPercentage}>
        {[...Array(props.numberOfSteps)].map((x, i) => {
          return (
              <div key={i} className={`indexedStep ${props.currentStep >= i+1 ? "stepCompleted" : ""}`}>
                  {i + 1}
              </div>
          )
        })}
    </ProgressBar>
  );
};

export default MultiStepProgressBar;