import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select';
import { RxCross1 } from "react-icons/rx";

import "./Operations.scss"

const rotatorCuffCompleteTearProcedureOptions = [
  {value: "Debridement", label: "Debridement"},
  {value: "Margin convergence", label: "Margin convergence"},
  {value: "Excision of calcification", label: "Excision of calcification"},
  {value: "Single row repair", label: "Single row repair"},
  {value: "Double row repair", label: "Double row repair"},
  {value: "Rip Stop repair", label: "Rip Stop repair"},
  {value: "Triple row repair", label: "Triple row repair"},
  {value: "Transosseous repair", label: "Transosseous repair"},
  {value: "Soft tissue augmentation", label: "Soft tissue augmentation"},
  {value: "Partial repair", label: "Partial repair"},
  {value: "Anterior slide", label: "Anterior slide"},
  {value: "Posterior slide", label: "Posterior slide"},
  {value: "Insertion of synthetic subacromial spacer", label: "Insertion of synthetic subacromial spacer"},
]

const rotatorCuffCompleteTearAnchorRepairTechniqueUsedOptions = [
  {value: "Single row", label: "Single Row"},
  {value: "Double row", label: "Double row"},
  {value: "Knots", label: "Knots"},
  {value: "Knotless", label: "Knotless"},
  {value: "SutureBridge", label: "SutureBridge"},
  {value: "SpeedBridge - Additional medial knots tied", label: "SpeedBridge - Additional medial knots tied"},
  {value: "SpeedBridge - No additional medial fixation", label: "SpeedBridge - No additional medial fixation"},
  {value: "SpeedBridge - Additional knotless medial fixation", label: "SpeedBridge - Additional knotless medial fixation"},
  {value: "SpeedFix", label: "SpeedFix"},
  {value: "CuffMend", label: "CuffMend"},
  {value: "Regeneten", label: "Regeneten"}
]

const rotatorCuffTearImplantsOptions = [
  {value: "SwiveLock", label:"SwiveLock"},
  {value: "SwiveLock Knotless", label:"SwiveLock Knotless"},
  {value: "Corkscrew Knotted", label:"Corkscrew Knotted"},
  {value: "Corkscrew Knotless", label:"Corkscrew Knotless"},
  {value: "FiberTak Knotted", label:"FiberTak Knotted"},
  {value: "FiberTak Knotless", label:"FiberTak Knotless"},
  {value: "TissueTak", label:"TissueTak"},
  {value: "PushLock", label:"PushLock"},
  {value: "AFLEX402", label:"AFLEX402"},
  {value: "AFLEX403", label:"AFLEX403"},
  {value: "ArthroFLEX other ", label:"ArthroFLEX other"},
  {value: "Xenograft", label:"Xenograft"},
  {value: "Biceps Autograft", label:"Biceps Autograft"},
  {value: "2.6 FiberTak RC Soft Anchor", label:"2.6 FiberTak RC Soft Anchor"},
  {value: "Knotless 2.6 FiberTak RC Soft Anchor", label:"Knotless 2.6 FiberTak RC Soft Anchor"},
  {value: "FiberStitch", label:"FiberStitch"},
  {value: "Biocomposite Pushlock SP (4.5mm x28mm)", label:"Biocomposite Pushlock SP (4.5mm x28mm)"},
]

const rotatorCuffPartialTearProcedureOptions = [
  {value: "Debridement", label: "Debridement"},
  {value: "Excision of calcification", label: "Excision of calcification"},
  {value: "Repair - Transtendon", label: "Repair - Transtendon"},
  {value: "Repair - Take down and repair", label: "Repair - Take down and repair"},
]

const rotatorCuffPartialTearAnchorRepairTechniqueUsedOptions = [
  {value: "Single row", label: "Single Row"},
  {value: "Double row", label: "Double row"},
  {value: "SutureBridge", label: "SutureBridge"},
  {value: "SpeedBridge - Additional medial knots tied", label: "SpeedBridge - Additional medial knots tied"},
  {value: "SpeedBridge - No additional medial fixation", label: "SpeedBridge - No additional medial fixation"},
  {value: "SpeedBridge - Additional knotless medial fixation", label: "SpeedBridge - Additional knotless medial fixation"},
  {value: "SpeedFix", label: "SpeedFix"},
  {value: "PASTA Knotted interconnected", label: "PASTA Knotted interconnected"},
  {value: "PASTA Knotted independent mattress(es)", label: "PASTA Knotted independent mattress(es)"},
  {value: "PASTA Knotless interconnected ", label: "PASTA Knotless interconnected "},
  {value: "PASTA Knotless independent mattress(es)", label: "PASTA Knotless independent mattress(es)"},
  {value: "CuffMend", label: "CuffMend"},
  {value: "Regeneten", label: "Regeneten"}
]

const concomitantProcedureOptions = [
  {value: "Biceps-left alone", label: "Biceps-left alone"},
  {value: "Biceps debridement", label: "Biceps debridement"},
  {value: "Biceps tenotomy", label: "Biceps tenotomy"},
  {value: "Biceps tenodesis", label: "Biceps tenodesis"},
  {value: "Infraspinatus repair", label: "Infraspinatus repair"},
  {value: "Subscapularis repair", label: "Subscapularis repair"},
]

const subscapProcedureOptions = [
  {value: "Debridement", label: "Debridement"},
  {value: "Excision of calcification", label: "Excision of calcification"},
  {value: "Rip Stop repair", label: "Rip Stop repair"},
  {value: "Single row repair", label: "Single row repair"},
  {value: "Double row repair", label: "Double row repair"},
  {value: "Transosseous repair", label: "Transosseous repair"},
  {value: "Soft tissue augmentation", label: "Soft tissue augmentation"},
]

const subscapAnchorRepairTechniqueUsedOptions = [
  {value: "Knots", label: "Knots"},
  {value: "Knotless", label: "Knotless"},
  {value: "SutureBridge", label: "SutureBridge"},
  {value: "SpeedBridge - Medial knots tied", label: "SpeedBridge - Medial knots tied"},
  {value: "SpeedBridge - Medial knots not tied", label: "SpeedBridge - Medial knots not tied"},
  {value: "SpeedFix", label: "SpeedFix"},
]

const tendonTransferProcedureOptions = [
  {value: "Pectoralis Major to Serratus", label: "Pectoralis Major to Serratus"},
  {value: "Pectoralis Major to Subscapularis - Anterior to conjoint tendon approach", label: "Pectoralis Major to Subscapularis - Anterior to conjoint tendon approach"},
  {value: "Pectoralis Major to Subscapularis - Posterior to conjoint tendon approach", label: "Pectoralis Major to Subscapularis - Posterior to conjoint tendon approach"},
  {value: "Latissimus Dorsi to Post Cuff - Posterior Approach (Gerber)", label: "Latissimus Dorsi to Post Cuff - Posterior Approach (Gerber)"},
  {value: "Latissimus Dorsi to Post Cuff - Anterior approach with Teres Major (Boileau)", label: "Latissimus Dorsi to Post Cuff - Anterior approach with Teres Major (Boileau)"},
  {value: "Lower Trapezius Tendon Transfer", label: "Lower Trapezius Tendon Transfer"},
]

const bicepsProcedureOptions = [
  {value: "Debridement", label: "Debridement"},
  {value: "Tenotomy", label: "Tenotomy"},
  {value: "Tenodesis", label: "Tenodesis"},
]

const bicepsTechniquesAndFixationDeviceOptions = [
  {value: "Top of Groove", label: "Top of Groove"},
  {value: "Suprapectoral", label: "Suprapectoral"},
  {value: "Subpectoral", label: "Subpectoral"},
  {value: "Soft Tissue (Suture)", label: "Soft Tissue (Suture)"},
  {value: "Anchor", label: "Anchor"},
  {value: "Screw", label: "Screw"},
  {value: "Button", label: "Button"},
  {value: "Loop n Tack", label: "Loop n Tack"},
  {value: "Other", label: "Other"},
  {value: "tendon in socket", label: "tendon in socket"},
  {value: "tendon onlay", label: "tendon onlay"},
  {value: "bicortical", label: "bicortical"},
  {value: "unicortical", label: "unicortical"},
]

const acJointStabilizationProcedureOptions = [
  {value: "TightRope technique", label: "TightRope technique"},
  {value: "DogBone technique (DogBone buttons + FiberTape)", label: "DogBone technique (DogBone buttons + FiberTape)"},
  {value: "DogBone with Graft Augmentation (fill in graft details)", label: "DogBone with Graft Augmentation (fill in graft details)"},
  {value: "DogBone with Distal Clavicle Plate", label: "DogBone with Distal Clavicle Plate"},
  {value: "ACCR/Chronic AC joint reconstruction", label: "ACCR/Chronic AC joint reconstruction"},
  {value: "Modified Weaver Dunn", label: "Modified Weaver Dunn"},
  {value: "AC InternalBrace™ ligament augmentation with coracoclavicular ligament fixation ", label: "AC InternalBrace™ ligament augmentation with coracoclavicular ligament fixation "},
]

const orthobiologicDetailOptions = [
  {value: "Blood", label: "Blood"},
  {value: "Plasma", label: "Plasma"},
  {value: "PRP-Plasma Based System ", label: "PRP-Plasma Based System "},
  {value: "PRP-Buffy Coat System ", label: "PRP-Buffy Coat System "},
  {value: "Bone Marrow Aspirate", label: "Bone Marrow Aspirate"},
  {value: "Bone Marrow Aspirate Concentrate (Fill in CFU below if known)", label: "Bone Marrow Aspirate Concentrate (Fill in CFU below if known)"},
  {value: "Adipose Tissue Aspirate", label: "Adipose Tissue Aspirate"},
  {value: "Adipose Tissue Concentrate", label: "Adipose Tissue Concentrate"},
]

const orthobiologicManufacturerOptions = [
  {value: "Arthrex - ACP", label: "Arthrex - ACP"},
  {value: "Arthrex - Angel", label: "Arthrex - Angel"},
]

const clavicleFractureProcedureOptions = [
  {value: "Pin", label: "Pin"},
  {value: "Plate", label: "Plate"},
  {value: "Intramedullary fixation", label: "Intramedullary fixation"},
  {value: "Cerclage", label: "Cerclage"},
  {value: "Other type fixation", label: "Other type fixation"},
]

const greaterTuberosityFractureProcedureOptions = [
  {value: "Screw", label: "Screw"},
  {value: "Other type fixation", label: "Other type fixation"},
]

const proximalHumerusFractureProcedureOptions = [
  {value: "Screw", label: "Screw"},
  {value: "Other type fixation", label: "Other type fixation"},
]

const glenoidFractureProcedureOptions = [
  {value: "Percutaneous Pinning", label: "Percutaneous Pinning"},
  {value: "Plate", label: "Plate"},
  {value: "IM Implant", label: "IM Implant"},
  {value: "Hemiarthroplasty for fracture", label: "Hemiarthroplasty for fracture"},
  {value: "Cerclage", label: "Cerclage"},
]

const scapulaFractureProcedureOptions = [
  {value: "Plate", label: "Plate"},
  {value: "Screw", label: "Screw"},
  {value: "Cerclage", label: "Cerclage"},
]

const capsularReleaseOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Pancapsular", label: "Pancapsular"},
]

const scJointOptions = [
  {value: "Irrigation/Debridement (I&D)", label: "Irrigation/Debridement (I&D)"},
  {value: "Arthroscopic debridement", label: "Arthroscopic debridement"},
  {value: "Closed reduction", label: "Closed reduction"},
  {value: "Open reduction", label: "Open reduction"},
  {value: "Reconstruction", label: "Reconstruction"},
]

const patientPositionOptions = [
  {value: "Lateral", label: "Lateral"},
  {value: "Prone", label: "Prone"},
  {value: "Supine", label: "Supine"},
]

const arthroscopicPortalOptions = [
  {value: "Proximal anteromedial", label: "Proximal anteromedial"},
  {value: "Proximal anterolateral", label:"Proximal anterolateral"},
  {value: "Anterolateral", label: "Anterolateral"},
  {value: "Direct lateral (softspot)", label: "Direct lateral (softspot)"},
  {value: "Proximal posterior central", label: "Proximal posterior central"},
  {value: "Proximal posterolateral", label: "Proximal posterolateral"},
  {value: "Distal ulnar", label: "Distal ulnar"}, 
]

const epicondylarProcedureOptions = [
  {value: "FAST procedure", label: "FAST procedure"},
  {value: "Debridement with bone tunnels and suture reattachment", label: "Debridement with bone tunnels and suture reattachment"},
  {value: "Debridement with anchor reattachment", label: "Debridement with anchor reattachment"},
  {value: "Debridement with tendon graft and anchor reattachment", label: "Debridement with tendon graft and anchor reattachment"},
  {value: "Release", label: "Release"},
]

const distalBicepsProcedureOptions = [
  {value: "2 incision", label: "2 incision"},
  {value: "Brachilias tenodesis", label: "Brachilias tenodesis"},
  {value: "Tension slide technique ", label: "Tension slide technique "},
  {value: "Tubersosity tenodesis fixation", label: "Tubersosity tenodesis fixation"},
  {value: "Tuberosity anchor fixation", label: "Tuberosity anchor fixation"},
  {value: "Tuberosity suture fixation", label: "Tuberosity suture fixation"},
  {value: "Reconstruction", label: "Reconstruction"},
]

const tricepsProcedureOptions = [
  {value: "Partial repair", label: "Partial repair"},
  {value: "Complete repair with anchors", label: "Complete repair with anchors"},
  {value: "Complete repair with tunnels", label: "Complete repair with tunnels"},
  {value: "Double row repair", label: "Double row repair"},
]

const collateralLigamentsProcedureTypeOptions = [
  {value: "Repair with suture", label: "Repair with suture"},
  {value: "Repair with anchors", label: "Repair with anchors"},
  {value: "Repair with bone tunnels and suture", label: "Repair with bone tunnels and suture"},
  {value: "Repair with tenodesis screw", label: "Repair with tenodesis screw"},
  {value: "Reconstruction", label: "Reconstruction"},
  {value: "InternalBrace augmentation", label: "InternalBrace augmentation"},
]

const anteriorPosteriosOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
]

const capsuleSynoviumProcedureOptions = [
  {value: "Capsulectomy", label: "Capsulectomy"},
  {value: "Capsular release", label: "Capsular release"},
  {value: "Lysis of adhesions", label: "Lysis of adhesions"},
  {value: "Synovectomy", label: "Synovectomy"},
  {value: "Plica excision", label: "Plica excision"},
]

const capsuleSynoviumLocationOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Medial", label: "Medial"},
]

const elbowNerveProcedureOptions = [
  {value: "Ulnar nerve release in situ", label: "Ulnar nerve release in situ"},
  {value: "Ulnar nerve subcutaneous transposition", label: "Ulnar nerve subcutaneous transposition"},
  {value: "Ulnar nerve submuscular transposition", label: "Ulnar nerve submuscular transposition"},
  {value: "Radial tunnel release", label: "Radial tunnel release"},
  {value: "Median nerve release", label: "Median nerve release"},
]

const Operations = ({ form, formData, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "rotatorCuffCompleteTear": {
      return (
        <div className="operations-rotator-cuff-complete-tear">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff Complete Tear
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedures</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffCompleteTearProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={rotatorCuffCompleteTearProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Anchor repair technique used</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffCompleteTearAnchorRepairTechniqueUsedOptions.filter(option => formData.anchorRepairTechniqueUsed.split(",").includes(option.value))}
                        name="procedures"
                        options={rotatorCuffCompleteTearAnchorRepairTechniqueUsedOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "anchorRepairTechniqueUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Implants Complete Tear </Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffTearImplantsOptions.filter(option => formData.rotatorCuffTearImplants.split(",").includes(option.value))}
                        name="procedures"
                        options={rotatorCuffTearImplantsOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "rotatorCuffTearImplants", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Number of Anchors Used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.numberOfAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "rotatorCuffPartialTear": {
      return (
        <div className="operations-rotator-cuff-partial-tear">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff Partial Tear
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                     <Col>
                      <Form.Label>Procedures</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffPartialTearProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={rotatorCuffPartialTearProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Anchor Repair Technique Used</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffPartialTearAnchorRepairTechniqueUsedOptions.filter(option => formData.anchorRepairTechniqueUsed.split(",").includes(option.value))}
                        name="procedures"
                        options={rotatorCuffPartialTearAnchorRepairTechniqueUsedOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "anchorRepairTechniqueUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                     <Col>
                      <Form.Label>Implants Partial Tear</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffTearImplantsOptions.filter(option => formData.rotatorCuffTearImplants.split(",").includes(option.value))}
                        name="procedures"
                        options={rotatorCuffTearImplantsOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "rotatorCuffTearImplants", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Number of Anchors Used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.numberOfAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "superiorCapsuleReconstruction": {
      return (
        <div className="operations-superior-capsule-reconstruction">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Superior Capsule Reconstruction
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid Side Graft Fixation</Form.Label>
                      <Form.Select
                        value={formData.glenoidSideGraftFixation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidSideGraftFixation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Medial Pulley - 2 SutureTaks">Medial Pulley - 2 SutureTaks</option>
                        <option value="Medial Pulley - 2 SutureTaks with PushLock(s)">Medial Pulley - 2 SutureTaks with PushLock(s)</option>
                        <option value="PASTA Bridge - SwiveLock with 2 SutureTaks">PASTA Bridge - SwiveLock with 2 SutureTaks</option>
                        <option value="Knotless SutureTak">Knotless SutureTak</option>
                        <option value="2-3 SwiveLocks with LabralTape">2-3 SwiveLocks with LabralTape</option>
                        <option value="2-3 PushLocks with LabralTape">2-3 PushLocks with LabralTape</option>
                        <option value="Medial Pulley - 2 Corkscrews">Medial Pulley - 2 Corkscrews</option>
                        <option value="Medial Pulley - 2 Corkscrews with PushLock(s)">Medial Pulley - 2 Corkscrews with PushLock(s)</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon}
                        label="Tie any sutures from the glenoid anchor to the supraspinatus tendon?" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Graft Fixation</Form.Label>
                      <Form.Select
                        value={formData.humeralSideGraftFixation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralSideGraftFixation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="4 anchor knotless SpeedBridge">4 anchor knotless SpeedBridge</option>
                        <option value="6 anchor knotless SpeedBridge">6 anchor knotless SpeedBridge</option>
                        <option value="SutureBridge with lateral SwiveLocks">SutureBridge with lateral SwiveLocks</option>
                        <option value="SutureBridge with lateral PushLocks">SutureBridge with lateral PushLocks</option>
                        <option value="single row repair">single row repair</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Graft Used</Form.Label>
                      <Form.Select
                        value={formData.graftUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "graftUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="ArthroFlex">ArthroFlex</option>
                        <option value="Xenograft-Arthrex DX Reinforcement Matrix">Xenograft-Arthrex DX Reinforcement Matrix</option>
                        <option value="Fascia Lata">Fascia Lata</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Arm position for measuring, positioning and securing graft</Form.Label>
                      <Form.Select
                        value={formData.armPositionForMeasuringPositioningAndSecuringGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "armPositionForMeasuringPositioningAndSecuringGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>              
                        <option value="0 degrees (arm at side)">0 degrees (arm at side)</option>
                        <option value="10 degrees of abduction">10 degrees of abduction</option>
                        <option value="20 degrees of abduction">20 degrees of abduction</option>
                        <option value="30 degrees of abduction">30 degrees of abduction</option>
                        <option value="40 degrees of abduction">40 degrees of abduction</option>
                        <option value="50 degrees of abduction">50 degrees of abduction</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.posteriorMarginConvergencePerformed}
                        label="Posterior margin convergence performed?" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "posteriorMarginConvergencePerformed", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.anteriorMarginConvergencePerformed}
                        label="Anterior margin convergence performed?" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "anteriorMarginConvergencePerformed", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Concomitant Procedures</Form.Label>
                      <Select
                        isMulti
                        value={concomitantProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={concomitantProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Superior Labrum Management</Form.Label>
                      <Form.Select
                        value={formData.superiorLabrumManagement}
                        onChange={(e) => {onChangeHandler(arrayIndex, "superiorLabrumManagement", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="removal/debridement">removal/debridement</option>
                        <option value="graft fixed medial to">graft fixed medial to</option>
                        <option value="other">other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "subscap": {
      return (
        <div className="operations-subscap">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Subscap
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedures</Form.Label>
                      <Select
                        isMulti
                        value={subscapProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={subscapProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Anchor repair technique used</Form.Label>
                      <Select
                        isMulti
                        value={subscapAnchorRepairTechniqueUsedOptions.filter(option => formData.anchorRepairTechniqueUsed.split(",").includes(option.value))}
                        name="procedures"
                        options={subscapAnchorRepairTechniqueUsedOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "anchorRepairTechniqueUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "subAcromialDecompression": {
      return (
        <div className="operations-sub-acromial-decompression">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Subacromial Decompression
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedure</Form.Label>
                      <Form.Select
                        value={formData.procedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="C-A Ligament Release">C-A Ligament Release</option>
                        <option value="Other (Smoothing)">Other (Smoothing)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "tendonTransfer": {
      return (
        <div className="operations-tendon-transfer">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Tendon Transfer
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={tendonTransferProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={tendonTransferProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "biceps": {
      return (
        <div className="operations-biceps">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Biceps
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={bicepsProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={bicepsProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Techniques and Fixation device</Form.Label>
                      <Select
                        isMulti
                        value={bicepsTechniquesAndFixationDeviceOptions.filter(option => formData.techniquesAndFixationDevice.split(",").includes(option.value))}
                        name="procedures"
                        options={bicepsTechniquesAndFixationDeviceOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "techniquesAndFixationDevice", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "coracoplasty": {
      return (
        <div className="operations-coracoplasty">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Coracoplasty
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "distalClavicleExcision": {
      return (
        <div className="operations-distal-clavicle-excision">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Distal Clavicle Excision
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "acJointStabilization": {
      return (
        <div className="operations-ac-joint-stabilization">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    AC Joint Stabilization
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={acJointStabilizationProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={acJointStabilizationProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Number of Tightropes used</Form.Label>
                      <Form.Select
                        value={formData.numberOfTightRopesUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfTightRopesUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="One">One</option>
                        <option value="Two">Two</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "osAcromiale": {
      return (
        <div className="operations-os-acromiale">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Os Acromiale
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedure</Form.Label>
                      <Form.Select
                        value={formData.procedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Excision">Excision</option>
                        <option value="Repair">Repair</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "osteotomy": {
      return (
        <div className="operations-osteotomy">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteotomy
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Glenoid">Glenoid</option>
                        <option value="Humerus">Humerus</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "cartilageTreatment": {
      return (
        <div className="operations-cartilage-treatment">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cartilage Treatment
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Glenoid">Glenoid</option>
                        <option value="Humerus">Humerus</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedure Type</Form.Label>
                      <Form.Select
                        value={formData.procedures}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedures", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Chondroplasty">Chondroplasty</option>
                        <option value="Microfracture">Microfracture</option>
                        <option value="Microfracture plus cartilage graft">Microfracture plus cartilage graft</option>
                        <option value="Microfracture plus orthobiologic">Microfracture plus orthobiologic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Microfracture Awl or Drilling/Power pick used</Form.Label>
                      <Form.Select
                        value={formData.microfractureAwlOrDrillingPowerPickUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "microfractureAwlOrDrillingPowerPickUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Microfracture Awl">Microfracture Awl</option>
                        <option value="Drilling/PowerPick">Drilling/PowerPick</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Orthobiologic Details</Form.Label>
                      <Select
                        isMulti
                        value={orthobiologicDetailOptions.filter(option => formData.orthobiologicDetails.split(",").includes(option.value))}
                        name="procedures"
                        options={orthobiologicDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "orthobiologicDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Orthobiologic Volume Delivered</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.numberOfAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Orthobiologic manufacturer</Form.Label>
                      <Select
                        isMulti
                        value={orthobiologicManufacturerOptions.filter(option => formData.orthobiologicManufacturer.split(",").includes(option.value))}
                        name="procedures"
                        options={orthobiologicManufacturerOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "orthobiologicManufacturer", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Angel system - Hematocrit setting</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={25}
                        value={formData.angelSystemHematocritSetting}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemHematocritSetting", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Angel system - Initial whole blood volume</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.angelSystemInitialWholeBloodVolume}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemInitialWholeBloodVolume", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Angel system - PRP volume produced</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.angelSystemPrpVolumeProduced}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemPrpVolumeProduced", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>PRP-Type of activation</Form.Label>
                      <Form.Select
                        value={formData.prpTypeOfActivation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "prpTypeOfActivation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Endogenous">Endogenous</option>
                        <option value="Exogenous">Exogenous</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "osteochondralTreatment": {
      return (
        <div className="operations-osteochondral-treatment">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteochondral Treatment
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Glenoid">Glenoid</option>
                        <option value="Humerus">Humerus</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Osteochondral Graft Type</Form.Label>
                      <Form.Select
                        value={formData.osteochondralGraftType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "osteochondralGraftType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="OATS Autograft">OATS Autograft</option>
                        <option value="OATS Allograft">OATS Allograft</option>
                        <option value="Bone graft +  allogenic cartilage graft">Bone graft +  allogenic cartilage graft</option>
                        <option value="Synthetic plug">Synthetic plug</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of plugs used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.numberOfPlugsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfPlugsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Avg diameter of plugs used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={3}
                        max={35}
                        value={formData.avgDiameterOfPlugsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "avgDiameterOfPlugsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Total approx size of implanted plug(s)</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={1}
                        max={25}
                        value={formData.totalApproxSizeOfImplantedPlug}
                        onChange={(e) => {onChangeHandler(arrayIndex, "totalApproxSizeOfImplantedPlug", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "otherIntraArticular": {
      return (
        <div className="operations-other-intra-articular">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Other Intra Articular
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.synovialDebridement}
                        label="Synovial debridement (Extensive)" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "synovialDebridement", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.cystRemoval}
                        label="Cyst removal" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "cystRemoval", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.looseBodyRemoval}
                        label="Loose body removal" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "looseBodyRemoval", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion></div>
      )
    }
    case "fractureManagement": {
      return (
        <div className="operations-fracture-management">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Fracture Management (ORIF)
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Clavicle fracture procedure</Form.Label>
                      <Select
                        isMulti
                        value={clavicleFractureProcedureOptions.filter(option => formData.clavicleFractureProcedure.split(",").includes(option.value))}
                        name="procedures"
                        options={clavicleFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "clavicleFractureProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Greater tuberosity fracture procedure</Form.Label>
                      <Select
                        isMulti
                        value={greaterTuberosityFractureProcedureOptions.filter(option => formData.greaterTuberosityFractureProcedure.split(",").includes(option.value))}
                        name="procedures"
                        options={greaterTuberosityFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "greaterTuberosityFractureProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Proximal humerus fracture procedure</Form.Label>
                      <Select
                        isMulti
                        value={proximalHumerusFractureProcedureOptions.filter(option => formData.proximalHumerusFractureProcedure.split(",").includes(option.value))}
                        name="procedures"
                        options={proximalHumerusFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "proximalHumerusFractureProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid fracture procedure</Form.Label>
                      <Select
                        isMulti
                        value={glenoidFractureProcedureOptions.filter(option => formData.glenoidFractureProcedure.split(",").includes(option.value))}
                        name="procedures"
                        options={glenoidFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "glenoidFractureProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Scapula fracture procedure</Form.Label>
                      <Select
                        isMulti
                        value={scapulaFractureProcedureOptions.filter(option => formData.scapulaFractureProcedure.split(",").includes(option.value))}
                        name="procedures"
                        options={scapulaFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "scapulaFractureProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "miscellaneous": {
      return (
        <div className="operations-miscellaneous">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Miscellaneous
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Capsular Release</Form.Label>
                      <Select
                        isMulti
                        value={capsularReleaseOptions.filter(option => formData.capsularRelease.split(",").includes(option.value))}
                        name="capsularRelease"
                        options={capsularReleaseOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "capsularRelease", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.diagnosticArthroscopy}
                        label="Diagnostic Arthroscopy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "diagnosticArthroscopy", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.extensiveGlenohumeralDebridement}
                        label="Extensive Glenohumeral Debridement"
                        onChange={(e) => {onChangeHandler(arrayIndex, "extensiveGlenohumeralDebridement", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.irrigationAndDebridement}
                        label="Irrigation and Debridement" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "irrigationAndDebridement", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.lysisOfAdhesions}
                        label="Lysis of adhesions" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "lysisOfAdhesions", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.manipulationUnderAnesthesia}
                        label="Manipulation under anesthesia (MUA)" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "manipulationUnderAnesthesia", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.subacromialBursectomy}
                        label="Subacromial Bursectomy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "subacromialBursectomy", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.scapulothoracicBursectomy}
                        label="Scapulothoracic Bursectomy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "scapulothoracicBursectomy", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.pecMajorRepair}
                        label="Pec Major Repair" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "pecMajorRepair", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Suprascapular Nerve Release</Form.Label>
                      <Form.Select
                        value={formData.suprascapularNerveRelease}
                        onChange={(e) => {onChangeHandler(arrayIndex, "suprascapularNerveRelease", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>SC joint</Form.Label>
                      <Select
                        isMulti
                        value={scJointOptions.filter(option => formData.scJoint.split(",").includes(option.value))}
                        name="scJoint"
                        options={scJointOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "scJoint", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCaseDetails": {
      return (
        <div className="operations-case-details">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Case Details
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Anesthesia type</Form.Label>
                      <Form.Select
                        value={formData.anesthesiaType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "anesthesiaType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="General">General</option>
                        <option value="Regional">Regional</option>
                        <option value="Local">Local</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Patient postion</Form.Label>
                      <Select
                        isMulti
                        value={patientPositionOptions.filter(option => formData.patientPosition.split(",").includes(option.value))}
                        name="patientPosition"
                        options={patientPositionOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "patientPosition", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowTendonProcedures": {
      return (
        <div className="operations-tendon-procedures">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Tendon Procedures
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Tennis elbow (lateral)">Tennis elbow (lateral)</option>
                        <option value="Golfer's elbow (medial) ">Golfer's elbow (medial) </option>
                        <option value="Distal biceps">Distal biceps</option>
                        <option value="Triceps">Triceps</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthroscopic portals used</Form.Label>
                      <Select
                        isMulti
                        value={arthroscopicPortalOptions.filter(option => formData.arthroscopicPortalsUsed.split(",").includes(option.value))}
                        name="arthroscopicPortalsUsed"
                        options={arthroscopicPortalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthroscopicPortalsUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Epicondylar procedure</Form.Label>
                      <Select
                        isMulti
                        value={epicondylarProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={epicondylarProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Distal biceps procedure</Form.Label>
                      <Select
                        isMulti
                        value={distalBicepsProcedureOptions.filter(option => formData.procedures2.split(",").includes(option.value))}
                        name="procedures"
                        options={distalBicepsProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures2", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Triceps procedure</Form.Label>
                      <Select
                        isMulti
                        value={tricepsProcedureOptions.filter(option => formData.procedures3.split(",").includes(option.value))}
                        name="procedures"
                        options={tricepsProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures3", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCollateralLigaments": {
      return (
        <div className="operations-collateral-ligaments">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Collateral Ligaments
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Ligament</Form.Label>
                      <Form.Select
                        value={formData.ligament}
                        onChange={(e) => {onChangeHandler(arrayIndex, "ligament", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Ulnar ">Ulnar </option>
                        <option value="Radial">Radial</option>
                        <option value="Lateral">Lateral</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthroscopic portals used</Form.Label>
                      <Select
                        isMulti
                        value={arthroscopicPortalOptions.filter(option => formData.arthroscopicPortalsUsed.split(",").includes(option.value))}
                        name="arthroscopicPortalsUsed"
                        options={arthroscopicPortalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthroscopicPortalsUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Type</Form.Label>
                      <Select
                        isMulti
                        value={collateralLigamentsProcedureTypeOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={collateralLigamentsProcedureTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>UCL reconstruction details</Form.Label>
                      <Form.Select
                        value={formData.details}
                        onChange={(e) => {onChangeHandler(arrayIndex, "details", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="DANE TJ Technique">DANE TJ Technique</option>
                        <option value="Docking Technique">Docking Technique</option>
                        <option value="Interference screw technique">Interference screw technique</option>
                        <option value="Modified Jobe technique">Modified Jobe technique</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCartilageTreatment": {
      return (
        <div className="operations-cartilage-treatment">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cartilage Treatment
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Arthroscopic portals used</Form.Label>
                      <Select
                        isMulti
                        value={arthroscopicPortalOptions.filter(option => formData.arthroscopicPortalsUsed.split(",").includes(option.value))}
                        name="arthroscopicPortalsUsed"
                        options={arthroscopicPortalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthroscopicPortalsUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Coronoid">Coronoid</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Trochlea">Trochlea</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Chondroplasty">Chondroplasty</option>
                        <option value="Microfracture ">Microfracture </option>
                        <option value="Microfracture plus Orthobiologic">Microfracture plus Orthobiologic</option>
                        <option value="Microfracture plus cartilage graft">Microfracture plus cartilage graft</option>                        
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Microfracture Awl or Drilling/Power pick used</Form.Label>
                      <Form.Select
                        value={formData.microfractureAwlOrDrillingPowerPickUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "microfractureAwlOrDrillingPowerPickUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Microfracture Awl">Microfracture Awl</option>
                        <option value="Drilling/PowerPick">Drilling/PowerPick</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Orthobiologic Details</Form.Label>
                      <Select
                        isMulti
                        value={orthobiologicDetailOptions.filter(option => formData.orthobiologicDetails.split(",").includes(option.value))}
                        name="procedures"
                        options={orthobiologicDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "orthobiologicDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Orthobiologic Volume Delivered</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.numberOfAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Orthobiologic manufacturer</Form.Label>
                      <Select
                        isMulti
                        value={orthobiologicManufacturerOptions.filter(option => formData.orthobiologicManufacturer.split(",").includes(option.value))}
                        name="procedures"
                        options={orthobiologicManufacturerOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "orthobiologicManufacturer", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Angel system - Hematocrit setting</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={25}
                        value={formData.angelSystemHematocritSetting}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemHematocritSetting", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Angel system - Initial whole blood volume</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.angelSystemInitialWholeBloodVolume}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemInitialWholeBloodVolume", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Angel system - PRP volume produced</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.angelSystemPrpVolumeProduced}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemPrpVolumeProduced", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>PRP-Type of activation</Form.Label>
                      <Form.Select
                        value={formData.prpTypeOfActivation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "prpTypeOfActivation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Endogenous">Endogenous</option>
                        <option value="Exogenous">Exogenous</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Bone Marrow Aspirate Concentrate</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.boneMarrowAspirateConcentrate}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneMarrowAspirateConcentrate", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOsteochondralTreatment": {
      return (
        <div className="operations-osteochondral-treatment">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteochondral Treatment
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Coronoid">Coronoid</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Trochlea">Trochlea</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of plugs used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.numberOfPlugsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfPlugsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Avg diameter of plugs used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={3}
                        max={35}
                        value={formData.avgDiameterOfPlugsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "avgDiameterOfPlugsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOsteophyteDebridementExcision": {
      return (
        <div className="operations-osteophyte-debridement-excision">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteophyte Debridement/Excision
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthroscopic portals used</Form.Label>
                      <Select
                        isMulti
                        value={arthroscopicPortalOptions.filter(option => formData.arthroscopicPortalsUsed.split(",").includes(option.value))}
                        name="arthroscopicPortalsUsed"
                        options={arthroscopicPortalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthroscopicPortalsUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Coronoid">Coronoid</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Trochlea">Trochlea</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowRemovalLooseBody": {
      return (
        <div className="operations-removal-loose-body">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Removal of Loose Body(s)
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Compartement</Form.Label>
                      <Select
                        isMulti
                        value={anteriorPosteriosOptions.filter(option => formData.compartement.split(",").includes(option.value))}
                        name="compartement"
                        options={anteriorPosteriosOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "compartement", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCapsuleSynovium": {
      return (
        <div className="operations-capsule-synovium">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Capsule/Synovium
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.manipulationUnderAnesthesia}
                        label="Manipulation under anesthesia" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "manipulationUnderAnesthesia", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Arthroscopic portals used</Form.Label>
                      <Select
                        isMulti
                        value={arthroscopicPortalOptions.filter(option => formData.arthroscopicPortalsUsed.split(",").includes(option.value))}
                        name="arthroscopicPortalsUsed"
                        options={arthroscopicPortalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthroscopicPortalsUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Procedure</Form.Label>
                      <Select
                        isMulti
                        value={capsuleSynoviumProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={capsuleSynoviumProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Select
                        isMulti
                        value={capsuleSynoviumLocationOptions.filter(option => formData.location.split(",").includes(option.value))}
                        name="location"
                        options={capsuleSynoviumLocationOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "location", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowNerve": {
      return (
        <div className="operations-nerve">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Nerve
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedure</Form.Label>
                      <Select
                        isMulti
                        value={elbowNerveProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={elbowNerveProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elobwOrif": {
      return (
        <div className="operations-orif">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    ORIF
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Distal Humerus - medial column">Distal Humerus - medial column</option>
                        <option value="Distal Humerus - lateral column">Distal Humerus - lateral column</option>
                        <option value="Distal Humerus - olecranon osteotomy">Distal Humerus - olecranon osteotomy</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Coronoid process">Coronoid process</option>
                      </Form.Select>
                    </Col>
                  </Row>
                   <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Plate">Plate</option>
                        <option value="Screw">Screw</option>
                        <option value="Bone tunnel suture fixation">Bone tunnel suture fixation</option>
                      </Form.Select>
                    </Col>
                  <Row>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "radialHeadExcision": {
      return (
        <div className="operations-radial-head-excision">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Radial Head Excision
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthroscopic portals used</Form.Label>
                      <Select
                        isMulti
                        value={arthroscopicPortalOptions.filter(option => formData.arthroscopicPortalsUsed.split(",").includes(option.value))}
                        name="arthroscopicPortalsUsed"
                        options={arthroscopicPortalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthroscopicPortalsUsed", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "radialHeadImplant": {
      return (
        <div className="operations-radial-head-implant">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Radial Head Implant
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "totalElbowArthroplasty": {
      return (
        <div className="operations-total-elbow-arthroplasty">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Total Elbow Arthroplasty
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: {
      return null
    }
  }

}

export default Operations