import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select';
import { RxCross1 } from "react-icons/rx";

import "./Implants.scss"

const acJointStabilizationArthrexDeviceOptions = [
  {value: "AC GraftRope kit", label: "AC GraftRope kit"},
  {value: "TightRope", label: "TightRope"},
  {value: "Twin Tail TightRope", label: "Twin Tail TightRope"},
  {value: "Dog Bone Button", label: "Dog Bone Button"},
  {value: "Distal Clavicle Plate", label: "Distal Clavicle Plate"},
  {value: "FiberTape", label: "FiberTape"},
  {value: "FiberWire", label: "FiberWire"},
  {value: "Tenodesis screw", label: "Tenodesis screw"},
  {value: "Internal Brace", label: "Internal Brace"},
]

const bicepsArthrexDeviceOptions = [
  {value: "Corkscrew FT (Fill in material)", label: "Corkscrew FT (Fill in material)"},
  {value: "Corkscrew (Fill in material )", label: "Corkscrew (Fill in material )"},
  {value: "SwiveLock (Fill in type & material)", label: "SwiveLock (Fill in type & material)"},
  {value: "Double Loaded SwiveLock (Fill in material)", label: "Double Loaded SwiveLock (Fill in material)"},
  {value: "Tenodesis screw (Fill in material)", label: "Tenodesis screw (Fill in material)"},
  {value: "BicepsButton", label: "BicepsButton"},
  {value: "FiberTak", label: "FiberTak"},
  {value: "FiberLink", label: "FiberLink"},
  {value: "PushLock", label: "PushLock"},
]

const implantTypeOptions = [
  {value: "Partially threaded screws", label: "Partially threaded screws"},
  {value: "Fully threaded screws", label: "Fully threaded screws"},
  {value: "Washers", label: "Washers"},
  {value: "Wedged Profile Plate", label: "Wedged Profile Plate"},
]

const rotatorCuffImplantTypeOptions = [
  {value: "Knotless anchor", label: "Knotless anchor"},
  {value: "Suture anchor", label: "Suture anchor"},
  {value: "Tenodesis screw", label: "Tenodesis screw"},
]

const Implants = ({ form, formData, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "acJointStabilization": {
      return (
        <div className="implants-ac-joint-stabilization">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    AC Joint Stabilization
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Arthrex devices</Form.Label>
                      <Select
                        isMulti
                        value={acJointStabilizationArthrexDeviceOptions.filter(option => formData.arthrexDevices.split(",").includes(option.value))}
                        name="arthrexDevices"
                        options={acJointStabilizationArthrexDeviceOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthrexDevices", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "biceps": {
      return (
        <div className="implants-biceps">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Biceps
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Arthrex devices</Form.Label>
                      <Select
                        isMulti
                        value={bicepsArthrexDeviceOptions.filter(option => formData.arthrexDevices.split(",").includes(option.value))}
                        name="arthrexDevices"
                        options={bicepsArthrexDeviceOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "arthrexDevices", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>SwiveLock type</Form.Label>
                      <Form.Select
                        value={formData.swiveLockType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "swiveLockType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="closed eyelet">closed eyelet</option>
                        <option value="forked eyelet">forked eyelet</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Fixation device material</Form.Label>
                      <Form.Select
                        value={formData.deviceMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "deviceMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="Metal">Metal</option>
                        <option value="PEEK">PEEK</option>
                        <option value="FiberWire (Polyester)">BioComposite</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Device diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={20}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.implantAlsoUsedForSubscapularisRepair}
                        label="Implant also used for subscapularis repair" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "implantAlsoUsedForSubscapularisRepair", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.implantAlsoUsedForSupraspinatusRepair}
                        label="Implant also used for supraspinatus repair" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "implantAlsoUsedForSupraspinatusRepair", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "capsularRepairHaglAlpsaRemplissageAsa": {
      return (
        <div className="implants-capsular-repair-hagl-alpsa-remplissage-asa">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Capsular Repair HAGL/ALPSA/Remplissage/ASA
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Form.Select
                        value={formData.associatedProcedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="HAGL">HAGL</option>
                        <option value="ALPSA">ALPSA</option>
                        <option value="Remplissage">Remplissage</option>
                        <option value="Capsulorrhaphy">Capsulorrhaphy</option>
                        <option value="ASA">ASA</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Labral SwiveLock">Arthrex Labral SwiveLock</option>
                        <option value="Arthrex PushLock">Arthrex PushLock</option>
                        <option value="Arthrex Knotless SutureTak">Arthrex Knotless SutureTak</option>
                        <option value="Arthrex Knotless Corkscrew">Arthrex Knotless Corkscrew</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0}
                        max={20}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberTape">FiberTape</option>
                        <option value="LabralTape">LabralTape</option>
                        <option value="SutureTape">SutureTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex SutureTak">Arthrex SutureTak</option>
                        <option value="Arthrex FASTak">Arthrex FASTak</option>
                        <option value="Arthrex Corkscrew">Arthrex Corkscrew</option>
                        <option value="Arthrex FiberTak">Arthrex FiberTak</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0}
                        max={20}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire CL">FiberWire CL</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Screws (metal)</Form.Label>
                      <Form.Select
                        value={formData.screwType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fully Threaded">Fully Threaded</option>
                        <option value="Partially Threaded">Partially Threaded</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter of screw</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={2}
                        max={6}
                        value={formData.screwDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of screws used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.screwsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "chondral": {
      return (
        <div className="implants-chondral">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Chondral
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location used</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Glenoid">Glenoid</option>
                        <option value="Humerus">Humerus</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Biocompression screw">Arthrex - Biocompression screw</option>
                        <option value="Arthrex - Chondral dart">Arthrex - Chondral dart</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "fractureManagement": {
      return (
        <div className="implants-fracture-management">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Fracture Management
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Clavicle device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Mid Shaft/Central Third plate">Arthrex - Mid Shaft/Central Third plate</option>
                        <option value="Arthrex - Distal plate">Arthrex - Distal plate</option>
                        <option value="Arthrex - Distal plate with AC Dogbone">Arthrex - Distal plate with AC Dogbone</option>
                        <option value="Arthrex - AC DogBone">Arthrex - AC DogBone</option>
                        <option value="Arthrex - TightRope">Arthrex - TightRope</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Greater tuberosity device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Proximal humerus device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer3}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer3", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Percutaneous pins and screws">Arthrex - Percutaneous pins and screws</option>
                        <option value="Arthrex - Humeral SuturePlate">Arthrex - Humeral SuturePlate</option>
                        <option value="Arthrex - Univers Fracture ">Arthrex - Univers Fracture </option>
                        <option value="Arthrex - pins/screws">Arthrex - pins/screws</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid fracture device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer4}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer4", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "glenoidBoneGraftProcedure": {
      return (
        <div className="implants-glenoid-bone-graft-procedure">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Glenoid Bone Graft Procedure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type of implant</Form.Label>
                      <Select
                        isMulti
                        value={implantTypeOptions.filter(option => formData.implantType.split(",").includes(option.value))}
                        name="material"
                        options={implantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "implantType", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "humeralBoneGraftProcedure": {
      return (
        <div className="implants-humeral-bone-graft-procedure">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Humeral Bone Graft Procedure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type of implant</Form.Label>
                      <Select
                        isMulti
                        value={implantTypeOptions.filter(option => formData.implantType.split(",").includes(option.value))}
                        name="material"
                        options={implantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "implantType", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "labralRepair": {
      return (
        <div className="implants-labral-repair">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Labral Repair
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Labral SwiveLock">Arthrex Labral SwiveLock</option>
                        <option value="Arthrex PushLock">Arthrex PushLock</option>
                        <option value="Arthrex Knotless SutureTak">Arthrex Knotless SutureTak</option>
                        <option value="Arthrex Knotless Corkscrew">Arthrex Knotless Corkscrew</option>
                        <option value="Arthrex Knotless FiberTak ">Arthrex Knotless FiberTak </option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire (Polyester)">FiberWire (Polyester)</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire/FiberStick">FiberWire/FiberStick</option>
                        <option value="LabralTape">LabralTape</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="Collagen coated FiberWire">Collagen coated FiberWire</option>
                        <option value="1.3mm SutureTape">1.3mm SutureTape</option>
                        <option value="FiberLink (FiberWire)">FiberLink (FiberWire)</option>
                        <option value="FiberLink (1.3mm Suture Tape)">FiberLink (1.3mm Suture Tape)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex SutureTak">Arthrex SutureTak</option>
                        <option value="Arthrex FASTak">Arthrex FASTak</option>
                        <option value="Arthrex FiberTak">Arthrex FiberTak</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire CL">FiberWire CL</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Suture Loop</Form.Label>
                      <Form.Select
                        value={formData.sutureLoop}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureLoop", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="Other suture">Other suture</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "osAcromiale": {
      return (
        <div className="implants-os-acromiale">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    OS Acromiale
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Fixation Device</Form.Label>
                      <Form.Select
                        value={formData.device}
                        onChange={(e) => {onChangeHandler(arrayIndex, "device", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Pins">Pins</option>
                        <option value="Screws">Screws</option>
                        <option value="Suture anchor">Suture anchor</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "osteotomy": {
      return (
        <div className="implants-osteotomy">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteotomy
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Device</Form.Label>
                      <Form.Select
                        value={formData.device}
                        onChange={(e) => {onChangeHandler(arrayIndex, "device", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Plate">Pins</option>
                        <option value="Screws">Screws</option>
                        <option value="Pins">Pins</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "pastaRepairKit": {
      return (
        <div className="implants-ac-joint-stabilization">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Pasta Repair Kit
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "pecMajor": {
      return (
        <div className="implants-pec-major">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Pec Major
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Device Information</Form.Label>
                      <Form.Select
                        value={formData.device}
                        onChange={(e) => {onChangeHandler(arrayIndex, "device", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Pec Button">Arthrex Pec Button</option>
                        <option value="Arthrex FiberTak">Arthrex FiberTak</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "rotatorCuffImplantsLateralRow": {
      return (
        <div className="implants-rotator-cuff-implants-lateral-row">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff Implants Lateral Row
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffImplantTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={rotatorCuffImplantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex SwiveLock">Arthrex SwiveLock</option>
                        <option value="Arthrex PushLock">Arthrex PushLock</option>
                        <option value="Arthrex Double Loaded SwiveLock">Arthrex Double Loaded SwiveLock</option>
                        <option value="Arthrex Corkscrew">Arthrex Corkscrew</option>
                        <option value="Arthrex FiberTak DR">Arthrex FiberTak DR</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberTape">FiberTape</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="FiberChain">FiberChain</option>
                        <option value="Collagen coated FiberWire">Collagen coated FiberWire</option>
                        <option value="Collagen coated FiberTape">Collagen coated FiberTape</option>
                        <option value="1.3mm SutureTape">1.3mm SutureTape</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Corkscrew FT">Arthrex Corkscrew FT</option>
                        <option value="Arthrex Corkscrew ">Arthrex Corkscrew</option>
                        <option value="Arthrex Double Loaded SwiveLock">Arthrex Double Loaded SwiveLock</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type tenodesis screw</Form.Label>
                      <Form.Select
                        value={formData.screwType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Tenodesis screw">Arthrex Tenodesis screw</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Tenodesis screw material</Form.Label>
                      <Form.Select
                        value={formData.screwMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter of screw</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.1}
                        min={0}
                        max={1000}
                        value={formData.screwDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of screws used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.screwsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "rotatorCuffImplantsMedialRow": {
      return (
        <div className="implants-rotator-cuff-implants-medial-row">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff Implants Medial Row
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffImplantTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={rotatorCuffImplantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex SwiveLock">Arthrex SwiveLock</option>
                        <option value="Arthrex PushLock">Arthrex PushLock</option>
                        <option value="Arthrex Double Loaded SwiveLock">Arthrex Double Loaded SwiveLock</option>
                        <option value="Arthrex Knotless SutureTak">Arthrex Knotless SutureTak</option>
                        <option value="Arthrex Corkscrew">Arthrex Corkscrew</option>
                        <option value="Arthrex Knotless FiberTak">Arthrex Knotless FiberTak</option>
                        <option value="Arthrex Knotless FiberTak">Arthrex Knotless FiberTak</option>
                        <option value="Arthrex Knotless Corkscrew">Arthrex Knotless Corkscrew</option>
                        <option value="2.6 FiberTak RC Soft Anchor">2.6 FiberTak RC Soft Anchor</option>
                        <option value="Knotless 2.6 FiberTak RC Soft Anchor">Knotless 2.6 FiberTak RC Soft Anchor</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire (Polyester)">FiberWire (Polyester)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberTape">FiberTape</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="FiberChain">FiberChain</option>
                        <option value="Collagen coated FiberWire">Collagen coated FiberWire</option>
                        <option value="Collagen coated FiberTape">Collagen coated FiberTape</option>
                        <option value="SutureTape">SutureTape</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Corkscrew FT">Arthrex Corkscrew FT</option>
                        <option value="Arthrex Corkscrew">Arthrex Corkscrew</option>
                        <option value="Arthrex Double Loaded SwiveLock">Arthrex Double Loaded SwiveLock</option>
                        <option value="Arthrex FiberTak">Arthrex FiberTak</option>
                        <option value="Arthrex SutureTak">Arthrex SutureTak</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire (Polyester)">FiberWire (Polyester)</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type tenodesis screw</Form.Label>
                      <Form.Select
                        value={formData.screwType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Tenodesis screw">Arthrex Tenodesis screw</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Tenodesis screw material</Form.Label>
                      <Form.Select
                        value={formData.screwMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter of screw</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.1}
                        min={0}
                        max={1000}
                        value={formData.screwDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of screws used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.screwsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "cuffMend": {
      return (
        <div className="implants-slap">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cuff Mend
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Medial anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberStitch">FiberStitch</option>
                        <option value="Rotator cuff implants medial row">Rotator cuff implants medial row</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of medial anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Lateral anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite Pushlock SP (4.5mm x28mm)">Biocomposite Pushlock SP (4.5mm x28mm)</option>
                        <option value="Rotator cuff implants lateral row">Rotator cuff implants lateral row</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of lateral anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorsUsed2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed2", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Suture used</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberLink SutureTape (0.9 mm)">FiberLink SutureTape (0.9 mm)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "slap": {
      return (
        <div className="implants-slap">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    SLAP
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Labral SwiveLock">Arthrex Labral SwiveLock</option>
                        <option value="Arthrex PushLock">Arthrex PushLock</option>
                        <option value="Arthrex Knotless SutureTak">Arthrex Knotless SutureTak</option>
                        <option value="Arthrex Knotless FiberTak">Arthrex Knotless FiberTak</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire CL">FiberWire CL</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire/FiberStick">FiberWire/FiberStick</option>
                        <option value="LabralTape">LabralTape</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="FiberChain">FiberChain</option>
                        <option value="Collagen coated FiberWire">Collagen coated FiberWire</option>
                        <option value="Collagen coated LabralTape">Collagen coated LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex SutureTak">Arthrex SutureTak</option>
                        <option value="Arthrex FASTak">Arthrex FASTak</option>
                        <option value="Arthrex FiberTak">Arthrex FiberTak</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                        <option value="FiberWire CL">FiberWire CL</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "subscap": {
      return (
        <div className="implants-subscap">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Subscap
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffImplantTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={rotatorCuffImplantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex SwiveLock">Arthrex SwiveLock</option>
                        <option value="Arthrex Double Loaded SwiveLock">Arthrex Double Loaded SwiveLock</option>
                        <option value="Arthrex PushLock">Arthrex PushLock</option>
                        <option value="Arthrex FiberTak DR">Arthrex FiberTak DR</option>
                        <option value="Arthrex Knotless FiberTak">Arthrex Knotless FiberTak</option>
                        <option value="Arthrex Corkscrew">Arthrex Corkscrew</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberTape">FiberTape</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="FiberChain">FiberChain</option>
                        <option value="Collagen coated FiberWire">Collagen coated FiberWire</option>
                        <option value="Collagen coated FiberTape">Collagen coated FiberTape</option>
                        <option value="SutureTape">SutureTape</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Corkscrew FT">Arthrex Corkscrew FT</option>
                        <option value="Arthrex Corkscrew">Arthrex Corkscrew</option>
                        <option value="Arthrex Double Loaded SwiveLock">Arthrex Double Loaded SwiveLock</option>
                        <option value="Arthrex SutureTak">Arthrex SutureTak</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type tenodesis screw</Form.Label>
                      <Form.Select
                        value={formData.screwType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Tenodesis screw">Arthrex Tenodesis screw</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Tenodesis screw material</Form.Label>
                      <Form.Select
                        value={formData.screwMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter of screw</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.1}
                        min={0}
                        max={1000}
                        value={formData.screwDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of screws used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.screwsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "screwsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "superiorCapsuleReconstructionGlenoid": {
      return (
        <div className="implants-superior-capsule-reconstruction-glenoid">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Superior Capsule Reconstruction Glenoid
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>3.0mm SutureTak (material)</Form.Label>
                      <Form.Select
                        value={formData.sutureTakMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureTakMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of SutureTaks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>3.5mm SwiveLock (material)</Form.Label>
                      <Form.Select
                        value={formData.swiveLockMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "swiveLockMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with 3.5mm SwiveLock</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of 3.5mm SwiveLocks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>4.75mm SwiveLock (material)</Form.Label>
                      <Form.Select
                        value={formData.swiveLockMaterial2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "swiveLockMaterial2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Suture used with 4.75mm SwiveLock</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of 4.75mm SwiveLocks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.knotlessAnchorsUsed2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed2", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>5.5 SwiveLock (material)</Form.Label>
                      <Form.Select
                        value={formData.swiveLockMaterial3}
                        onChange={(e) => {onChangeHandler(arrayIndex, "swiveLockMaterial3", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with 5.5mm SwiveLock</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture3}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture3", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of 5.55mm SwiveLocks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.knotlessAnchorsUsed3}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed3", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>2.9mm PushLock (material)</Form.Label>
                      <Form.Select
                        value={formData.pushlockMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "pushlockMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Suture used with 2.9 PushLock</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture4}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture4", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="LabralTape">LabralTape</option>
                        <option value="N/A">N/A</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of 2.9 PushLock used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.knotlessAnchorsUsed4}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed4", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>4.5 Corkscrew (material)</Form.Label>
                      <Form.Select
                        value={formData.corkscrewkMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "corkscrewkMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of 4.5 Corkscrew used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorsUsed5}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed5", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>3.9 Corkscrew (material)</Form.Label>
                      <Form.Select
                        value={formData.corkscrewkMaterial2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "corkscrewkMaterial2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number of 3.9 Corkscrew used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={5}
                        value={formData.knotlessAnchorsUsed6}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed6", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.fiberTakDr}
                        label="FiberTak DR" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "fiberTakDr", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Number FiberTak DR used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorsUsed7}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed7", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of 1.8 mm knotless FiberTak used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorsUsed8}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed8", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "superiorCapsuleReconstructionHumeralSideLateralRow": {
      return (
        <div className="implants-superior-capsule-reconstruction-humeral-side-lateral-row">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Superior Capsule Reconstruction Humeral Side Lateral Row
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffImplantTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={rotatorCuffImplantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="4.75mm SwiveLock">4.75mm SwiveLock</option>
                        <option value="5.5mm SwiveLock">5.5mm SwiveLock</option>
                        <option value="4.5mm PushLock">4.5mm PushLock</option>
                        <option value="FiberTak DR">FiberTak DR</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Accessory suture used</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="SutureTape">SutureTape</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "superiorCapsuleReconstructionHumeralSideMedialRow": {
      return (
        <div className="implants-superior-capsule-reconstruction-humeral-side-medial-row">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Superior Capsule Reconstruction Humeral Side Medial Row
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffImplantTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={rotatorCuffImplantTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="4.75mm SwiveLock">4.75mm SwiveLock</option>
                        <option value="4.75mm Double Loaded SwiveLock">4.75mm Double Loaded SwiveLock</option>
                        <option value="FiberTak DR">FiberTak DR</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter knotless anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.knotlessAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.knotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.knotlessAnchorSuture}
                        onChange={(e) => {onChangeHandler(arrayIndex, "knotlessAnchorSuture", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberTape">FiberTape</option>
                        <option value="SutureTape">SutureTape</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="5.5mm Corkscrew">5.5mm Corkscrew</option>
                        <option value="5.5mm Corkscrew FT">5.5mm Corkscrew FT</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureAnchorMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Biocomposite">Biocomposite</option>
                        <option value="PEEK">PEEK</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Diameter suture anchor</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.sutureAnchorDiameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorDiameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-1000}
                        max={1000}
                        value={formData.sutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowTendonProcedures": {
      return (
        <div className="implants-tendon-procedures">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Tendon Procedures
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                 <Row>
                    <Col>
                      <Form.Label>Associated procedure</Form.Label>
                      <Form.Select
                        value={formData.associatedProcedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Tennis elbow (lateral)">Tennis elbow (lateral)</option>
                        <option value="Golfer's elbow (medial) ">Golfer's elbow (medial) </option>
                        <option value="Distal biceps">Distal biceps</option>
                        <option value="Triceps">Triceps</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthrex product</Form.Label>
                      <Form.Select
                        value={formData.arthrexDevices}
                        onChange={(e) => {onChangeHandler(arrayIndex, "arthrexDevices", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Corkscrew FT">Corkscrew FT</option>
                        <option value="Corkscrew">Corkscrew</option>
                        <option value="SwiveLock">SwiveLock</option>
                        <option value="Tenodesis screw ">Tenodesis screw </option>
                        <option value="BicepsButton">BicepsButton</option>
                        <option value="FiberTak">FiberTak</option>
                        <option value="Knotless SutureTak">Knotless SutureTak</option>
                        <option value="Knotless Peek SutureTak">Knotless Peek SutureTak</option>
                        <option value="Knotless corkscrew">Knotless corkscrew</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Device material</Form.Label>
                      <Form.Select
                        value={formData.deviceMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "deviceMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="Metal">Metal</option>
                        <option value="PEEK">PEEK</option>
                        <option value="FiberWire CL">FiberWire CL</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Device diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCollateralLigaments": {
      return (
        <div className="implants-collateral-ligaments">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Collateral Ligaments
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Ligament</Form.Label>
                      <Form.Select
                        value={formData.ligament}
                        onChange={(e) => {onChangeHandler(arrayIndex, "ligament", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Ulnar">Ulnar</option>
                        <option value="Lateral">Lateral</option>
                        <option value="Radial">Radial</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthrex product</Form.Label>
                      <Form.Select
                        value={formData.arthrexDevices}
                        onChange={(e) => {onChangeHandler(arrayIndex, "arthrexDevices", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Corkscrew FT">Corkscrew FT</option>
                        <option value="Corkscrew ">Corkscrew </option>
                        <option value="SwiveLock ">SwiveLock </option>
                        <option value="Tenodesis screw ">Tenodesis screw </option>
                        <option value="BicepsButton">BicepsButton</option>
                        <option value="FiberTak">FiberTak</option>
                        <option value="InternalBrace">InternalBrace</option>
                        <option value="FiberLoop">FiberLoop</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="Knotless corkscrew">Knotless corkscrew</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Device material</Form.Label>
                      <Form.Select
                        value={formData.deviceMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "deviceMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="Metal">Metal</option>
                        <option value="PEEK">PEEK</option>
                        <option value="FiberWire CL">FiberWire CL</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Device diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowCartilageTreatment": {
      return (
        <div className="implants-cartilage-treatment">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cartilage Treatment
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Coronoid">Coronoid</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Trochlea">Trochlea</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Chondral Implant Used</Form.Label>
                      <Form.Select
                        value={formData.implantUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "implantUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Compression screw">Compression screw</option>
                        <option value="Chondral dart">Chondral dart</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Device diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOsteochondralTreatment": {
      return (
        <div className="implants-osteochondral-treatment">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Osteochondral Treatment
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Coronoid">Coronoid</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Radial Head">Radial Head</option>
                        <option value="Trochlea">Trochlea</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Chondral Implant Used</Form.Label>
                      <Form.Select
                        value={formData.implantUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "implantUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Compression screw">Compression screw</option>
                        <option value="Chondral dart">Chondral dart</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Device diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowOrif": {
      return (
        <div className="implants-orif">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    ORIF
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Capitellum">Capitellum</option>
                        <option value="Olecranon">Olecranon</option>
                        <option value="Distal Humerus - medial column">Distal Humerus - medial column</option>
                        <option value="Distal Humerus - lateral column">Distal Humerus - lateral column</option>
                        <option value="Distal Humerus - olecranon osteotomy">Distal Humerus - olecranon osteotomy</option>
                        <option value="Radial Head">Radial Head</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Arthrex Implant Used</Form.Label>
                      <Form.Select
                        value={formData.implantUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "implantUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Mini Compression FT screw">Mini Compression FT screw</option>
                        <option value="Standard Compression FT screw">Standard Compression FT screw</option>
                        <option value="Bio-Compression screw">Bio-Compression screw</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Device diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.05}
                        min={0}
                        max={10}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Device material</Form.Label>
                      <Form.Select
                        value={formData.deviceMaterial}
                        onChange={(e) => {onChangeHandler(arrayIndex, "deviceMaterial", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="Metal">Metal</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Number Used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={10}
                        value={formData.numberUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowRadialHeadImplant": {
      return (
        <div className="implants-radial-head-implant">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Radial Head Implant
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowTotalElbowArthroplasty": {
      return (
        <div className="implants-total-elbow-arthroplasty">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Total Elbow Arthroplasty
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Device manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: return
  }
}

export default Implants