import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select';
import { RxCross1 } from "react-icons/rx";

import "./Grafts.scss"

const boneGraftTypeOptions = [
  {value: "Vascularized", label: "Vascularized"},
  {value: "Cancellous", label: "Cancellous"},
  {value: "Corticocancellous", label: "Corticocancellous"},
]

const demineralizedBoneOptions = [
  {value: "StimuBlast DBM Putty", label: "StimuBlast DBM Putty"},
  {value: "StimuBlast DBM Gel", label: "StimuBlast DBM Gel"},
  {value: "StimuBlast CB DBM Putty", label: "StimuBlast CB DBM Putty"},
  {value: "StimuBlast CB DBM Gel", label: "StimuBlast CB DBM Gel"},
  {value: "FlexiGRAFT Cortical Fibers", label: "FlexiGRAFT Cortical Fibers"},
  {value: "FlexiGRAFT Cancellous Sponge", label: "FlexiGRAFT Cancellous Sponge"},
  {value: "FlexiGraft Cancellous Chips", label: "FlexiGraft Cancellous Chips"},
  {value: "AlloSync DBM Gel", label: "AlloSync DBM Gel"},
  {value: "AlloSync DBM Putty", label: "AlloSync DBM Putty"},
  {value: "AlloSync CB DBM Paste", label: "AlloSync CB DBM Paste"},
  {value: "AlloSync Cancellous Sponge", label: "AlloSync Cancellous Sponge"},
  {value: "AlloSync Cancellous Chips", label: "AlloSync Cancellous Chips"},
  {value: "AlloSync Cortical Fibers", label: "AlloSync Cortical Fibers"},
  {value: "DBM particulate", label: "DBM particulate"},
  {value: "Other DBM with carrier", label: "Other DBM with carrier"},
  {value: "Other demineralized cancellous sponge", label: "Other demineralized cancellous sponge"},
]

const mineralizedBoneOptions = [
  {value: "Corticocancellous wedge/block", label: "Corticocancellous wedge/block"},
  {value: "Corticocancellous chips", label: "Corticocancellous chips"},
  {value: "Cancellous chips", label: "Cancellous chips"},
  {value: "Cancellous cubes/blocks", label: "Cancellous cubes/blocks"},
]

const materialOptions = [
  {value: "Calcium phosphate mixture", label: "Calcium phosphate mixture"},
  {value: "Beta tricalcium phosphate (Beta TCP)", label: "Beta tricalcium phosphate (Beta TCP)"},
  {value: "Hydroxyapatite (HA)", label: "Hydroxyapatite (HA)"},
  {value: "Combination Beta TCP and HA", label: "Combination Beta TCP and HA"},
  {value: "Mixture of collagen and a calcium phosphate", label: "Mixture of collagen and a calcium phosphate"},
  {value: "Bioactive glass", label: "Bioactive glass"},
  {value: "Calcium sulfate", label: "Calcium sulfate"},
  {value: "Other", label: "Other"},
]

const orthobiologicDetailOptions = [
  {value: "Blood", label: "Blood"},
  {value: "Plasma", label: "Plasma"},
  {value: "PRP-Plasma Based System ", label: "PRP-Plasma Based System "},
  {value: "PRP-Buffy Coat System ", label: "PRP-Buffy Coat System "},
  {value: "Bone Marrow Aspirate", label: "Bone Marrow Aspirate"},
  {value: "Bone Marrow Aspirate Concentrate", label: "Bone Marrow Aspirate Concentrate"},
]

const processingOptions = [
  {value: "Aseptically Processed", label: "Aseptically Processed"},
  {value: "AlloWash XG", label: "AlloWash XG"},
  {value: "Irradiation: Low-dose/Low temperature (< 2.0 Mrad)", label: "Irradiation: Low-dose/Low temperature (< 2.0 Mrad)"},
  {value: "Irradiation: High-dose Irradiation (>2.0 Mrad)", label: "Irradiation: High-dose Irradiation (>2.0 Mrad)"},
  {value: "E-beam", label: "E-beam"},
  {value: "Critical CO2", label: "Critical CO2"},
  {value: "BioCleanse/Chemical", label: "BioCleanse/Chemical"},
  {value: "Other Processing Method", label: "Other Processing Method"},
]

const softTissueMeniscalAllograftTypeOptions = [
  {value: "Meniscal allograft", label: "Meniscal allograft"},
  {value: "Achilles allograft", label: "Achilles allograft"},
  {value: "Fascia Lata autograft", label: "Fascia Lata autograft"},
  {value: "Fascia Lata allograft ", label: "Fascia Lata allograft "},
  {value: "Capsule", label: "Capsule"},
  {value: "Other", label: "Other"},
]

const boneGraftElbowAssociatedProcedureOptions = [
  {value: "Osteochondral bone graft", label: "Osteochondral bone graft"},
  {value: "ORIF", label: "ORIF"},
  {value: "Total elbow arthroplasty", label: "Total elbow arthroplasty"},
]

const elbowSoftTissueTendonGraftAutograftOptions = [
  {value: "Ipsilateral Palmaris", label: "Ipsilateral Palmaris"},
  {value: "Contralateral Palmaris", label: "Contralateral Palmaris"},
  {value: "Gracilis", label: "Gracilis"},
  {value: "Plantaris", label: "Plantaris"},
]

const elbowSoftTissueTendonGraftAllograftOptions = [
  {value: "Palmaris", label: "Palmaris"},
  {value: "Gracilis", label: "Gracilis"},
  {value: "Plantaris", label: "Plantaris"},
  {value: "Other", label: "Other"},
]

const Grafts = ({ form, formData, study, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "boneGraftAutografts": {
      return (
        <div className="grafts-bone-graft-autografts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Bone Graft Autografts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      {
                        study === "elbow"? (
                          <Select
                            isMulti
                            value={boneGraftElbowAssociatedProcedureOptions.filter(option => formData.associatedProcedure.split(",").includes(option.value))}
                            name="associatedProcedure"
                            options={boneGraftElbowAssociatedProcedureOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedValues) => {onChangeHandler(arrayIndex, "associatedProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                          />
                        ) : (
                          <Form.Select
                            value={formData.associatedProcedure}
                            onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                          >
                            <option value="" hidden>Select an option</option>
                            <option value="Os Acromiale">Os Acromiale</option>
                            <option value="Osteotomy">Osteotomy</option>
                            <option value="Osteochondral bone graft">Osteochondral bone graft</option>
                            <option value="Clavicle fracture treatment">Clavicle fracture treatment</option>
                            <option value="Greater tuberosity fracture treatment">Greater tuberosity fracture treatment</option>
                            <option value="Proximal humerus fracture treatment">Proximal humerus fracture treatment</option>
                            <option value="Glenoid fracture treatment">Glenoid fracture treatment</option>
                            <option value="Instability glenoid loss bone graft">Instability glenoid loss bone graft</option>
                            <option value="Instability humeral head bone graft">Instability humeral head bone graft</option>
                            <option value="BIO-RSA">BIO-RSA</option>
                          </Form.Select>
                        )
                      }
                    </Col>
                    <Col>
                      <Form.Label>Harvested From</Form.Label>
                      <Form.Select
                        value={formData.harvestedFrom}
                        onChange={(e) => {onChangeHandler(arrayIndex, "harvestedFrom", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Humeral Head">Humeral Head</option>
                        <option value="Coracoid">Coracoid</option>
                        <option value="Scapular Spine">Scapular Spine</option>
                        <option value="Iliac Crest ">Iliac Crest </option>
                        <option value="Other ">Other </option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={boneGraftTypeOptions.filter(option => formData.boneGraftType.split(",").includes(option.value))}
                        name="procedures"
                        options={boneGraftTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "boneGraftType", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Volume Used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={20}
                        value={formData.volumeUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "volumeUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "boneGraftAllografts": {
      return (
        <div className="grafts-bone-graft-allografts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Bone Graft Allografts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Form.Select
                        value={formData.associatedProcedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        {
                          study === "elbow"? (
                            <>
                              <option value="Osteochondral bone graft">Osteochondral bone graft</option>
                              <option value="ORIF">ORIF</option>
                              <option value="Total elbow arthroplasty">Total elbow arthroplasty</option>
                            </>
                          ): (
                            <>
                              <option value="Os Acromiale">Os Acromiale</option>
                              <option value="Glenoid osteotomy">Glenoid osteotomy</option>
                              <option value="Humeral osteotomy">Humeral osteotomy</option>
                              <option value="Osteochondral bone graft">Osteochondral bone graft</option>
                              <option value="Clavicle fracture treatment">Clavicle fracture treatment</option>
                              <option value="Greater tuberosity fracture treatment">Greater tuberosity fracture treatment</option>
                              <option value="Proximal humerus fracture treatment">Proximal humerus fracture treatment</option>
                              <option value="Glenoid fracture treatment">Glenoid fracture treatment</option>
                              <option value="Instability glenoid loss bone graft">Instability glenoid loss bone graft</option>
                              <option value="Instability humeral head bone graft">Instability humeral head bone graft</option>
                            </>
                          )
                        }
                      </Form.Select>
                    </Col>
                    {
                      study !== "elbow" && (
                        <Col>
                          <Form.Label>Harvested From</Form.Label>
                          <Form.Select
                            value={formData.harvestedFrom}
                            onChange={(e) => {onChangeHandler(arrayIndex, "harvestedFrom", e.target.value)}}
                          >
                            <option value="" hidden>Select an option</option>
                            <option value="Humeral Head">Humeral Head</option>
                            <option value="Proximal Tibia">Proximal Tibia</option>
                            <option value="Distal Tibia ">Distal Tibia </option>
                            <option value="Glenoid ">Glenoid </option>
                            <option value="Femoral head/neck ">Femoral head/neck </option>
                            <option value="Iliac crest ">Iliac crest </option>
                          </Form.Select>
                        </Col>
                      )
                    }
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Demineralized bone</Form.Label>
                      <Select
                        isMulti
                        value={demineralizedBoneOptions.filter(option => formData.demineralizedBone.split(",").includes(option.value))}
                        name="procedures"
                        options={demineralizedBoneOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "demineralizedBone", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Cellular Demineralized Bone</Form.Label>
                      <Form.Select
                        value={formData.cellularDemineralizedBone}
                        onChange={(e) => {onChangeHandler(arrayIndex, "cellularDemineralizedBone", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="ArthroCell">ArthroCell</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Mineralized bone</Form.Label>
                      <Select
                        isMulti
                        value={mineralizedBoneOptions.filter(option => formData.mineralizedBone.split(",").includes(option.value))}
                        name="procedures"
                        options={mineralizedBoneOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "mineralizedBone", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Volume used</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.volumeUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "volumeUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Size used - Width - Sponge/wedge</Form.Label>
                      <Form.Control
                        type="number"
                        step={2}
                        min={2}
                        max={50}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Size used - Length - Sponge/wedge</Form.Label>
                      <Form.Control
                        type="number"
                        step={2}
                        min={2}
                        max={50}
                        value={formData.length}
                        onChange={(e) => {onChangeHandler(arrayIndex, "length", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Size used - Height - Sponge/wedge</Form.Label>
                      <Form.Control
                        type="number"
                        step={2}
                        min={2}
                        max={50}
                        value={formData.height}
                        onChange={(e) => {onChangeHandler(arrayIndex, "height", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label># of sponges or wedges used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={10}
                        value={formData.numberUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Allograft bone distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Processing</Form.Label>
                      <Form.Select
                        value={formData.processing}
                        onChange={(e) => {onChangeHandler(arrayIndex, "processing", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh">Fresh</option>
                        <option value="Aseptically Processed">Aseptically Processed</option>
                        <option value="Terminal Sterilization Low Temp/Low-dose Irradiation">Terminal Sterilization Low Temp/Low-dose Irradiation</option>
                        <option value="Terminal Sterilization High dose Irradiation">Terminal Sterilization High dose Irradiation</option>
                        <option value="E-Beam">E-Beam</option>
                        <option value="Frozen">Frozen</option>
                        <option value="Lyphilization">Lyphilization</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "boneGraftFreshAllografts": {
      return (
        <div className="grafts-bone-graft-autografts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Bone Graft Fresh Allografts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Select
                        isMulti
                        value={boneGraftElbowAssociatedProcedureOptions.filter(option => formData.associatedProcedure.split(",").includes(option.value))}
                        name="associatedProcedure"
                        options={boneGraftElbowAssociatedProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "associatedProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Distal humerus allograft">Distal humerus allograft</option>
                        <option value="Proximal ulna allograft">Proximal ulna allograft</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "boneGraftSyntheticBoneVoidFillers": {
      return (
        <div className="grafts-bone-graft-synthetic-bone-void-fillers">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Bone Graft SyntheticBone Void Fillers
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                    <Form.Label>Associated Procedure</Form.Label>
                      {
                        study === "elbow"? (
                          <Select
                            isMulti
                            value={boneGraftElbowAssociatedProcedureOptions.filter(option => formData.associatedProcedure.split(",").includes(option.value))}
                            name="associatedProcedure"
                            options={boneGraftElbowAssociatedProcedureOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedValues) => {onChangeHandler(arrayIndex, "associatedProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                          />
                        ) : (
                          <Form.Select
                            value={formData.associatedProcedure}
                            onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                          >
                            <option value="" hidden>Select an option</option>
                            <option value="Os Acromiale">Os Acromiale</option>
                            <option value="Osteotomy">Osteotomy</option>
                            <option value="Osteochondral bone graft">Osteochondral bone graft</option>
                            <option value="Clavicle fracture treatment">Clavicle fracture treatment</option>
                            <option value="Greater tuberosity fracture treatment">Greater tuberosity fracture treatment</option>
                            <option value="Proximal humerus fracture treatment">Proximal humerus fracture treatment</option>
                            <option value="Glenoid fracture treatment">Glenoid fracture treatment</option>
                            <option value="Instability glenoid loss bone graft">Instability glenoid loss bone graft</option>
                            <option value="Instability humeral head bone graft">Instability humeral head bone graft</option>
                          </Form.Select>
                        )
                      }
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.boneGraftType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraftType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex Quickset resorbable bone cement">Arthrex Quickset resorbable bone cement</option>
                        <option value="Other resorbable bone cement">Other resorbable bone cement</option>
                        <option value="Non-resorbable bone cement">Non-resorbable bone cement</option>
                        <option value="Osferion">Osferion</option>
                        <option value="BoneSync calcium phosphate cement ">BoneSync calcium phosphate cement </option>
                        <option value="Other pre-formed shape (non-malleable) ">Other pre-formed shape (non-malleable) </option>
                        <option value="Pre-formed shape (malleable) ">Pre-formed shape (malleable) </option>
                        <option value="Granules ">Granules </option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Material</Form.Label>
                      <Select
                        isMulti
                        value={materialOptions.filter(option => formData.material.split(",").includes(option.value))}
                        name="material"
                        options={materialOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "material", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Bone graft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Volume used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={1}
                        max={20}
                        value={formData.volumeUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "volumeUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "cartilageGraftChondralGrafts": {
      return (
        <div className="grafts-cartilage-graft-chondral-grafts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cartilage Graft - Chondral Grafts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        {
                          study === "elbow"? (
                            <>
                              <option value="Capitellum">Capitellum</option>
                              <option value="Coronoid">Coronoid</option>
                              <option value="Olecranon">Olecranon</option>
                              <option value="Radial Head">Radial Head</option>
                              <option value="Trochlea">Trochlea</option>
                            </>
                          ) : (
                            <>
                              <option value="Glenoid">Glenoid</option>
                              <option value="Humerus">Humerus</option>
                            </>
                          )
                        }
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Autologous cartilage grafts</Form.Label>
                      <Form.Select
                        value={formData.autologousCartilageGrafts}
                        onChange={(e) => {onChangeHandler(arrayIndex, "autologousCartilageGrafts", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="ACI - Periosteum">ACI - Periosteum</option>
                        <option value="ACI - Synthetic patch">ACI - Synthetic patch</option>
                        <option value="Membrane ACI (MACI)">Membrane ACI (MACI)</option>
                        <option value="Minced autologous cartilage">Minced autologous cartilage</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Allogenic cartilage grafts</Form.Label>
                      <Form.Select
                        value={formData.alloglenicCartilageGrafts}
                        onChange={(e) => {onChangeHandler(arrayIndex, "alloglenicCartilageGrafts", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - BioCartilage (Fill in orthobiologic details)">Arthrex - BioCartilage (Fill in orthobiologic details)</option>
                        <option value="Arthrex-Cartiform 10mm disc">Arthrex-Cartiform 10mm disc</option>
                        <option value="Arthrex-Cartiform 20mm disc">Arthrex-Cartiform 20mm disc</option>
                        <option value="Minced juvenile cartilage">Minced juvenile cartilage</option>
                        <option value="Fenestrated adult cartilage sheet">Fenestrated adult cartilage sheet</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Orthobiologic details for BioCartilage</Form.Label>
                      <Select
                        isMulti
                        value={orthobiologicDetailOptions.filter(option => formData.orthobiologicDetails.split(",").includes(option.value))}
                        name="material"
                        options={orthobiologicDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "orthobiologicDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Orthobiologic distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - ACP">Arthrex - ACP</option>
                        <option value="Arthrex - Angel">Arthrex - Angel</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Cartilage graft volume delivered</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.volumeUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "volumeUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Cartilage graft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Angel system - Hematocrit setting</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={25}
                        value={formData.angelSystemHematocritSetting}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemHematocritSetting", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Angel system - Initial whole blood volume</Form.Label>
                      <Form.Control
                        type="number"
                        step={10}
                        min={40}
                        max={180}
                        value={formData.angelSystemInitialWholeBloodVolume}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemInitialWholeBloodVolume", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Angel system - PRP volume produced</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.angelSystemPrpVolumeProduced}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemPrpVolumeProduced", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>PRP-Type of activation</Form.Label>
                      <Form.Select
                        value={formData.prpTypeOfActivation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "prpTypeOfActivation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Endogenous">Endogenous</option>
                        <option value="Exogenous">Exogenous</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Bone Marrow Aspirate Concentrate</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.boneMarrowAspirateConcentrate}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneMarrowAspirateConcentrate", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Synthetic cartilage grafts</Form.Label>
                      <Form.Select
                        value={formData.syntheticCartilageGrafts}
                        onChange={(e) => {onChangeHandler(arrayIndex, "syntheticCartilageGrafts", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Injectible scaffold material">Injectible scaffold material</option>
                        <option value="Intact scaffold matrix">Intact scaffold matrix</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "cartilageGraftOsteochondralGrafts": {
      return (
        <div className="grafts-cartilage-graft-osteochondral-grafts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cartilage Graft - Osteochondral Grafts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Location</Form.Label>
                      <Form.Select
                        value={formData.location}
                        onChange={(e) => {onChangeHandler(arrayIndex, "location", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        {
                          study === "elbow"? (
                            <>
                              <option value="Capitellum">Capitellum</option>
                              <option value="Coronoid">Coronoid</option>
                              <option value="Olecranon">Olecranon</option>
                              <option value="Radial Head">Radial Head</option>
                              <option value="Trochlea">Trochlea</option>                            </>
                          ) : (
                            <>
                              <option value="Glenoid">Glenoid</option>
                              <option value="Humerus">Humerus</option>
                            </>
                          )
                        }
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type of graft</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Autograft OATS">Autograft OATS</option>
                        <option value="Allograft OATS">Allograft OATS</option>
                        <option value="BioMatrix CRD">BioMatrix CRD</option>
                        <option value="Other synthetic osteochondral graft">Other synthetic osteochondral graft</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Osteochondral graft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Allograft osteochondral plug processing</Form.Label>
                      <Form.Select
                        value={formData.processing}
                        onChange={(e) => {onChangeHandler(arrayIndex, "processing", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Fresh">Fresh</option>
                        <option value="Frozen">Frozen</option>
                        <option value="Room Temp">Room Temp</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Donor site</Form.Label>
                      <Form.Select
                        value={formData.donorSite}
                        onChange={(e) => {onChangeHandler(arrayIndex, "donorSite", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Femoral condyle">Femoral condyle</option>
                        <option value="Trochlea">Trochlea</option>
                        <option value="Tibia">Tibia</option>
                        <option value="Patella ">Patella </option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Allogenic cartilage graft used with bone graft</Form.Label>
                      <Form.Select
                        value={formData.alloglenicCartilageGrafts}
                        onChange={(e) => {onChangeHandler(arrayIndex, "alloglenicCartilageGrafts", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - BioCartilage (Fill in orthobiologic details)">Arthrex - BioCartilage (Fill in orthobiologic details)</option>
                        <option value="Arthrex-Cartiform 10mm disc">Arthrex-Cartiform 10mm disc</option>
                        <option value="Arthrex-Cartiform 20mm disc">Arthrex-Cartiform 20mm disc</option>
                        <option value="Minced juvenile cartilage">Minced juvenile cartilage</option>
                        <option value="Fenestrated adult cartilage sheet">Fenestrated adult cartilage sheet</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Orthobiologic details for BioCartilage/BioMatrix</Form.Label>
                      <Select
                        isMulti
                        value={orthobiologicDetailOptions.filter(option => formData.orthobiologicDetails.split(",").includes(option.value))}
                        name="material"
                        options={orthobiologicDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "orthobiologicDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Orthobiologic distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - ACP">Arthrex - ACP</option>
                        <option value="Arthrex - Angel">Arthrex - Angel</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Bone graft used with allogenic cartilage graft</Form.Label>
                      <Form.Select
                        value={formData.boneGraftType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraftType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Autograft">Autograft</option>
                        <option value="Allograft">Allograft</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Angel system - Hematocrit setting</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={25}
                        value={formData.angelSystemHematocritSetting}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemHematocritSetting", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Angel system - Initial whole blood volume</Form.Label>
                      <Form.Control
                        type="number"
                        step={10}
                        min={40}
                        max={180}
                        value={formData.angelSystemInitialWholeBloodVolume}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemInitialWholeBloodVolume", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Angel system - PRP volume produced</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={20}
                        value={formData.angelSystemPrpVolumeProduced}
                        onChange={(e) => {onChangeHandler(arrayIndex, "angelSystemPrpVolumeProduced", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>PRP-Type of activation</Form.Label>
                      <Form.Select
                        value={formData.prpTypeOfActivation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "prpTypeOfActivation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Endogenous">Endogenous</option>
                        <option value="Exogenous">Exogenous</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Bone Marrow Aspirate Concentrate</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.boneMarrowAspirateConcentrate}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneMarrowAspirateConcentrate", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "softTissueTendonGrafts": {
      return (
        <div className="grafts-soft-tissue-tendon-grafts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Soft Tissue - Tendon Grafts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Form.Select
                        value={formData.associatedProcedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="AC joint">AC joint</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        value={formData.boneGraftType}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraftType", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Autograft">Autograft</option>
                        <option value="Allograft">Allograft</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Graft details</Form.Label>
                      <Form.Select
                        value={formData.graftDetails}
                        onChange={(e) => {onChangeHandler(arrayIndex, "graftDetails", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="GraftRope allograft">GraftRope allograft</option>
                        <option value="VersaGraft">VersaGraft</option>
                        <option value="Semi-tendinosus and gracilis">Semi-tendinosus and gracilis</option>
                        <option value="Tibialis ">Tibialis </option>
                        <option value="Peroneus Longus">Peroneus Longus</option>
                        <option value="Other type graft">Other type graft</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Allograft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Allograft processing</Form.Label>
                      <Select
                        isMulti
                        value={processingOptions.filter(option => formData.processing.split(",").includes(option.value))}
                        name="material"
                        options={processingOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "processing", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Sterility assurance level</Form.Label>
                      <Form.Select
                        value={formData.sterilityAssuranceLevel}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sterilityAssuranceLevel", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Terminally sterilized (10-6)">Terminally sterilized (10-6)</option>
                        <option value="Aseptically processed (10-3)">Aseptically processed (10-3)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Tendon graft diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Tendon graft length</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={-10000}
                        max={10000}
                        value={formData.length}
                        onChange={(e) => {onChangeHandler(arrayIndex, "length", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "softTissueAugmentationGrafts": {
      return (
        <div className="grafts-soft-tissue-augmentation-grafts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Soft - Tissue Augmentation Grafts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                     <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Form.Select
                        value={formData.associatedProcedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="" hidden>Select an option</option>
                        {
                          study === "elbow"? (
                            <>
                              <option value="Tennis elbow (lateral)">Tennis elbow (lateral)</option>
                              <option value="Golfers elbow (medial)">Golfers elbow (medial)</option>
                              <option value="Distal biceps">Distal biceps</option>
                              <option value="Triceps">Triceps</option>
                            </>
                          ) : (
                            <>
                              <option value="Rotator cuff augmentation">Rotator cuff augmentation</option>
                              <option value="Pec Major">Pec Major</option>
                              <option value="Subscap Augmentation">Subscap Augmentation</option>
                              <option value="Superior capsule reconstruction graft">Superior capsule reconstruction graft</option>
                            </>
                          )
                        }
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Allograft dermis</Form.Label>
                      <Form.Select
                        value={formData.allograftDermis}
                        onChange={(e) => {onChangeHandler(arrayIndex, "allograftDermis", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex  ArthroFlex">Arthrex  ArthroFlex</option>
                        <option value="Arthrex BioWasher">Arthrex BioWasher</option>
                        <option value="Other allograft dermis">Other allograft dermis</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Synthetic</Form.Label>
                      <Form.Select
                        value={formData.synthetic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "synthetic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Silicone">Silicone</option>
                        <option value="Poly(urethaneurea)">Poly(urethaneurea)</option>
                        <option value="Other type">Other type</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Xenograft</Form.Label>
                      <Form.Select
                        value={formData.xenograft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "xenograft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex DX Reinforcement Matrix (Porcine dermis)">Arthrex DX Reinforcement Matrix (Porcine dermis)</option>
                        <option value="Other porcine dermis product">Other porcine dermis product</option>
                        <option value="Porcine SIS">Porcine SIS</option>
                        <option value="Equine pericardium ">Equine pericardium </option>
                        <option value="Fetal bovine dermis">Fetal bovine dermis</option>
                        <option value="Non-structural bovine collagen graft">Non-structural bovine collagen graft</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                      <Form.Label>Graft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Approximate thickness (Final implanted product)</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.1}
                        min={0}
                        max={5}
                        value={formData.thickness}
                        onChange={(e) => {onChangeHandler(arrayIndex, "thickness", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Approximate length (Final implanted product)</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={65}
                        value={formData.length}
                        onChange={(e) => {onChangeHandler(arrayIndex, "length", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Approximate width (Final implanted product)</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0.5}
                        max={50}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Sterility assurance level</Form.Label>
                      <Form.Select
                        value={formData.sterilityAssuranceLevel}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sterilityAssuranceLevel", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Terminally sterilized (10-6)">Terminally sterilized (10-6)</option>
                        <option value="Aseptically processed (10-3)">Aseptically processed (10-3)</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Processing</Form.Label>
                      <Form.Select
                        value={formData.processing}
                        onChange={(e) => {onChangeHandler(arrayIndex, "processing", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Hydrated (Matracell processing)">Hydrated (Matracell processing)</option>
                        <option value="Cryopreserved">Cryopreserved</option>
                        <option value="Freeze Dried">Freeze Dried</option>
                        <option value="Fresh">Fresh</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "softTissueAdiposeTissueGraft": {
      return (
        <div className="grafts-soft-tissue-adipose-tissue-graft">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Soft Tissue - Adipose Tissue Graft (Autograft)
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                     <Col>
                      <Form.Label>Type Used</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Unconcentrated">Unconcentrated</option>
                        <option value="Concentrated">Concentrated</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "softTissueMeniscalAllograftFasciaLata": {
      return (
        <div className="grafts-soft-tissue-meniscal-allograft-fascia-lata">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Soft Tissue - Meniscal Allograft, Fascia Lata, etc
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                     <Col>
                      <Form.Label>Type Used</Form.Label>
                      <Select
                        isMulti
                        value={softTissueMeniscalAllograftTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="material"
                        options={softTissueMeniscalAllograftTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Fascia Lata Size used</Form.Label>
                      <Form.Select
                        value={formData.size}
                        onChange={(e) => {onChangeHandler(arrayIndex, "size", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Allograft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Allograft processing</Form.Label>
                      <Select
                        isMulti
                        value={processingOptions.filter(option => formData.processing.split(",").includes(option.value))}
                        name="material"
                        options={processingOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "processing", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Sterility assurance level</Form.Label>
                      <Form.Select
                        value={formData.sterilityAssuranceLevel}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sterilityAssuranceLevel", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Terminally sterilized (10-6)">Terminally sterilized (10-6)</option>
                        <option value="Aseptically processed (10-3)">Aseptically processed (10-3)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "elbowSoftTissueTendonGrafts": {
      return (
        <div className="grafts-soft-tissue-tendon-grafts">
          <Accordion>
            <Accordion.Item eventKey="0">
               <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Soft Tissue - Tendon Grafts
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Form.Select
                        value={formData.associatedProcedure}
                        onChange={(e) => {onChangeHandler(arrayIndex, "associatedProcedure", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Tennis elbow (lateral)">Tennis elbow (lateral)</option>
                        <option value="Golfers elbow (medial)">Golfers elbow (medial)</option>
                        <option value="Distal biceps">Distal biceps</option>
                        <option value="Triceps">Triceps</option>
                        <option value="Ulnar ligament">Ulnar ligament</option>
                        <option value="Lateral ligament">Lateral ligament</option>
                        <option value="Radial ligament">Radial ligament</option>
                      </Form.Select>
                    </Col>
                    <Form.Label>Autograft</Form.Label>
                      <Select
                        isMulti
                        value={elbowSoftTissueTendonGraftAutograftOptions.filter(option => formData.autograft.split(",").includes(option.value))}
                        name="autograft"
                        options={elbowSoftTissueTendonGraftAutograftOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "autograft", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Allograft</Form.Label>
                      <Select
                        isMulti
                        value={elbowSoftTissueTendonGraftAllograftOptions.filter(option => formData.allograft.split(",").includes(option.value))}
                        name="allograft"
                        options={elbowSoftTissueTendonGraftAllograftOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "allograft", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Allograft distributor/marketer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Tendon graft diameter</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={1}
                        max={12}
                        value={formData.diameter}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diameter", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Tendon graft length</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={1}
                        max={12}
                        value={formData.length}
                        onChange={(e) => {onChangeHandler(arrayIndex, "length", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: return
  }
}

export default Grafts