import React from "react";
import { Form } from "react-bootstrap";

import './ASESShoulder.scss';

const ASESShoulder = ({ handleFormData,  values, error }) => {
  return (
    <div className="self-assessment-test ases">
      <p className="form-title">ASES</p>      
      <Form.Group className="form-group">
        <Form.Label>1. Heeft u pijn in uw schouder?</Form.Label>
        <Form.Text className="text-muted">
        </Form.Text>
        <Form.Select
          required
          value={values.ases.asesQuestion1}
          onChange={(event) => handleFormData("ases", "asesQuestion1", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>2. Heeft u 's nachts pijn in uw schouder?</Form.Label>
        <Form.Text className="text-muted">
        </Form.Text>
        <Form.Select
          required
          value={values.ases.asesQuestion2}
          onChange={(event) => handleFormData("ases", "asesQuestion2", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>3. Gebruikt u pijnstillers (paracetamol, ibuprofen, etc.)?</Form.Label>
        <Form.Text className="text-muted">
        </Form.Text>
        <Form.Select
          required
          value={values.ases.asesQuestion3}
          onChange={(event) => handleFormData("ases", "asesQuestion3", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>4. Gebruikt u recept-plichtige pijnstillers (tramadol, codeine of sterker)?</Form.Label>
        <Form.Text className="text-muted">
        </Form.Text>
        <Form.Select
          required
          value={values.ases.asesQuestion4}
          onChange={(event) => handleFormData("ases", "asesQuestion4", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
      <Form.Label>5. Hoeveel tabletten voor pijnstilling gebruikt u gemiddeld per dag?</Form.Label>
      <div className="pain-slider">
        <Form.Range
          required
          min="0" max="20"
          value={values.ases.asesQuestion5}
          onChange={(event) => handleFormData("ases", "asesQuestion5", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="20" step="1" value={values.ases.asesQuestion5} onChange={(event) => handleFormData("ases", "asesQuestion5", event.target.value.trim())}/>
      </div>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>6. Voelt uw schouder instabiel aan (alsof hij uit de kom gaat)?</Form.Label>
        <Form.Text className="text-muted">
        </Form.Text>
        <Form.Select
          required
          value={values.ases.asesQuestion6}
          onChange={(event) => handleFormData("ases", "asesQuestion6", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
      <Form.Label>7. Geef op de lijn aan hoe instabiel uw schouder is?</Form.Label>
      <Form.Text>Van 0 (geen instabiliteit) tot 10 (maximale instabiliteit)</Form.Text>
      <div className="pain-slider">
        <Form.Range
          required
          value={values.ases.asesQuestion7}
          min="0" max="10" step="0.1"
          onChange={(event) => handleFormData("ases", "asesQuestion7", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="10" step="0.1" value={values.ases.asesQuestion7} onChange={(event) => handleFormData("ases", "asesQuestion7", event.target.value.trim())}/>
      </div>
      </Form.Group>

      <p className="form-sub-title">Kies het antwoord dat uw huidige status het best beschrijft bij het doen van de volgende activiteiten:</p>      

      <Form.Group className="form-group">
        <Form.Label>8. Een jas aandoen?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion8}
          onChange={(event) => handleFormData("ases", "asesQuestion8", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>9. Slapen op de aangedane zijde?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion9}
          onChange={(event) => handleFormData("ases", "asesQuestion9", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>10. De rug wassen / bh-bandje achteren sluiten?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion10}
          onChange={(event) => handleFormData("ases", "asesQuestion10", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>11. Gebruik van toilet?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion11}
          onChange={(event) => handleFormData("ases", "asesQuestion11", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>12. Haar kammen?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion12}
          onChange={(event) => handleFormData("ases", "asesQuestion12", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>13. Hoge plank bereiken?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion13}
          onChange={(event) => handleFormData("ases", "asesQuestion13", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>14. 5 Kg boven schouderhoogte tillen?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion14}
          onChange={(event) => handleFormData("ases", "asesQuestion14", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>15. Bovenhands een bal gooien?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion15}
          onChange={(event) => handleFormData("ases", "asesQuestion15", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>16. Normale werk doen?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion16}
          onChange={(event) => handleFormData("ases", "asesQuestion16", event.target.value)}
        >
         <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>17. Normale sport doen?</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion17}
          onChange={(event) => handleFormData("ases", "asesQuestion17", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Kan ik niet">Kan ik niet</option>
          <option value="Zeer moeilijk">Zeer moeilijk</option>
          <option value="Iets moeilijk">Iets moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}

export default ASESShoulder