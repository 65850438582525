import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RxCross1 } from "react-icons/rx";
import Select from 'react-select';

import "./Complications.scss"

const rotatorCuffTypeOptions = [
  {value: "Failed Rotator Cuff repair", label:"Failed Rotator Cuff repair"},
  {value: "Trauma", label:"Trauma"},
  {value: "Impingement", label:"Impingement"},
  {value: "Loose anchor", label:"Loose anchor"},
  {value: "Mechanical symptoms", label:"Mechanical symptoms"},
]

const labrumAndInstabilityTypeOptions = [
  {value: "Painful/loose hardware", label: "Painful/loose hardware"},
  {value: "Arthritis/chondral injury", label: "Arthritis/chondral injury"},
  {value: "Recurrent instability (a traumatic)", label: "Recurrent instability (a traumatic)"},
  {value: "Recurrent instatibiliy (traumatic)", label: "Recurrent instatibiliy (traumatic)"},
]

const acJointTypeOptions = [
  {value: "Inadequate resection", label: "Inadequate resection"},
  {value: "Instability", label: "Instability"},
  {value: "Pain", label: "Pain"},
  {value: "Trauma", label: "Trauma"},
]

const cartilageOsteochondralTypeOptions = [
  {value: "Failure of repair", label: "Failure of repair"},
  {value: "Arthritis", label: "Arthritis"},
  {value: "Trauma/chondral injury", label: "Trauma/chondral injury"},
]  

const bleedingHematologicClottingTypeOptions = [
  {value: "Hematoma", label: "Hematoma"},
  {value: "Excessive blood loss", label: "Excessive blood loss"},
  {value: "Arterial injury", label: "Arterial injury"},
  {value: "Venous injury", label: "Venous injury"},
  {value: "Transfusion required", label: "Transfusion required"},
  {value: "Disease transmission", label: "Disease transmission"},
  {value: "DIC", label: "DIC"},
  {value: "DVT", label: "DVT"},
  {value: "PE", label: "PE"},
  {value: "Compartment syndrome", label: "Compartment syndrome"},
  {value: "Compartment release", label: "Compartment release"},
]

const nervousSystemTypeOptions = [
  {value: "Nerve laceration/transaction", label: "Nerve laceration/transaction"},
  {value: "Complex Regional Pain Syndrome (CRPS)", label: "Complex Regional Pain Syndrome (CRPS)"},
  {value: "Dysesthesia", label: "Dysesthesia"},
  {value: "Neuropraxia", label: "Neuropraxia"},
]

const infectionTypeOptions = [
  {value: "Acute", label: "Acute"},
  {value: "Late", label: "Late"},
  {value: "Implant removal required", label: "Implant removal required"},
  {value: "I&D required", label: "I&D required"},
  {value: "Hospital acquired", label: "Hospital acquired"},
  {value: "MRSA", label: "MRSA"},
  {value: "Pseudomonous", label: "Pseudomonous"},
]

const woundOptions = [
  {value: "Dehiscense", label: "Dehiscense"},
  {value: "Superficial infection", label: "Superficial infection"},
  {value: "Deep infection - no surgery required", label: "Deep infection - no surgery required"},
  {value: "Deep infection - additional surgery required", label: "Deep infection - additional surgery required"},
  {value: "Exposed suture", label: "Exposed suture"},
  {value: "Hardware palpable", label: "Hardware palpable"},
  {value: "Hardware erosion", label: "Hardware erosion"},
]

const fractureOptions = [
  {value: "Intra-operative", label: "Intra-operative"},
  {value: "Post-operative", label: "Post-operative"},
  {value: "Peri-prosthetic", label: "Peri-prosthetic"},
  {value: "Trauma", label: "Trauma"},
]

const motionLossTypeOptions = [
  {value: "Arthrofibrosis/scar tissue", label: "Arthrofibrosis/scar tissue"},
  {value: "Adhesions", label: "Adhesions"},
  {value: "Stiffness", label: "Stiffness"},
]

const heterotopicOssificationDetailOptions = [
  {value: "Prophylaxis", label: "Prophylaxis"},
  {value: "Oral", label: "Oral"},
  {value: "Radiation", label: "Radiation"},
]

const hardwareFailureTypeOptions = [
  {value: "Intra-operative", label: "Intra-operative"},
  {value: "Post-operative", label: "Post-operative"},
  {value: "Trauma", label: "Trauma"},
  {value: "Malposition", label: "Malposition"},
  {value: "Loose", label: "Loose"},
  {value: "Broken", label: "Broken"},
  {value: "Failure of fixation ", label: "Failure of fixation "},
  {value: "Implants as loose body", label: "Implants as loose body"},
]

const generalMedicalTypeOptions = [
  {value: "CVA/stroke (TIA)", label: "CVA/stroke (TIA)"},
  {value: "MI/heart attack", label: "MI/heart attack"},
  {value: "Arrhythmia (Heart block, atrial fibrillation)", label: "Arrhythmia (Heart block, atrial fibrillation)"},
  {value: "GI bleed", label: "GI bleed"},
  {value: "Death", label: "Death"},
]

const graftFailureTypeOptions = [
  {value: "CVA/stroke (TIA)", label: "CVA/stroke (TIA)"},
  {value: "MI/heart attack", label: "MI/heart attack"},
  {value: "Arrhythmia (heart block, atrial fibrillation)", label: "Arrhythmia (heart block, atrial fibrillation)"},
  {value: "GI Bleed", label: "GI Bleed"},    
  {value: "Death", label: "Death"},    
]

const arthroplastyFailureTypeOptions = [
  {value: "Trauma", label: "Trauma"},
  {value: "RC failure", label: "RC failure"},
  {value: "LTO failure", label: "LTO failure"},
  {value: "Component malposition", label: "Component malposition"},
  {value: "Loosening of prosthetic joint", label: "Loosening of prosthetic joint"},
  {value: "Dislocation of prosthetic joint", label: "Dislocation of prosthetic joint"},
  {value: "Prosthetic joint implant failure", label: "Prosthetic joint implant failure"},
  {value: "Peri-prosthetic fracture", label: "Peri-prosthetic fracture"},
  {value: "Peri-prosthetic osteolysis", label: "Peri-prosthetic osteolysis"},
  {value: "Bearing surface wear", label: "Bearing surface wear"},
  {value: "Other mechanical complication of prosthetic joint implant", label: "Other mechanical complication of prosthetic joint implant"},
  {value: "Other non-prosthetic mechanical complication", label: "Other non-prosthetic mechanical complication"},
]

const tendonRepairOrReconstructionAssociatedProcedureOptions = [
  {value: "Tennis elbow (lateral)", label: "Tennis elbow (lateral)"},
  {value: "Golfers elbow (medial)", label: "Golfers elbow (medial)"},
  {value: "Biceps", label: "Biceps"},
  {value: "Triceps", label: "Triceps"},
]

const tendonRepairOrReconstructionTypeOptions = [
  {value: "Failed repair/reconstruction", label: "Failed repair/reconstruction"},
  {value: "Trauma", label: "Trauma"},
  {value: "Loose hardware", label: "Loose hardware"},
  {value: "Mechanical symptoms", label: "Mechanical symptoms"},
]

const instabilityAssociatedProcedureOptions = [
  {value: "Ulnar ligament", label: "Ulnar ligament"},
  {value: "Radial ligament", label: "Radial ligament"},
  {value: "Lateral ligament", label: "Lateral ligament"},
]

const instabilityTypeOptions = [
  {value: "Failed repair/reconstruction", label: "Failed repair/reconstruction"},
  {value: "Loose hardware", label: "Loose hardware"},
  {value: "Arthritis/chondral injury", label: "Arthritis/chondral injury"},
  {value: "Recurrent instability (atraumatic)", label: "Recurrent instability (atraumatic)"},
  {value: "Recurrent instability (traumatic)", label: "Recurrent instability (traumatic)"},
]

const Complications = ({ formData, form, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "rotatorCuff": {
      return (
        <div className="complication-rotator-cuff">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Rotator Cuff
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={rotatorCuffTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={rotatorCuffTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.softTissueAugmentationFailure}
                        label="Soft tissue augmentation failure" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "softTissueAugmentationFailure", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "labrumAndInstability": {
      return (
        <div className="complication-labrum-and-instability">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Labrum and Instability
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={labrumAndInstabilityTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={labrumAndInstabilityTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "acJoint": {
      return (
        <div className="complication-ac-joint">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    AC Joint
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={acJointTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={acJointTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "cartilageOsteochondral": {
      return (
        <div className="complication-cartilage-osteochondral">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Cartilage/Osteochondral
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={cartilageOsteochondralTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={cartilageOsteochondralTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "bleedingHematologicClotting": {
      return (
        <div className="complication-bleeding-hematologic-clotting">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Bleeding/Hematologic/Clotting
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={bleedingHematologicClottingTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={bleedingHematologicClottingTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "nervousSystem": {
      return (
        <div className="complication-nervous-system">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Nervous System
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={nervousSystemTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={nervousSystemTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "infection": {
      return (
        <div className="complication-infection">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Infection
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={infectionTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={infectionTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "excessiveSwellingSepsisWound": {
      return (
        <div className="complication-excessive-swelling-sepsis-wound">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Excessive Swelling, Sepsis, Wound
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.excessiveSwelling}
                        label="Excessive Swelling" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "excessiveSwelling", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Wound</Form.Label>
                      <Select
                        isMulti
                        value={woundOptions.filter(option => formData.wound.split(",").includes(option.value))}
                        name="wound"
                        options={woundOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "wound", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "motionLoss": {
      return (
        <div className="complication-motion-loss">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Motion Loss
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={motionLossTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={motionLossTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "fractureAndDislocation": {
      return (
        <div className="complication-fracture-and-dislocation">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Fracture And Dislocation
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Fracture</Form.Label>
                      <Select
                        isMulti
                        value={fractureOptions.filter(option => formData.fracture.split(",").includes(option.value))}
                        name="fracture"
                        options={fractureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "fracture", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Dislocation</Form.Label>
                      <Form.Select
                        value={formData.dislocation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "dislocation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Prosthetic">Prosthetic</option>          
                        <option value="Non-prosthetic">Non-prosthetic</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "heterotopicOssification": {
      return (
        <div className="complication-heterotopic-ossification">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Heterotopic Ossification
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Details</Form.Label>
                      <Select
                        isMulti
                        value={heterotopicOssificationDetailOptions.filter(option => formData.details.split(",").includes(option.value))}
                        name="details"
                        options={heterotopicOssificationDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "details", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "nonUnionPseudoarthrosisMalunion": {
      return (
        <div className="complication-non-union-pseudoarthrosis-malunion">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Non-union/pseudoarthrosis, Malunion
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "avn": {
      return (
        <div className="complication-avn">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    AVN
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Number of weeks after surgery</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.5}
                        min={0}
                        max={10000}
                        value={formData.weeksAfterSurgery}
                        onChange={(e) => {onChangeHandler(arrayIndex, "weeksAfterSurgery", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "hardwareFailure": {
      return (
        <div className="complication-motion-loss">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Hardware Failure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={hardwareFailureTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={hardwareFailureTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.intraArticularHardwarePenetration}
                        label="Intra-articular hardware penetration" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "intraArticularHardwarePenetration", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.hardwareRemoval}
                        label="Hardware removal" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "hardwareRemoval", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "generalMedical": {
      return (
        <div className="complication-general-medical">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    General Medical
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={generalMedicalTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={generalMedicalTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "arthroplastyFailure": {
      return (
        <div className="complication-arthroplasty-failure">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Arthroplasty Failure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={arthroplastyFailureTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={arthroplastyFailureTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>    
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "graftFailure": {
      return (
        <div className="complication-graft-failure">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Graft Failure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={graftFailureTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={graftFailureTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>    
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "tendonRepairOrReconstruction": {
      return (
        <div className="complication-tendon-repair-or-reconstruction">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Tendon Repair/Reconstruction
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Select
                        isMulti
                        value={tendonRepairOrReconstructionAssociatedProcedureOptions.filter(option => formData.associatedProcedure.split(",").includes(option.value))}
                        name="type"
                        options={tendonRepairOrReconstructionAssociatedProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "associatedProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={tendonRepairOrReconstructionTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={tendonRepairOrReconstructionTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>    
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    case "instability": {
      return (
        <div className="complication-instability">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Instability
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Associated Procedure</Form.Label>
                      <Select
                        isMulti
                        value={instabilityAssociatedProcedureOptions.filter(option => formData.associatedProcedure.split(",").includes(option.value))}
                        name="type"
                        options={instabilityAssociatedProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "associatedProcedure", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={instabilityTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={instabilityTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>    
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.revisionRequired}
                        label="Revision required" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "revisionRequired", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>If medical product related then choose manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>          
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    default: {
      return null
    }
  }
}

export default Complications