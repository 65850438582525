import React from "react";
import "./CircleProgressBar.scss"

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct, r, width, height }) => {
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx="50%"
      cy="50%"
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"0.5rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1em"}
      fontWeight={"bold"}
      cursor={"pointer"}
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const CircleProgressBar = ({ percentage, colour, width, height, radius, onClickAction }) => {
  const pct = cleanPercentage(percentage);
  return (
    <div className="circle-progress-bar" style={{width: width, height: height}} onClick={onClickAction}>
      <svg width={width} height={height}>
        <g transform={`rotate(-90 ${"50 50"})`}>
          <Circle colour="lightgrey" r={radius} width={width} height={height}/>
          <Circle colour={colour} pct={pct} r={radius} width={width} height={height}/>
        </g>
        <Text percentage={pct} />
      </svg>
    </div>
  );
};

export default CircleProgressBar;
