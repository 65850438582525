import React from 'react'
import { Form } from "react-bootstrap"

import "./AsesShoulderObjectiveMeasures.scss"


const AsesShoulderObjectiveMeasures = ({onChangeHandler, formValues, error}) => {
  return (
    <div className='ases-shoulder-objective-measures'>
      <Form.Group className="form-group">
        <Form.Label>1. Shoulder treatment side</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion1 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion1}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion1", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Left">Left</option>
          <option value="Right">Right</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>2. Range of motion: Forward elevation (Maximum arm-trunk angle) - Active (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion2 === "" || parseInt(formValues.objectiveMeasuresQuestion2) < -360 || parseInt(formValues.objectiveMeasuresQuestion2) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion2}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion2", event.target.value)}
        />
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>3. Range of motion: Forward elevation (Maximum arm-trunk angle) - Passive (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion3 === "" || parseInt(formValues.objectiveMeasuresQuestion3) < -360 || parseInt(formValues.objectiveMeasuresQuestion3) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion3}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion3", event.target.value)}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>4. Range of motion: External rotation (Arm comfortably at side) - Active (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion4 === "" || parseInt(formValues.objectiveMeasuresQuestion4) < -360 || parseInt(formValues.objectiveMeasuresQuestion4) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion4}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion4", event.target.value)}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>5. Range of motion: External rotation (Arm comfortably at side) - Passive (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion5 === "" || parseInt(formValues.objectiveMeasuresQuestion5) < -360 || parseInt(formValues.objectiveMeasuresQuestion5) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion5}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion5", event.target.value)}
        />
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>6. Range of motion: External rotation (Arm at 90° abduction) - Active (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion6 === "" || parseInt(formValues.objectiveMeasuresQuestion6) < -360 || parseInt(formValues.objectiveMeasuresQuestion6) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion6}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion6", event.target.value)}
        />
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>7. Range of motion: External rotation (Arm at 90° abduction) - Passive (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion7 === "" || parseInt(formValues.objectiveMeasuresQuestion7) < -360 || parseInt(formValues.objectiveMeasuresQuestion7) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion7}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion7", event.target.value)}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>8. Range of motion: Internal rotation (Highest posterior anatomy reached with thumb) - Active</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion8 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion8}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion8", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="hip">Hip</option>
          <option value="sacrum">Sacrum</option>
          <option value="L5">L5</option>
          <option value="L4">L4</option>
          <option value="L3">L3</option>
          <option value="L2">L2</option>
          <option value="L1">L1</option>
          <option value="T12">T12</option>
          <option value="T11">T11</option>
          <option value="T10">T10</option>
          <option value="T9">T9</option>
          <option value="T8">T8</option>
          <option value="T7">T7</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>9. Range of motion: Internal rotation (Highest posterior anatomy reached with thumb) - Passive</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion9 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion9}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion9", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="hip">Hip</option>
          <option value="sacrum">Sacrum</option>
          <option value="L5">L5</option>
          <option value="L4">L4</option>
          <option value="L3">L3</option>
          <option value="L2">L2</option>
          <option value="L1">L1</option>
          <option value="T12">T12</option>
          <option value="T11">T11</option>
          <option value="T10">T10</option>
          <option value="T9">T9</option>
          <option value="T8">T8</option>
          <option value="T7">T7</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>10. Range of motion: Cross-body adduction (Antecubital fossa to opposite acromion) - Active (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion10 === "" || parseInt(formValues.objectiveMeasuresQuestion10) < -360 || parseInt(formValues.objectiveMeasuresQuestion10) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion10}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion10", event.target.value)}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>11. Range of motion: Cross-body adduction (Antecubital fossa to opposite acromion) - Passive (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion11 === "" || parseInt(formValues.objectiveMeasuresQuestion11) < -360 || parseInt(formValues.objectiveMeasuresQuestion11) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion11}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion11", event.target.value)}
        />
      </Form.Group>

      <hr />

      <Form.Group className="form-group">
        <Form.Label>12. Signs: Supraspinatus/Greater tuberosity tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion12 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion12}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion12", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>13. Signs: AC joint tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion13 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion13}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion13", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>14. Signs: Biceps tendon tenderness (or rupture)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion14 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion14}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion14", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>15. Signs: Other tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion15 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion15}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion15", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>16. Signs: Impingement I (Passive Forward elevation in slight internal rotation)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion16 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion16}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion16", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>17. Signs: Impingement II (Passive internal rotation with 90° flexion)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion17 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion17}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion17", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>18. Signs: Impingement III (90° active abduction - classic painful arc)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion18 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion18}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion18", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>19. Signs: Subacromial crepitus</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion19 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion19}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion19", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>20. Signs: Scars</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion20 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion20}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion20", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>21. Signs: Atrophy</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion21 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion21}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion21", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>22. Signs: Deformity</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion22 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion22}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion22", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <hr />

      <Form.Group className="form-group">
        <Form.Label>23. Strength: Testing affected by pain?</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion23 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion23}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion23", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>24. Strength: Forward elevation</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion24 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion24}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion24", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>25. Strength: Abduction</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion25 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion25}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion25", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>26. Strength: External rotation (Arm comfortably at side)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion26 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion26}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion26", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group> 
  
      <Form.Group className="form-group">
        <Form.Label>27. Strength: Internal rotation (Arm comfortably at side)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion27 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion27}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion27", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group> 

      <hr />
      
      <Form.Group className="form-group">
        <Form.Label>28. Instability: Anterior translation</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion28 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion28}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion28", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild (0-1 cm translation)">Mild (0-1 cm translation)</option>
          <option value="moderate (1-2 cm translation or translate to glenoid rim)">Moderate (1-2 cm translation or translate to glenoid rim)</option>
          <option value="severe (>2 cm translation or over rim of glenoid)">Severe (&gt; cm translation or over rim of glenoid)</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>29. Instability: Posterior translation</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion29 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion29}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion29", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild (0-1 cm translation)">Mild (0-1 cm translation)</option>
          <option value="moderate (1-2 cm translation or translate to glenoid rim)">Moderate (1-2 cm translation or translate to glenoid rim)</option>
          <option value="severe (>2 cm translation or over rim of glenoid)">Severe (&gt; cm translation or over rim of glenoid)</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>30. Instability: Inferior translation (sulcus sign)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion30 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion30}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion30", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild (0-1 cm translation)">Mild (0-1 cm translation)</option>
          <option value="moderate (1-2 cm translation or translate to glenoid rim)">Moderate (1-2 cm translation or translate to glenoid rim)</option>
          <option value="severe (>2 cm translation or over rim of glenoid)">Severe (&gt; cm translation or over rim of glenoid)</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>31. Instability: Anterior apprehension</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion31 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion31}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion31", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild (0-1 cm translation)">Mild (0-1 cm translation)</option>
          <option value="moderate (1-2 cm translation or translate to glenoid rim)">Moderate (1-2 cm translation or translate to glenoid rim)</option>
          <option value="severe (>2 cm translation or over rim of glenoid)">Severe (&gt; cm translation or over rim of glenoid)</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>32. Instability: Reproduces symptoms?</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion32 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion32}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion32", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>33. Instability: Voluntary instability?</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion33 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion33}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion33", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>34. Instability: Relocation test positive?</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion34 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion34}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion34", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>35. Instability: Generalized ligamentous laxity?</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion35 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion35}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion35", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
    </div>
  )
}

export default AsesShoulderObjectiveMeasures