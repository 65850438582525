import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RxCross1 } from "react-icons/rx";
import Select from 'react-select';

import "./SurgicalDressing.scss"

const slingBraceAplicationOptions = [
  {value: "None used", label: "None used"},
  {value: "Sling", label: "Sling"},
  {value: "Immobilization-abducted sling", label: "Immobilization-abducted sling"},
  {value: "Immobilization-neutral wedge", label: "Immobilization-neutral wedge"},
]

const SurgicalDressing = ({ formData, form, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "slingBraceApplication": {
      return (
        <div className="sling-brace-application">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Sling/Brace Application
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Sling/Brace Application</Form.Label>
                      <Select
                        // defaultValue={[colourOptions[2], colourOptions[3]]}
                        isMulti
                        value={slingBraceAplicationOptions.filter(option => formData.slingBrace.split(",").includes(option.value))}
                        name="slingBrace"
                        options={slingBraceAplicationOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "slingBrace", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    default: {
      return null
    }
  }
}

export default SurgicalDressing