import React from 'react'
import { Form } from "react-bootstrap"

import "./AsesElbowObjectiveMeasures.scss"


const AsesElbowObjectiveMeasures = ({onChangeHandler, formValues, error}) => {
  return (
    <div className='ases-elbow-objective-measures'>
      <Form.Group className="form-group">
        <Form.Label>1. Elbow treatment side</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion1 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion1}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion1", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Left">Left</option>
          <option value="Right">Right</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>2. Range of motion: Elbow flexion (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion2 === "" || parseInt(formValues.objectiveMeasuresQuestion2) < -360 || parseInt(formValues.objectiveMeasuresQuestion2) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion2}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion2", event.target.value)}
        />
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>3. Range of motion: Elbow extension (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion3 === "" || parseInt(formValues.objectiveMeasuresQuestion3) < -360 || parseInt(formValues.objectiveMeasuresQuestion3) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion3}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion3", event.target.value)}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>4. Range of motion: Elbow flexion/extension arc (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion4 === "" || parseInt(formValues.objectiveMeasuresQuestion4) < -360 || parseInt(formValues.objectiveMeasuresQuestion4) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion4}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion4", event.target.value)}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>5. Range of motion: Elbow pronation (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion5 === "" || parseInt(formValues.objectiveMeasuresQuestion5) < -360 || parseInt(formValues.objectiveMeasuresQuestion5) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion5}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion5", event.target.value)}
        />
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>6. Range of motion: Elbow supination (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion6 === "" || parseInt(formValues.objectiveMeasuresQuestion6) < -360 || parseInt(formValues.objectiveMeasuresQuestion6) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion6}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion6", event.target.value)}
        />
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>7. Range of motion: Elbow pronation/supination Arc (in degrees)</Form.Label>
        <Form.Control
          type="number"
          step={1}
          min={-360}
          max={360}
          style={{ border: error && (formValues.objectiveMeasuresQuestion7 === "" || parseInt(formValues.objectiveMeasuresQuestion7) < -360 || parseInt(formValues.objectiveMeasuresQuestion7) > 360) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion7}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion7", event.target.value)}
        />
      </Form.Group>

      <hr />

      <Form.Group className="form-group">
        <Form.Label>8. Stability: Valgus</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion8 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion8}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion8", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no instability">No instability</option>
          <option value="mild laxity with good endpoint">Mild laxity with good endpoint</option>
          <option value="moderate laxity no endpoint">Moderate laxity no endpoint</option>
          <option value="gross instability">Gross instability</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>9. Stability: Varus</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion9 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion9}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion9", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no instability">No instability</option>
          <option value="mild laxity with good endpoint">Mild laxity with good endpoint</option>
          <option value="moderate laxity no endpoint">Moderate laxity no endpoint</option>
          <option value="gross instability">Gross instability</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>10. Stability: Posterolateral rotatory</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion10 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion10}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion10", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no instability">No instability</option>
          <option value="mild laxity with good endpoint">Mild laxity with good endpoint</option>
          <option value="moderate laxity no endpoint">Moderate laxity no endpoint</option>
          <option value="gross instability">Gross instability</option>
        </Form.Select>
      </Form.Group>

      <hr />

      <Form.Group className="form-group">
        <Form.Label>11. Strength: Testing affected by pain?</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion11 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion11}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion11", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>12. Strength: Flexion</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion12 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion12}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion12", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>13. Strength: Extension</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion13 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion13}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion13", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>14. Strength: Pronation</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion14 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion14}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion14", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>15. Strength: Supination</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion15 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion15}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion15", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="no contraction">No contraction</option>
          <option value="flicker">Flicker</option>
          <option value="movement with gravity eliminated">Movement with gravity eliminated</option>
          <option value="movement against gravity">Movement against gravity</option>
          <option value="movement against some resistance">Movement against some resistance</option>
          <option value="normal power">Normal power</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>16. Strength: Grip strength (in kilograms)</Form.Label>
        <Form.Control
          type="number"
          step={0.1}
          min={0}
          max={200}
          style={{ border: error && (formValues.objectiveMeasuresQuestion16 === "" || parseFloat(formValues.objectiveMeasuresQuestion16) < 0 || parseFloat(formValues.objectiveMeasuresQuestion16) > 200) ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion16}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion16", event.target.value)}
        />
      </Form.Group>
      
      <hr />

      <Form.Group className="form-group">
        <Form.Label>17. Signs: Ulnohumeral tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion17 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion17}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion17", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>18. Signs: Radiocapitellar tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion18 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion18}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion18", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>19. Signs: Medial flexor origin  tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion19 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion19}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion19", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>20. Signs: Lateral extensor origin tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion20 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion20}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion20", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>21. Signs: Medial collateral ligament tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion21 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion21}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion21", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>22. Signs: Posterior interosseous nerve tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion22 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion22}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion22", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>23. Signs: Other tenderness</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion23 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion23}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion23", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>24. Signs: Impingement pain in flexion</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion24 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion24}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion24", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>25. Signs: Impingement pain in extension</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion25 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion25}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion25", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="none">None</option>
          <option value="mild">Mild</option>
          <option value="moderate">Moderate</option>
          <option value="severe">Severe</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>26. Signs: Pain on resisted wrist extension</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion26 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion26}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion26", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group> 
      
      <Form.Group className="form-group">
        <Form.Label>27. Signs: Pain on resisted wrist flexion</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion27 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion27}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion27", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>28. Signs: Pain on resisted long finger extension</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion28 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion28}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion28", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>29. Signs: Pain on resisted wrist pronation</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion29 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion29}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion29", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>30. Signs: Pain on resisted wrist supination</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion30 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion30}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion30", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>31. Signs: Ulnohumeral crepitus</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion31 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion31}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion31", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>32. Signs: Radiocapitellar crepitus</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion32 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion32}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion32", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>33. Signs: Scars</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion33 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion33}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion33", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>34. Signs: Atrophy</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion34 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion34}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion34", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>35. Signs: Deformity</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion35 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion35}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion35", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>36. Signs: Ulnar nerve tinels</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion36 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion36}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion36", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      
      <Form.Group className="form-group">
        <Form.Label>37. Signs: Cubital tunnel stretch test</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion37 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion37}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion37", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      
      <Form.Group className="form-group">
        <Form.Label>38. Signs: Other joints limiting activity (Shoulder)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion38 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion38}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion38", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
      
      <Form.Group className="form-group">
        <Form.Label>39. Signs: Other joints limiting activity (Wrist)</Form.Label>
        <Form.Select
          style={{ border: error && formValues.objectiveMeasuresQuestion39 === "" ? "2px solid red" : "" }}
          value={formValues.objectiveMeasuresQuestion39}
          onChange={(event) => onChangeHandler("objectiveMeasuresQuestion39", event.target.value)}
        >
          <option value="" hidden>Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Form.Select>
      </Form.Group>
      
    </div>
  )
}

export default AsesElbowObjectiveMeasures