import { React, useState, useEffect } from "react";
import validator from 'validator';
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';

import { Container, Button, Form, Card } from "react-bootstrap";
import StandardEarlyPostOp from '../../../components/SelfAssessment/StandardEarlyPostOp/StandardEarlyPostOp';
import VAS from '../../../components/SelfAssessment/VAS/VAS';
import FormCompletion from '../../../components/SelfAssessment/FormCompletion/FormCompletion';
import MultiStepProgressBar from '../../../components/MultiStepProgressBar/MultiStepProgressBar';
import SurveyAlreadyCompleted from "../../../components/SelfAssessment/SurveyAlreadyCompleted/SurveyAlreadyCompleted";
import { BASE_URL } from "../../../utils/constants";

import './AfterXWeeks.scss'

const AfterXWeeks = () => {
  const { eventId, customPatientId, week } = useParams();
  const treatmentSide = useParams()["treatmentSide"] === "left"? "Links" : "Rechts"
  const study = useParams()["study"].replace("-", " ")
  const navigate = useNavigate();
  useEffect(() => {
    if (!["2", "6"].includes(week) || !["elbow", "shoulder arthroscopy", "shoulder arthroplasty"].includes(study)) {
      navigate("/404")
    }
  });
  const numberOfSteps = week === "2"? 2 : 1;
  const [step, setStep] = useState(1);
  const [surveyCompleted, setSurveCompleted] = useState(false)
  const [validated, setValidated] = useState(false);
  
  const [formData, setFormData] = useState({
    vasScore: "",
    sepostop: {
      sepostopQuestion1: "",
      sepostopQuestion2: "",
      sepostopQuestion3: "",
    }
  })

  const checkSurveyCompletion = async () => {
    try {
      const stage = week === "2"? "two_weeks" : "six_weeks"
      const response = await axios.get(`${BASE_URL}/api/v1/self-assessment/check-survey-completion/${customPatientId}/${eventId}/${stage}`)
      setSurveCompleted(response.data.completed)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkSurveyCompletion()
  }, [])


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    const formError = validateCurrentForm()
    setValidated(true);
    if(!formError) {
      setValidated(false)
      setStep(step + 1);
    }
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (type, input, value) => {
    switch(type) {
      case "sepostop":
        setFormData(prevState => {
          prevState.sepostop[input] = value
          return{...prevState}
        });
        break;
      default: 
        setFormData(prevState => {
          prevState[input] = value
          return{...prevState}
        });
    } 
  }

const handleSubmit = async (event, type) => {
  event.preventDefault();
  const formError = validateCurrentForm()
  if(!formError) {
    try {
      await axios.post(`${BASE_URL}/api/v1/self-assessment/week/${week}`, {
        eventId: eventId,
        customPatientId: customPatientId,
        study: study,
        formAnswers: formData
      }, {headers:{"Content-Type" : "application/json"}})
      setStep(numberOfSteps + 1);
    }
    catch(error) {
      console.log(error.response.data)
      // switch to error screen
    }
  }
  else {
    setValidated(true)
  }
};

const validateCurrentForm = () =>{
  let formError = false
  switch(step) {
    case 1:
      formError = validator.isEmpty(formData.vasScore) || !validator.isFloat(formData.vasScore, { min: 0, max: 10 })
      break
    case 2:
      for(let i = 1; i <= 3; i++) {
        if(validator.isEmpty(formData.sepostop[`sepostopQuestion${i}`])){
          formError = true;
          break;
        }
      } 
      break;
    default: return formError;
  };
  return formError
}

const renderFormContent = step => {
  switch (step){
    // case 1 to show VAS form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return <VAS handleFormData={handleInputData} values={formData} />
    // case 2 to show SANE form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      if (week === "2") { 
        return <StandardEarlyPostOp handleFormData={handleInputData} study={study} values={formData} />
      }
      else {
        return <FormCompletion />
      } 
    case 3:
      return <FormCompletion />
    default:
      return <div></div>
  }
}

// javascript switch case to show different form in each step

  return (
    <div className="after-x-weeks">
    <Form validated={validated} onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{study === "elbow"? "Elleboog" : "Schouder"} ({treatmentSide}) Zelfevaluatie Na {week} Weken</Card.Header>
        {!surveyCompleted? 
          (
            <>
            <Card.Body>
              <Card.Title>
                <MultiStepProgressBar className="form-progress-bar" currentStep={step} numberOfSteps={numberOfSteps + 1} />
              </Card.Title>
              <Container className="form-content">
                {
                  renderFormContent(step)
                }
              </Container>
              </Card.Body>
              {step < (numberOfSteps + 1)? (
                <Card.Footer style={step === 1? {justifyContent: "flex-end"} : {justifyContent: "space-between"}}>
                {step !== 1? <Button className="btn-secondary float-start" onClick={prevStep}>Vorige Stap</Button> : null}
                {step < numberOfSteps? <Button className="btn-primary float-end" onClick={nextStep}>Volgende Stap</Button> : null}
                {step === numberOfSteps? <Button className="btn-primary float-end" onClick={handleSubmit}>Indienen</Button> : null}
              </Card.Footer>
              ) : null}
            </>
          ) :
          (
            <SurveyAlreadyCompleted />
          )
        }
      </Card>
    </Form>
    </div>
  )
}

export default AfterXWeeks
    