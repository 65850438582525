import { React, useState, useEffect } from "react";
import validator from 'validator';
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';

import { Container, Button, Form, Card } from "react-bootstrap";
import VAS from "../../../components/SelfAssessment/VAS/VAS";
import ASESElbow from '../../../components/SelfAssessment/ASESElbow/ASESElbow';
import ASESShoulder from '../../../components/SelfAssessment/ASESShoulder/ASESShoulder';
import SANE from "../../../components/SelfAssessment/SANE/SANE";
import VR12 from '../../../components/SelfAssessment/VR12/VR12';
import StandardLatePostOp from "../../../components/SelfAssessment/StandardLatePostOp/StandardLatePostOp";
import FormCompletion from '../../../components/SelfAssessment/FormCompletion/FormCompletion';
import MultiStepProgressBar from '../../../components/MultiStepProgressBar/MultiStepProgressBar';
import SurveyAlreadyCompleted from "../../../components/SelfAssessment/SurveyAlreadyCompleted/SurveyAlreadyCompleted";
import { BASE_URL } from "../../../utils/constants";
// import Final from "./Components/Forms/Final";

import './AfterXYears.scss'

const AfterXYears = () => {
  const { eventId, customPatientId, year } = useParams();
  const treatmentSide = useParams()["treatmentSide"] === "left"? "Links" : "Rechts"
  const study = useParams()["study"].replace("-", " ")
  const navigate = useNavigate();
  useEffect(() => {
    if (!["elbow", "shoulder arthroscopy", "shoulder arthroplasty"].includes(study) || ((study === "elbow" || study === "shoulder arthroscopy") && !["1", "2", "5"].includes(year)) || ((study === "shoulder arthroplasty") && !(0 < parseInt(year) <= 15))) {
      navigate("/404")
    }
  });
  const numberOfSteps = 5;
  const [step, setStep] = useState(1);
  const [surveyCompleted, setSurveCompleted] = useState(false)
  const [validated, setValidated] = useState(false);
  
  const [formData, setFormData] = useState({
    vasScore: "",
    saneScore: "",
    ases: {
      asesQuestion1: "",
      asesQuestion2: "",
      asesQuestion3: "",
      asesQuestion4: "",
      asesQuestion5: "",
      asesQuestion6: "",
      asesQuestion7: "",
      asesQuestion8: "",
      asesQuestion9: "",
      asesQuestion10: "",
      asesQuestion11: "",
      asesQuestion12: "",
      asesQuestion13: "",
      asesQuestion14: "",
      asesQuestion15: "",
      asesQuestion16: "",
      asesQuestion17: "",
      asesQuestion18: ""
    },
    vr12: {
      vr12Question1: "",
      vr12Question2: "",
      vr12Question3: "",
      vr12Question4: "",
      vr12Question5: "",
      vr12Question6: "",
      vr12Question7: "",
      vr12Question8: "",
      vr12Question9: "",
      vr12Question10: "",
      vr12Question11: "",
      vr12Question12: "",
      vr12Question13: "",
      vr12Question14: ""
    },
    slpostop: {
      slpostopQuestion1: "",
      slpostopQuestion2: "",
      slpostopQuestion3: "",
      slpostopQuestion4: ""
    }
  })

  const checkSurveyCompletion = async () => {
    try {
      const yearColumnValueMap = {
        "1": "one_year",
        "2": "two_years",
        "3": "three_years",
        "4": "four_years",
        "5": "five_years",
        "6": "six_years",
        "7": "seven_years",
        "8": "eight_years",
        "9": "nine_years",
        "10": "ten_years",
        "11": "eleven_years",
        "12": "twelve_years",
        "13": "thirteen_years",
        "14": "fourteen_years",
        "15": "fifteen_years",
      }
      const response = await axios.get(`${BASE_URL}/api/v1/self-assessment/check-survey-completion/${customPatientId}/${eventId}/${yearColumnValueMap[year]}`)
      setSurveCompleted(response.data.completed)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkSurveyCompletion()
  }, [])


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    const formError = validateCurrentForm()
    setValidated(true);
    if(!formError) {
      setValidated(false)
      setStep(step + 1);
    }
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (type, input, value) => {
    switch(type) {
      case "ases":
        setFormData(prevState => {
          prevState.ases[input] = value
          return{...prevState}
        });
      break; 
      case "vr12":
        setFormData(prevState => {
          prevState.vr12[input] = value
          return{...prevState}
        });
      break;
      case "slpostop":
        setFormData(prevState => {
          prevState.slpostop[input] = value
          return{...prevState}
        });
      break;
      default:
        setFormData(prevState => {
          prevState[input] = value
          return{...prevState}
        });
    } 
  }

const handleSubmit = async (event, type) => {
  event.preventDefault();
  const formError = validateCurrentForm()
  if(!formError) {
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/self-assessment/year/${year}`, {
        eventId: eventId,
        customPatientId: customPatientId,
        study: study,
        formAnswers: formData
      }, {headers:{"Content-Type" : "application/json"}})
      console.log(response.data)
      setStep(numberOfSteps + 1);
    }
    catch(error) {
      console.log(error.response.data)
      // switch to error screen
    }
  }
  else {
    setValidated(true)
  }
};

const validateCurrentForm = () =>{
  let formError = false
  switch(step) {
    case 1: 
      formError = validator.isEmpty(formData.vasScore) || !validator.isFloat(formData.vasScore, { min: 0, max: 10 })
      break;
    case 2:
      const j = (study === "elbow"? 18 : 17)
        for(let i = 1; i <= j; i++) {
          if(validator.isEmpty(formData.ases[`asesQuestion${i}`])){
            formError = true
            break;
          }
          if(study !== "elbow" && i === 5 && !validator.isInt(formData.ases[`asesQuestion${i}`], { min: 0, max: 20 })) {
            formError = true
            break;
          }
          if(study !== "elbow" && i === 7 && !validator.isFloat(formData.ases[`asesQuestion${i}`], { min: 0, max: 10 })) {
            formError = true
            break;
          }
          if(study === "elbow" && [2, 3, 4, 5, 6].includes(i) && !validator.isInt(formData.ases[`asesQuestion${i}`], { min: 0, max: 10 })) {
            formError = true
            break;
          }
        }
      break;
    case 3:
      formError = validator.isEmpty(formData.saneScore) || !validator.isInt(formData.saneScore, { min: 0, max: 100 }); 
      break;
    case 4:
      for(let i = 1; i <= 14; i++) {
        if(validator.isEmpty(formData.vr12[`vr12Question${i}`])){
          formError = true;
          break;
        }
      } 
      break;
    case 5:
      for(let i = 1; i <= 4; i++) {
        if(validator.isEmpty(formData.slpostop[`slpostopQuestion${i}`])){
          formError = true;
          break;
        }
      } 
      break;
    default: 
      return formError;
  };
  return formError
}

const renderFormContent = step => {
  switch (step){
    // case 1 to show VAS form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return <VAS handleFormData={handleInputData} values={formData}/>
    // case 2 to show SANE form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      if(study === "elbow") {
        return <ASESElbow handleFormData={handleInputData} values={formData}/>
      }
      else {
        return <ASESShoulder handleFormData={handleInputData} values={formData}/>
      }
    case 3:
      return <SANE handleFormData={handleInputData} study={study} values={formData}/>
    case 4:
      return <VR12 handleFormData={handleInputData} values={formData}/>
    case 5:
      return <StandardLatePostOp handleFormData={handleInputData} values={formData}/>
    case 6:
      return <FormCompletion />
    default:
      return <div className="after-x-years"></div>
  }
}

// javascript switch case to show different form in each step

  return (
    <div className="after-x-years">
    <Form validated={validated} onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{study === "elbow"? "Elleboog" : "Schouder"} ({treatmentSide}) Zelfevaluatie Na {year} Jaar</Card.Header>
        {!surveyCompleted? 
          (
            <>
              <Card.Body>
                <Card.Title>
                  <MultiStepProgressBar className="form-progress-bar" currentStep={step} numberOfSteps={numberOfSteps + 1} />
                </Card.Title>
                <Container className="form-content">
                  {
                    renderFormContent(step)
                  }
                </Container>
              </Card.Body>
              {step < (numberOfSteps + 1)? (
                <Card.Footer style={step === 1? {justifyContent: "flex-end"} : {justifyContent: "space-between"}}>
                  {step !== 1? <Button className="btn-secondary float-start" onClick={prevStep}>Vorige Stap</Button> : null}
                  {step < numberOfSteps? <Button className="btn-primary float-end" onClick={nextStep}>Volgende Stap</Button> : null}
                  {step === numberOfSteps? <Button className="btn-primary float-end" onClick={handleSubmit}>Indienen</Button> : null}
                </Card.Footer>
              ) : null}
            </>
          ):
          (
            <SurveyAlreadyCompleted />
          )
        }
        
      </Card>
    </Form>
    </div>
  )
}

export default AfterXYears
    