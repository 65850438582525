import React, { useState, useEffect } from 'react'
import { Button } from "react-bootstrap"
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { Oval } from "react-loader-spinner"
import { toast } from 'react-toastify';
import { FaUserDoctor } from "react-icons/fa6";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment"
import PatientInformationSurgicalTimeAndPersonnel from '../../components/ArthrexCatalog/General/PatientInformationSurgicalTimeAndPersonnel/PatientInformationSurgicalTimeAndPersonnel'
import HandDominance from '../../components/ArthrexCatalog/General/HandDominance/HandDominance';
import Diagnosis from '../../components/ArthrexCatalog/General/Diagnosis/Diagnosis';
import Operations from '../../components/ArthrexCatalog/General/Operations/Operations';

import "./SurgeryDetails.scss"
import OperationsLabrumAndInstability from '../../components/ArthrexCatalog/General/OperationsLabrumAndInstability/OperationsLabrumAndInstability';
import Implants from '../../components/ArthrexCatalog/General/Implants/Implants';
import SurgicalDressing from '../../components/ArthrexCatalog/General/SurgicalDressing/SurgicalDressing';
import Grafts from '../../components/ArthrexCatalog/General/Grafts/Grafts';
import OperationsAndImplants from '../../components/ArthrexCatalog/ShoulderArthroplasty/OperationsAndImplants/OperationsAndImplants';
import PostOperativeManagement from '../../components/ArthrexCatalog/ShoulderArthroplasty/PostOperativeManagement/PostOperativeManagement';

const studyAbbreviationMap = {
  "shoulder arthroscopy": "SAs",
  "shoulder arthroplasty": "SAp",
  "elbow": "Elbow "
}

const SurgeryDetails = ({ user, logUserOut }) => {
  const navigate = useNavigate()
  const { customPatientId, eventId } = useParams()
  const [surgeryEvent, setSurgeryEvent ] = useState(null)
  const [catalogEntries, setCatalogEntries] = useState([])
  const [deletedEntries, setDeletedEntries] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [eventUpdated, setEventUpdated] = useState(false)
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getSurgeryEventBydId = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}`, config)
        const event = response.data.event
        setSurgeryEvent(event)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getSurgeryEventBydId()
  }, [eventId])

  useEffect(() => {
    const getCatalogEntriesByEventId = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}/surgery-metadata`, config)
        const catalogEntries = response.data.catalogEntries
        setCatalogEntries(catalogEntries)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getCatalogEntriesByEventId()
  }, [eventId, eventUpdated])

  const addCatalogEntry = (category, form) => {
    switch(category) {
      case "patientInformationSurgicalTimeAndPersonnel":
        switch(form) {
          case "patientInformation":
            setCatalogEntries(prevState => [
                ...prevState,
                {
                  category: "patientInformationSurgicalTimeAndPersonnel",
                  form: "patientInformation",
                  formData: {
                    "weight": "", 
                    "height": "",
                    "durationOfSymptoms": "",
                    "profession": "",
                    "principalSport": "",
                    "smoker": "",
                    "diabetic": "",
                    "workmansCompensationCase": "",
                    "preOperativeUseOfNarcotics": "",
                  }
                }  
            ])
          break;
          case "surgicalTimeAndPersonnel":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "patientInformationSurgicalTimeAndPersonnel",
                form: "surgicalTimeAndPersonnel",
                formData: {
                  "surgicalTime": "",
                  "numberOfSurgicalTechs": "",
                  "numberOfPas": "",
                  "numberOfSurgicalAssistants": "",
                  "numberOfNurses": "",
                  "numberOfSurgeons": "",
                  "numberOfFellows": "",
                  "numberOfResidents": "",
                  "numberOfNursePractitioners": "",
                  "numberOfOrthopedicTechnologists": "",
                }
              }  
            ])
          break;
          default: return
        }
      break;
      case "handDominance":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            category: "handDominance",
            formData: {
              "handDominance": "", 
            }
          }  
        ])
      break;
      case "diagnosis":
        switch(form) {
          case "rotatorCuff":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "rotatorCuff",
                formData: {
                  "acuteOrChronic": "",
                  "numberOfTendonsTorn": "",
                  "rotatorCuffTendonQuality": "",
                  "snyderClassification": "",
                  "cofieldClassification": "",
                  "rotatorCuffRetractionStage": "",
                  "rotatorDimensionsAnteriorToPosterior": "",
                  "tearSizeAnteriorToPosterior": "",
                  "tearSizeMedialToLateral": "",
                  "rotatorCuffTearShape": "",
                  "supraspinatusTear": "",
                  "goutallierStageSupraspinatus": "",
                  "infraspinatusTear": "",
                  "goutallierStageInfraspinatus": "",
                  "laFosseClassification": "",
                  "goutallierStageSubScapularis": "",
                  "teresMinorTear": "",
                  "goutallierStageTeresMinor": "",
                }
              }  
            ])
          break;
          case "subacromialImpingement":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "subacromialImpingement",
                formData: {
                  "acromionType": "",
                }
              }  
            ])
          break;
          case "labralInjuryAndInstability":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "labralInjuryAndInstability",
                formData: {
                  "directionOfInstability": "",
                  "glenoHumeralDislocation": "",
                  "slapLesion": "",
                  "labralTearAnterior": "",
                  "labralTearPosterior": "",
                  "glenoidBoneDefect": "",
                  "humeralHeadHillsSachsLesion": "",
                  "hagl": "",
                  "alpsa": false,
                  "glenoHumeralChondropathy": false
                }
              }  
            ])
          break;
          case "biceps":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "biceps",
                formData: {
                  "degreeOfTear": "",
                  "condition": "",
                }
              }  
            ])
          break;
          case "acJoint":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "acJoint",
                formData: {
                  "acuteOrChronic": "",
                  "acInstabilityType": "",
                }
              }  
            ])
          break;
          case "osAcromiale":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "osAcromiale",
                formData: {
                  "type": "",
                }
              }  
            ])
          break;
          case "glenohumeralArthritis":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "glenohumeralArthritis",
                formData: {
                  "typglenoidOuterbridgeGradee": "",
                  "humerusOuterbridgeGrade": "",
                  "kellgrenLawrenceClassification": "",
                }
              }  
            ])
          break;
          case "articularCartilageOsteochondralLesion":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "articularCartilageOsteochondralLesion",
                formData: {
                  "location": "",
                  "probableCause": "",
                  "icrsGrade": "",
                  "length": "",
                  "width": "",
                  "area": "",
                  "depth": "",
                  "fullThicknessCartilageDefectWithWellDefinedBorders": false
                }
              }  
            ])
          break;
          case "otherIntraArticular":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "otherIntraArticular",
                formData: {
                  "synovialDisease": false,
                  "synovialCyst": false,
                  "looseBodyGreaterThan1Cm": false
                }
              }  
            ])
          break;
          case "fracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "fracture",
                formData: {
                  "clavicleDistalThird": "",
                  "clavicleMiddleThird": "",
                  "clavicleProximalThird": "",
                  "proximalHumerus": "",
                  "glenoid": "",
                  "fractureStatus": "",
                  "acromion": false,
                  "coracoid": false,
                  "scapularBody": false,
                }
              }  
            ])
          break;
          case "miscellaneous":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "miscellaneous",
                formData: {
                  "scJoint": "",
                  "pecMajorRupture": "",
                  "romAbnormalities": "",
                  "hematoma": false,
                  "infection": false,
                  "fattyAtrophy": false,
                  "suprascapularNerveCompression": false,
                  "subacromialBursitis": false,
                  "scapulothoracicBursitis": false,
                  "woundProblem": false,
                }
              }  
            ])
          break;
          case "arthritis":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "arthritis",
                formData: {
                  "type": "",
                }
              }  
            ])
          break;
          case "rotatorCuffArthropathy":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "rotatorCuffArthropathy",
                formData: {
                  "subscap": "",
                }
              }  
            ])
          break;
          case "failedRotatorCuffRepair":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "failedRotatorCuffRepair",
                formData: {
                  "subscap": "",
                }
              }  
            ])
          break;
          case "failedShoulderArthroplasty":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "failedShoulderArthroplasty",
                formData: {
                  "type": "",
                  "sizeOfFocalHumeralHeadResurfacingImplant": "",
                  "indicationForRevision": "",
                  "subscap": "",
                }
              }  
            ])
          break;
          case "clavicleFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "clavicleFracture",
                formData: {
                  "clavicleDistalThird": "",
                  "clavicleMiddleThird": "",
                  "clavicleProximalThird": "",
                  "fractureStatus": "",
                }
              }  
            ])
          break;
          case "humeralFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "humeralFracture",
                formData: {
                  "type": "",
                  "acute": "",
                  "priorFractureSequelae": "",
                  "fractureStatus": "",
                }
              }  
            ])
          break;
          case "scapulaFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "scapulaFracture",
                formData: {
                  "type": "",
                  "fractureStatus": "",
                }
              }  
            ])
          break;
          case "chronicRotatorCuffTear":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "chronicRotatorCuffTear",
                formData: {
                  "subscap": "",
                  "hamadaClassification": "",
                }
              }  
            ])
          break;
          case "tendon":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "tendon",
                formData: {
                  "tendon": "",
                  "acuteOrChronic": "",
                  "type": "",
                  "other": "",
                }
              }  
            ])
          break;
          case "elbowLigamentInstability":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowLigamentInstability",
                formData: {
                  "ligament": "",
                  "acuteOrChronic": "",
                  "type": "",
                  "directionOfInstability": "",
                  "other": "",
                }
              }  
            ])
          break;
          case "elbowOsteochondralLesion":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowOsteochondralLesion",
                formData: {
                  "location": "",
                  "probableCause": "",
                  "length": "",
                  "width": "",
                  "area": "",
                  "depth": "",
                  "calcificTendonosis": false,
                  "icrsGrade": "",
                }
              }  
            ])
          break;
          case "elbowOsteoarthritis":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowOsteoarthritis",
                formData: {
                  "location": "",
                  "grade": "",
                  "osteophytes": "",
                }
              }  
            ])
          break;
          case "elbowOtherInflammatoryArthritis":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowOtherInflammatoryArthritis",
                formData: {
                  "type": "",
                }
              }  
            ])
          break;
          case "elbowLooseBody":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowLooseBody",
                formData: {
                  "compartement": "",
                }
              }  
            ])
          break;
          case "elbowCapsuleSynovium":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowCapsuleSynovium",
                formData: {
                  "synovitis": "",
                  "capsularPlicaPosterolateralImpingement": "",
                  "capsularContracture": "",
                }
              }  
            ])
          break;
          case "elbowNerve":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowNerve",
                formData: {
                  "acuteOrChronic": "",
                  "type": "",
                  "capsularContracture": "",
                }
              }  
            ])
          break;
          case "elbowFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowFracture",
                formData: {
                  "intraArticularFracture": "",
                  "avulsionFracture": "",
                  "distalHumerusFracture": false,
                  "proximalBothBonesForearmFracture": false,
                  "stressFracture": "",
                  "apophysealInjuries": "",
                  "fractureStatus": "",
                }
              }  
            ])
          break;
          case "olecranonSpur":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "olecranonSpur",
                formData: {
                  "location": "",
                }
              }  
            ])
          break;
          case "elbowOther":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "diagnosis",
                form: "elbowOther",
                formData: {
                  "rheumatoidArthritis": "",
                  "septicArthritis": "",
                  "olecranonBursitis": "",
                }
              }  
            ])
          break;
          default: return
        }
      break;
      case "operations":
        switch(form) {
          case "rotatorCuffCompleteTear":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "rotatorCuffCompleteTear",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "anchorRepairTechniqueUsed": "",
                  "rotatorCuffTearImplants": "",
                  "numberOfAnchorsUsed": "",
                }
              }  
            ])
          break;
          case "rotatorCuffPartialTear":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "rotatorCuffPartialTear",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "anchorRepairTechniqueUsed": "",
                  "rotatorCuffTearImplants": "",
                  "numberOfAnchorsUsed": ""
                }
              }  
            ])
          break;
          case "superiorCapsuleReconstruction":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "superiorCapsuleReconstruction",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "glenoidSideGraftFixation": "",
                  "tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon": false,
                  "humeralSideGraftFixation": "",
                  "graftUsed": "",
                  "armPositionForMeasuringPositioningAndSecuringGraft": "",
                  "posteriorMarginConvergencePerformed": false,
                  "anteriorMarginConvergencePerformed": false,
                  "superiorLabrumManagement": "",
                }
              }  
            ])
          break;
          case "subscap":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "subscap",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "procedures": "",
                  "anchorRepairTechniqueUsed": "",
                }
              }  
            ])
          break;
          case "subAcromialDecompression":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "subAcromialDecompression",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "tendonTransfer":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "tendonTransfer",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "biceps":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "biceps",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                  "techniquesAndFixationDevice": ""
                }
              }  
            ])
          break;
          case "coracoplasty":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "coracoplasty",
                formData: {
                  "primaryOrRevision": "",
                }
              }  
            ])
          break;
          case "distalClavicleExcision":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "distalClavicleExcision",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                }
              }  
            ])
          break;
          case "acJointStabilization":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "acJointStabilization",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                  "numberOfTightRopesUsed": "",
                }
              }  
            ])
          break;
          case "osAcromiale":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "osAcromiale",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "osteotomy":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "osteotomy",
                formData: {
                  "primaryOrRevision": "",
                  "location": "",
                }
              }  
            ])
          break;
          case "cartilageTreatment":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "cartilageTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "location": "",
                  "procedures": "",
                  "microfractureAwlOrDrillingPowerPickUsed": "",
                  "orthobiologicDetails": "",
                  "numberOfAnchorsUsed": "",
                  "orthobiologicManufacturer": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                }
              }  
            ])
          break;
          case "osteochondralTreatment":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "osteochondralTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "location": "",
                  "osteochondralGraftType": "",
                  "numberOfPlugsUsed": "",
                  "avgDiameterOfPlugsUsed": "",
                  "totalApproxSizeOfImplantedPlug": "",
                }
              }  
            ])
          break;
          case "otherIntraArticular":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "otherIntraArticular",
                formData: {
                  "primaryOrRevision": "",
                  "synovialDebridement": "",
                  "cystRemoval": "",
                  "looseBodyRemoval": "",
                }
              }  
            ])
          break;
          case "fractureManagement":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "fractureManagement",
                formData: {
                  "primaryOrRevision": "",
                  "clavicleFractureProcedure": "",
                  "greaterTuberosityFractureProcedure": "",
                  "proximalHumerusFractureProcedure": "",
                  "glenoidFractureProcedure": "",
                  "scapulaFractureProcedure": "",
                }
              }  
            ])
          break;
          case "miscellaneous":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "miscellaneous",
                formData: {
                  "primaryOrRevision": "",
                  "capsularRelease": "",
                  "diagnosticArthroscopy": false,
                  "extensiveGlenohumeralDebridement": false,
                  "irrigationAndDebridement": false,
                  "lysisOfAdhesions": false,
                  "manipulationUnderAnesthesia": false,
                  "subacromialBursectomy": false,
                  "scapulothoracicBursectomy": false,
                  "pecMajorRepair": false,
                  "suprascapularNerveRelease": "",
                  "scJoint": "",
                }
              }  
            ])
          break;
          case "elbowCaseDetails":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowCaseDetails",
                formData: {
                  "anesthesiaType": "",
                  "patientPosition": "",
                }
              }  
            ])
          break;
          case "elbowTendonProcedures":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowTendonProcedures",
                formData: {
                  "type": "",
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "procedures": "",
                  "procedures2": "",
                  "procedures3": "",
                }
              }  
            ])
          break;
          case "elbowCollateralLigaments":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowCollateralLigaments",
                formData: {
                  "ligament": "",
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "procedures": "",
                  "details": "",
                }
              }  
            ])
          break;
          case "elbowCartilageTreatment":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowCartilageTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "location": "",
                  "type": "",
                  "microfractureAwlOrDrillingPowerPickUsed": "",
                  "orthobiologicDetails": "",
                  "numberOfAnchorsUsed": "",
                  "orthobiologicManufacturer": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                  "boneMarrowAspirateConcentrate": "",
                }
              }  
            ])
          break;
          case "elbowOsteochondralTreatment":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowOsteochondralTreatment",
                formData: {
                  "primaryOrRevision": "",
                  "location": "",
                  "numberOfPlugsUsed": "",
                  "avgDiameterOfPlugsUsed": "",
                }
              }  
            ])
          break;
          case "elbowOsteophyteDebridementExcision":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowOsteophyteDebridementExcision",
                formData: {
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "location": "",
                }
              }  
            ])
          break;
          case "elbowRemovalLooseBody":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowRemovalLooseBody",
                formData: {
                  "compartement": "",
                }
              }  
            ])
          break;
          case "elbowCapsuleSynovium":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowCapsuleSynovium",
                formData: {
                  "manipulationUnderAnesthesia": "",
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                  "procedures": "",
                  "location": "",
                }
              }  
            ])
          break;
          case "elbowNerve":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elbowNerve",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "elobwOrif":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "elobwOrif",
                formData: {
                  "primaryOrRevision": "",
                  "location": "",
                  "type": "",
                }
              }  
            ])
          break;
          case "radialHeadExcision":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "radialHeadExcision",
                formData: {
                  "openOrArthroscopic": "",
                  "arthroscopicPortalsUsed": "",
                }
              }  
            ])
          break;
          case "radialHeadImplant":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "radialHeadImplant",
                formData: {
                  "primaryOrRevision": "",
                }
              }  
            ])
          break;
          case "totalElbowArthroplasty":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operations",
                form: "totalElbowArthroplasty",
                formData: {
                  "primaryOrRevision": "",
                }
              }  
            ])
          break;
          default: return
        }
      break;
      case "operationsLabrumAndInstability":
        switch(form) {
          case "slap":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "slap",
                formData: {
                  "primaryOrRevision": "", 
                  "procedures": "", 
                  "repairDetails": "",
                  "typeOfAnchorUsed": "",
                }
              }  
            ])
          break;
          case "labralRepair":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "labralRepair",
                formData: {
                  "primaryOrRevision": "", 
                  "open": false, 
                  "procedures": "", 
                  "repairDetails": "",
                }
              }  
            ])
          break;
          case "capsulorrhaphy":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "capsulorrhaphy",
                formData: {
                  "primaryOrRevision": "", 
                  "openOrArthroscopic": "", 
                  "procedures": "", 
                  "withAnchors": false,
                }
              }  
            ])
          break;
          case "haglRepair":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "haglRepair",
                formData: {
                  "primaryOrRevision": "", 
                  "open": false, 
                  "haglRepairType": "", 
                }
              }  
            ])
          break;
          case "alpsaRepair":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "alpsaRepair",
                formData: {
                  "primaryOrRevision": "", 
                  "open": false, 
                  "procedures": "", 
                }
              }  
            ])
          break;
          case "remplissage":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "remplissage",
                formData: {
                  "primaryOrRevision": "", 
                  "openOrArthroscopic": "", 
                  "procedures": "", 
                }
              }  
            ])
          break;
          case "subscapularisAugmentation":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "subscapularisAugmentation",
                formData: {
                  "primaryOrRevision": "", 
                  "procedures": "", 
                  "repairDetails": "", 
                  "extrarotationDegree": "", 
                  "subscapularisElongation": "", 
                }
              }  
            ])
          break;
          case "glenoidBoneGraftProcedure":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "glenoidBoneGraftProcedure",
                formData: {
                  "primaryOrRevision": "", 
                  "openOrArthroscopic": "", 
                  "procedures": "", 
                  "typeOfBoneGraftUsed": "", 
                }
              }  
            ])
          break;
          case "humeralBoneGraftProcedure":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsLabrumAndInstability",
                form: "humeralBoneGraftProcedure",
                formData: {
                  "primaryOrRevision": "", 
                  "typeOfBoneGraftUsed": "", 
                }
              }  
            ])
          break;
          default: return;
        }
      break;
      case "implants":
        switch(form) {
          case "acJointStabilization":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "acJointStabilization",
                formData: {
                  "arthrexDevices": "",
                }
              }  
            ])
          break;
          case "biceps":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "biceps",
                formData: {
                  "arthrexDevices": "",
                  "swiveLockType": "",
                  "deviceMaterial": "",
                  "diameter": "",
                  "implantAlsoUsedForSubscapularisRepair": false,
                  "implantAlsoUsedForSupraspinatusRepair": false,
                }
              }  
            ])
          break;
          case "capsularRepairHaglAlpsaRemplissageAsa":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "capsularRepairHaglAlpsaRemplissageAsa",
                formData: {
                  "associatedProcedure": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
            ])
          break;
          case "chondral":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "chondral",
                formData: {
                  "location": "",
                  "type": "",
                }
              }  
            ])
          break;
          case "fractureManagement":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "fractureManagement",
                formData: {
                  "manufacturer": "",
                  "manufacturer2": "",
                  "manufacturer3": "",
                  "manufacturer4": "",
                }
              }  
            ])
          break;
          case "glenoidBoneGraftProcedure":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "glenoidBoneGraftProcedure",
                formData: {
                  "implantType": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "humeralBoneGraftProcedure":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "humeralBoneGraftProcedure",
                formData: {
                  "implantType": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "labralRepair":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "labralRepair",
                formData: {
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "sutureLoop": "",
                }
              }  
            ])
          break;
          case "osAcromiale":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "osAcromiale",
                formData: {
                  "device": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "osteotomy":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "osteotomy",
                formData: {
                  "device": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "pastaRepairKit":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "pastaRepairKit",
                formData: {
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "pecMajor":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "pecMajor",
                formData: {
                  "device": "",
                }
              }  
            ])
          break;
          case "rotatorCuffImplantsLateralRow":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "rotatorCuffImplantsLateralRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwMaterial": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
            ])
          break;
          case "rotatorCuffImplantsMedialRow":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "rotatorCuffImplantsMedialRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwMaterial": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
            ])
          break;
          case "cuffMend":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "cuffMend",
                formData: {
                  "knotlessAnchorType": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorType2": "",
                  "knotlessAnchorsUsed2": "",
                  "knotlessAnchorSuture": "",    
                }
              }  
            ])
          break;
          case "slap":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "slap",
                formData: {
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                }
              }  
            ])
          break;
          case "subscap":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "subscap",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                  "screwType": "",
                  "screwMaterial": "",
                  "screwDiameter": "",
                  "screwsUsed": "",
                }
              }  
            ])
          break;
          case "superiorCapsuleReconstructionGlenoid":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "superiorCapsuleReconstructionGlenoid",
                formData: {
                  "sutureTakMaterial": "",
                  "sutureAnchorsUsed": "",
                  "swiveLockMaterial": "",
                  "knotlessAnchorSuture": "",
                  "knotlessAnchorsUsed": "",
                  "swiveLockMaterial2": "",
                  "knotlessAnchorSuture2": "",
                  "knotlessAnchorsUsed2": "",
                  "swiveLockMaterial3": "",
                  "knotlessAnchorSuture3": "",
                  "knotlessAnchorsUsed3": "",
                  "pushlockMaterial": "",
                  "knotlessAnchorSuture4": "",
                  "knotlessAnchorsUsed4": "",
                  "corkscrewkMaterial": "",
                  "knotlessAnchorsUsed5": "",
                  "corkscrewkMaterial2": "",
                  "knotlessAnchorsUsed6": "",
                  "fiberTakDr": false,
                  "knotlessAnchorsUsed7": "",
                  "knotlessAnchorsUsed8": "",
                }
              }  
            ])
          break;
          case "superiorCapsuleReconstructionHumeralSideLateralRow":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "superiorCapsuleReconstructionHumeralSideLateralRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                }
              }  
            ])
          break;
          case "superiorCapsuleReconstructionHumeralSideMedialRow":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "superiorCapsuleReconstructionHumeralSideMedialRow",
                formData: {
                  "type": "",
                  "knotlessAnchorType": "",
                  "knotlessAnchorDiameter": "",
                  "knotlessAnchorMaterial": "",
                  "knotlessAnchorsUsed": "",
                  "knotlessAnchorSuture": "",
                  "sutureAnchorType": "",
                  "sutureAnchorDiameter": "",
                  "sutureAnchorMaterial": "",
                  "sutureAnchorsUsed": "",
                }
              }  
            ])
          break;
          case "elbowTendonProcedures":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowTendonProcedures",
                formData: {
                  "associatedProcedure": "",
                  "arthrexDevices": "",
                  "deviceMaterial": "",
                  "diameter": "",
                }
              }  
            ])
          break;
          case "elbowCollateralLigaments":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowCollateralLigaments",
                formData: {
                  "ligament": "",
                  "arthrexDevices": "",
                  "deviceMaterial": "",
                  "diameter": "",
                }
              }  
            ])
          break;
          case "elbowCartilageTreatment":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowCartilageTreatment",
                formData: {
                  "location": "",
                  "implantUsed": "",
                  "manufacturer": "",
                  "diameter": "",
                }
              }  
            ])
          break;
          case "elbowOsteochondralTreatment":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowOsteochondralTreatment",
                formData: {
                  "location": "",
                  "implantUsed": "",
                  "manufacturer": "",
                  "diameter": "",
                }
              }  
            ])
          break;
          case "elbowOrif":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowOrif",
                formData: {
                  "location": "",
                  "implantUsed": "",
                  "manufacturer": "",
                  "diameter": "",
                  "deviceMaterial": "",
                  "numberUsed": "",
                }
              }  
            ])
          break;
          case "elbowRadialHeadImplant":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowRadialHeadImplant",
                formData: {
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "elbowTotalElbowArthroplasty":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "implants",
                form: "elbowTotalElbowArthroplasty",
                formData: {
                  "manufacturer": "",
                }
              }  
            ])
          break;
          default: return
        }
      break;
      case "surgicalDressing":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            category: "surgicalDressing",
            form: "slingBraceApplication",
            formData: {
              "slingBrace": "",
            }
          }  
        ])
      break;
      case "postOperativeManagement":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            category: "postOperativeManagement",
            form: "postOpPath",
            formData: {
              "postOpPath": "",
              "inpatientStayDays": ""
            }
          }  
        ])
      break;
      case "grafts":
        switch(form) {
          case "boneGraftAutografts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "boneGraftAutografts",
                formData: {
                  "associatedProcedure": "",
                  "harvestedFrom": "",
                  "boneGraftType": "",
                  "volumeUsed": "",
                }
              }  
            ])
          break;
          case "boneGraftAllografts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "boneGraftAllografts",
                formData: {
                  "associatedProcedure": "",
                  "harvestedFrom": "",
                  "demineralizedBone": "",
                  "mineralizedBone": "",
                  "cellularDemineralizedBone": "",
                  "volumeUsed": "",
                  "numberUsed": "",
                  "width": "",
                  "length": "",
                  "height": "",
                  "manufacturer": "",
                  "processing": "",
                }
              }  
            ])
          break;
          case "boneGraftSyntheticBoneVoidFillers":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "boneGraftSyntheticBoneVoidFillers",
                formData: {
                  "associatedProcedure": "",
                  "boneGraftType": "",
                  "material": "",
                  "manufacturer": "",
                  "volumeUsed": "",
                }
              }  
            ])
          break;
          case "cartilageGraftChondralGrafts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "cartilageGraftChondralGrafts",
                formData: {
                  "location": "",
                  "autologousCartilageGrafts": "",
                  "alloglenicCartilageGrafts": "",
                  "orthobiologicDetails": "",
                  "manufacturer": "",
                  "volumeUsed": "",
                  "manufacturer2": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                  "syntheticCartilageGrafts": "",
                }
              }  
            ])
          break;
          case "cartilageGraftOsteochondralGrafts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "cartilageGraftOsteochondralGrafts",
                formData: {
                  "location": "",
                  "type": "",
                  "manufacturer": "",
                  "processing": "",
                  "donorSite": "",
                  "alloglenicCartilageGrafts": "",
                  "orthobiologicDetails": "",
                  "boneGraftType": "",
                  "angelSystemHematocritSetting": "",
                  "angelSystemInitialWholeBloodVolume": "",
                  "angelSystemPrpVolumeProduced": "",
                  "prpTypeOfActivation": "",
                  "boneMarrowAspirateConcentrate": "",
                }
              }  
            ])
          break;
          case "softTissueTendonGrafts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "softTissueTendonGrafts",
                formData: {
                  "associatedProcedure": "",
                  "boneGraftType": "",
                  "graftDetails": "",
                  "manufacturer": "",
                  "processing": "",
                  "sterilityAssuranceLevel": "",
                  "diameter": "",
                  "length": "",
                }
              }  
            ])
          break;
          case "softTissueAugmentationGrafts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "softTissueAugmentationGrafts",
                formData: {
                  "associatedProcedure": "",
                  "allograftDermis": "",
                  "synthetic": "",
                  "xenograft": "",
                  "manufacturer": "",
                  "thickness": "",
                  "length": "",
                  "width": "",
                  "sterilityAssuranceLevel": "",
                  "processing": "",
                }
              }  
            ])
          break;
          case "softTissueAdiposeTissueGraft":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "softTissueAdiposeTissueGraft",
                formData: {
                  "type": "",
                }
              }  
            ])
          break;
          case "softTissueMeniscalAllograftFasciaLata":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "softTissueMeniscalAllograftFasciaLata",
                formData: {
                  "type": "",
                  "size": "",
                  "manufacturer": "",
                  "processing": "",
                }
              }  
            ])
          break;
          case "elbowSoftTissueTendonGrafts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "elbowSoftTissueTendonGrafts",
                formData: {
                  "associatedProcedure": "",
                  "autograft": "",
                  "allograft": "",
                  "manufacturer": "",
                  "diameter": "",
                  "length": "",
                }
              }  
            ])
          break;
          case "boneGraftFreshAllografts":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "grafts",
                form: "boneGraftFreshAllografts",
                formData: {
                  "associatedProcedure": "",
                  "type": "",
                }
              }  
            ])
          break;
          default: return;
        }
      break;
      case "operationsAndImplants":
        switch(form) {
          case "approachSupscapRepairAndApplicationBraces":
            setCatalogEntries(prevState => [
                ...prevState,
                {
                  category: "operationsAndImplants",
                  form: "approachSupscapRepairAndApplicationBraces",
                  formData: {
                    "approach": "",
                    "subscapRepairMethod": "",
                    "applicationOrBrace": "",
                  }
                }  
            ])
          break;
          case "hemiarthroplasty":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "hemiarthroplasty",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "stemDetails": "",
                  "size": "",
                  "width": "",
                  "height": "",
                  "titaniumHead": false,
                  "cageScrew": "",
                  "trunionSize": "",
                  "boneGraft": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "humeralHeadResurfacing":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "humeralHeadResurfacing",
                formData: {
                  "primaryOrRevision": "",
                  "type": "",
                  "size": "",
                  "width": "",
                  "height": "",
                  "manufacturer": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "biologicTotalShoulder":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "biologicTotalShoulder",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "totalShoulderReplacement":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "totalShoulderReplacement",
                formData: {
                  "primaryOrRevision": "",
                  "stemDetails": "",
                  "size": "",
                  "width": "",
                  "height": "",
                  "titaniumHead": false,
                  "glenoid": "",
                  "glenoidSize": "",
                  "glenoidDeficiency": "",
                  "walchClassification": "",
                  "glenoidRetroversion": "",
                  "boneGraft": "",
                  "manufacturer": "",
                  "type": "",
                  "convertibleUniversalGlenoid": "",
                  "humeralOffset": "",
                  "cageScrew": "",
                  "trunionSize": "",
                  "planning3d": "",
                  "procedures": "",
                  "cerclage": "",
                }
              }  
            ])
          break;
          case "inverseReverseTotalShoulderReplacement":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "inverseReverseTotalShoulderReplacement",
                formData: {
                  "primaryOrRevision": "",
                  "size": "",
                  "titaniumHead": false,
                  "glenoidDeficiency": "",
                  "walchClassification": "",
                  "glenoidRetroversion": "",
                  "boneGraft": "",
                  "lateralOffset": "",
                  "eccentric": "",
                  "type": "",
                  "type2": "",
                  "spacerPolySize": "",
                  "metalSpacerTrays": "",
                  "type3": "",
                  "stemDetails": "",
                  "size2": "",
                  "humeralCup": "",
                  "humeralOfffset": "",
                  "humeralInclination": "",
                  "humeralVersion": "",
                  "humeralVersion2": "",
                  "manufacturer": "",
                  "latTransfer": "",
                  "planning3d": "",
                  "osAcromiale": "",
                  "cerclage": "",
                }
              }  
            ])
          break;
          case "superiorCapsuleReconstruction":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "superiorCapsuleReconstruction",
                formData: {
                  "primaryOrRevision": "",
                  "open": false,
                  "glenoidSideGraftFixation": "",
                  "tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon": false,
                  "humeralSideGraftFixation": "",
                  "graftUsed": "",
                  "armPositionForMeasuringPositioningAndSecuringGraft": "",
                  "posteriorMarginConvergencePerformed": "",
                  "anteriorMarginConvergencePerformed": "",
                  "procedures": "",
                  "glenoidSutureTak": "",
                  "numberOfSutureTaksUsed": "",
                  "glenoidSwiveLock": "",
                  "sutureUsedWithSwiveLock": "",
                  "numberOfSwiveLocksUsed": "",
                  "glenoidPushLock": "",
                  "sutureUsedWithPushLock": "",
                  "numberOfPushLocksUsed": "",
                  "typeOfKnotlessAnchor": "",
                  "materialKnotlessAnchor": "",
                  "sutureUsedWithKnotlessAnchor": "",
                  "numberOfKnotlessAnchorsUsed": "",
                  "typeOfSutureAnchor": "",
                  "materialSutureAnchor": "",
                  "numberOfSutureAnchorsUsed": "",
                  "typeOfKnotlessAnchor2": "",
                  "materialKnotlessAnchor2": "",
                  "accessorySutureUsed": "",
                  "numberOfKnotlessAnchorsUsed2": "",
                  "superiorLabrumManagement": "",
                }
              }  
            ])
          break;
          case "clavicleFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "clavicleFracture",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "greaterTuberosityFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "greaterTuberosityFracture",
                formData: {
                  "primaryOrRevision": "",
                  "boneGraft": "",
                  "manufacturer": "",
                  "procedures": "",
                }
              }  
            ])
          break;
          case "proximalHumerusFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "proximalHumerusFracture",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "boneGraft": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "humeralShaftFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "humeralShaftFracture",
                formData: {
                  "procedures": "",
                }
              }  
            ])
          break;
          case "glenoidFracture":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "glenoidFracture",
                formData: {
                  "primaryOrRevision": "",
                  "procedures": "",
                  "boneGraft": "",
                  "manufacturer": "",
                }
              }  
            ])
          break;
          case "fusion":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "fusion",
                formData: {
                  "primaryOrRevision": "",
                  "openOrArthroscopic": "",
                  "boneGraft": "",
                  "type": "",
                }
              }  
            ])
          break;
          case "prostalac":
            setCatalogEntries(prevState => [
              ...prevState,
              {
                category: "operationsAndImplants",
                form: "prostalac",
                formData: {
                }
              }  
            ])
          break;
          default: return
        }
      break;
      default: return
    }
  }

  const handleCatalogEntryFormDataUpdate = (arrayIndex, key, value) => {
    let newCatalogEntries = [...catalogEntries]
    newCatalogEntries[arrayIndex].formData[key] = value
    setCatalogEntries(newCatalogEntries)
  }

  const handleCatalogEntryRemoval = (arrayIndex) => {
    const catalogEntryId = catalogEntries[arrayIndex].id
    if((catalogEntryId && !deletedEntries.some(deletedEntry => deletedEntry.id === catalogEntryId)) || ["patientInformationSurgicalTimeAndPersonnel", "handDominance"].includes(catalogEntries[arrayIndex].category)) {
      setDeletedEntries(prevDeletedEntries => [
        ...prevDeletedEntries,
        {
          category: catalogEntries[arrayIndex].category,
          form: catalogEntries[arrayIndex].form,
          eventId: surgeryEvent.id,
          id: catalogEntryId
        }
      ]);
    }
    setCatalogEntries(catalogEntries.filter((catalogEntry, index) => index !== arrayIndex))
  }

  const handleEventUpdateSubmit = async () => {
    try {
      const body = {
        eventId: eventId,
        catalogEntries: catalogEntries,
        deletedEntries: deletedEntries
      }
      setDisableButton(true)
      await axios.post(`${BASE_URL}/api/v1/event/${eventId}/surgery-metadata`, body, config)
      setEventUpdated(!eventUpdated)
      toast.success('Event updated', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setDisableButton(false)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }
  
  const renderCatalog = () => {
    switch(surgeryEvent.study) {
      case "shoulder arthroscopy": {
        return (
          <>
            <Accordion.Item eventKey="3" className="diagnosis">
              <Accordion.Header>Diagnosis</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("diagnosis", "rotatorCuff")}>Rotator Cuff</button>
                <button onClick={() => addCatalogEntry("diagnosis", "subacromialImpingement")}>Subacromial Impingement</button>
                <button onClick={() => addCatalogEntry("diagnosis", "labralInjuryAndInstability")}>Labral injury and instability</button>
                <button onClick={() => addCatalogEntry("diagnosis", "biceps")}>Biceps</button>
                <button onClick={() => addCatalogEntry("diagnosis", "acJoint")}>AC joint</button>
                <button onClick={() => addCatalogEntry("diagnosis", "osAcromiale")}>Os acriomiale</button>
                <button onClick={() => addCatalogEntry("diagnosis", "glenohumeralArthritis")}>Glenohumeral arthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "articularCartilageOsteochondralLesion")}>Articular cartilage/osteochondral lesion</button>
                <button onClick={() => addCatalogEntry("diagnosis", "otherIntraArticular")}>Other intra-articular</button>
                <button onClick={() => addCatalogEntry("diagnosis", "fracture")}>Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "miscellaneous")}>Miscellaneous</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="operations">
              <Accordion.Header>Operations</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operations", "rotatorCuffCompleteTear")}>Rotator cuff (complete tear)</button>
                <button onClick={() => addCatalogEntry("operations", "rotatorCuffPartialTear")}>Rotator cuff (partial tear)</button>
                <button onClick={() => addCatalogEntry("operations", "superiorCapsuleReconstruction")}>Superior Capsule Reconstruction (SCR)</button>
                <button onClick={() => addCatalogEntry("operations", "subscap")}>Subscap</button>
                <button onClick={() => addCatalogEntry("operations", "subAcromialDecompression")}>Subacromial decompression</button>
                <button onClick={() => addCatalogEntry("operations", "tendonTransfer")}>Tendon transfer</button>
                <button onClick={() => addCatalogEntry("operations", "biceps")}>Biceps</button>
                <button onClick={() => addCatalogEntry("operations", "coracoplasty")}>Coracoplasty</button>
                <button onClick={() => addCatalogEntry("operations", "distalClavicleExcision")}>Distal clavicle excision</button>
                <button onClick={() => addCatalogEntry("operations", "acJointStabilization")}>AC joint stabilization</button>
                <button onClick={() => addCatalogEntry("operations", "osAcromiale")}>Os acromiale</button>
                <button onClick={() => addCatalogEntry("operations", "osteotomy")}>Osteotomy</button>
                <button onClick={() => addCatalogEntry("operations", "cartilageTreatment")}>Cartilage treatment</button>
                <button onClick={() => addCatalogEntry("operations", "osteochondralTreatment")}>Osteochondral treatment</button>
                <button onClick={() => addCatalogEntry("operations", "otherIntraArticular")}>Other intra-articular</button>
                <button onClick={() => addCatalogEntry("operations", "fractureManagement")}>Fracture management (ORIF)</button>
                <button onClick={() => addCatalogEntry("operations", "miscellaneous")}>Miscellaneous</button>
              </Accordion.Body>

            </Accordion.Item>
            <Accordion.Item eventKey="5" className="operations-labrum-and-instability">
              <Accordion.Header>Operations - labrum & instability</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "slap")}>SLAP</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "labralRepair")}>Labral repair</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "capsulorrhaphy")}>Capsulorrhaphy</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "haglRepair")}>HAGL repair</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "alpsaRepair")}>ALPSA repair</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "remplissage")}>Remplissage</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "subscapularisAugmentation")}>Subscapularis augmentation</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "glenoidBoneGraftProcedure")}>Glenoid bone graft procedure</button>
                <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "humeralBoneGraftProcedure")}>Humeral bone graft procedure</button>
                {/* <button onClick={() => addCatalogEntry("operationsLabrumAndInstability", "dynamicAnteriorStabilization")}>Dynamic Anterior Stabilization (DAS)</button> */}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="grafts">
              <Accordion.Header>Grafts</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAutografts")}>Bone graft - autografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAllografts")}>Bone graft - allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftSyntheticBoneVoidFillers")}>Bone graft - synthetic bone void fillers</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftChondralGrafts")}>Cartilage graft - chondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftOsteochondralGrafts")}>Cartilage graft - osteochondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueTendonGrafts")}>Soft tissue - tendon grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAugmentationGrafts")}>Soft tissue - augmentation grafts</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="implants">
              <Accordion.Header>Implants</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("implants", "acJointStabilization")}>AC joint stabilization</button>
                <button onClick={() => addCatalogEntry("implants", "biceps")}>Biceps</button>
                <button onClick={() => addCatalogEntry("implants", "capsularRepairHaglAlpsaRemplissageAsa")}>Capsular repair HAGL/ALPSA/remplissage/ASA</button>
                <button onClick={() => addCatalogEntry("implants", "chondral")}>Chondral</button>
                <button onClick={() => addCatalogEntry("implants", "fractureManagement")}>Fracture management (ORIF)</button>
                <button onClick={() => addCatalogEntry("implants", "glenoidBoneGraftProcedure")}>Glenoid bone graft procedure</button>
                <button onClick={() => addCatalogEntry("implants", "labralRepair")}>Labral repair</button>
                <button onClick={() => addCatalogEntry("implants", "osAcromiale")}>Os acromiale</button>
                <button onClick={() => addCatalogEntry("implants", "osteotomy")}>Osteotomy</button>
                <button onClick={() => addCatalogEntry("implants", "pastaRepairKit")}>Pasta repair kit</button>
                <button onClick={() => addCatalogEntry("implants", "pecMajor")}>Pec major</button>
                <button onClick={() => addCatalogEntry("implants", "rotatorCuffImplantsLateralRow")}>Rotator cuff implants - lateral row (lateral half of greater tuberosity)</button>
                <button onClick={() => addCatalogEntry("implants", "rotatorCuffImplantsMedialRow")}>Rotator cuff implants - medial row (medial half of greater tuberosity)</button>
                <button onClick={() => addCatalogEntry("implants", "cuffMend")}>Cuff Mend</button>
                <button onClick={() => addCatalogEntry("implants", "slap")}>SLAP</button>
                <button onClick={() => addCatalogEntry("implants", "subscap")}>Subscap</button>
                <button onClick={() => addCatalogEntry("implants", "superiorCapsuleReconstructionGlenoid")}>Superior Capsule Reconstruction (SCR) - glenoid</button>
                <button onClick={() => addCatalogEntry("implants", "superiorCapsuleReconstructionHumeralSideLateralRow")}>Superior Capsule Reconstruction (SCR) - humeral side lateral Row</button>
                <button onClick={() => addCatalogEntry("implants", "superiorCapsuleReconstructionHumeralSideMedialRow")}>Superior Capsule Reconstruction (SCR) - humeral side medial Row</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className="surgicalDressing">
              <Accordion.Header>Surgical Dressing</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("surgicalDressing")}>Sling/Brace application</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      }
      case "shoulder arthroplasty": {
        return (
          <>
            <Accordion.Item eventKey="3" className="diagnosis">
              <Accordion.Header>Diagnosis</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("diagnosis", "arthritis")}>Arthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "rotatorCuffArthropathy")}>Rotator Cuff Arthropathy</button>
                <button onClick={() => addCatalogEntry("diagnosis", "failedRotatorCuffRepair")}>Failed Rotator Cuff Repair</button>
                <button onClick={() => addCatalogEntry("diagnosis", "failedShoulderArthroplasty")}>Failed Shoulder Arthroplasty</button>
                <button onClick={() => addCatalogEntry("diagnosis", "clavicleFracture")}>Clavicle Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "humeralFracture")}>Humeral Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "scapulaFracture")}>Scapula Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "chronicRotatorCuffTear")}>Chronic Rotator Cuff Tear</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="operations">
              <Accordion.Header>Operations/Implants</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "approachSupscapRepairAndApplicationBraces")}>Approach, Supscap Repair and Application/Braces</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "hemiarthroplasty")}>Hemiarthroplasty</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "humeralHeadResurfacing")}>Humeral Head Resurfacing</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "biologicTotalShoulder")}>Biologic Total Shoulder</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "totalShoulderReplacement")}>Total Shoulder Replacement</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "inverseReverseTotalShoulderReplacement")}>Inverse/Reverse Total Shoulder Replacement</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "superiorCapsuleReconstruction")}>Superior Capsule Reconstruction (SCR)</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "clavicleFracture")}>Clavicle Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "greaterTuberosityFracture")}>Greater Tuberosity Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "proximalHumerusFracture")}>Proximal Humerus Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "humeralShaftFracture")}>Humeral Shaft Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "glenoidFracture")}>Glenoid Fracture</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "fusion")}>Fusion</button>
                <button onClick={() => addCatalogEntry("operationsAndImplants", "prostalac")}>Prostalac</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="grafts">
              <Accordion.Header>Grafts</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAutografts")}>Bone graft - autografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAllografts")}>Bone graft - allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftSyntheticBoneVoidFillers")}>Bone graft - synthetic bone void fillers</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftChondralGrafts")}>Cartilage graft - chondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftOsteochondralGrafts")}>Cartilage graft - osteochondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueMeniscalAllograftFasciaLata")}>Soft Tissue - Meniscal Allograft, Fascia Lata, etc</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAugmentationGrafts")}>Soft tissue - augmentation grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAdiposeTissueGraft")}>Soft Tissue - Adipose Tissue Graft (Autograft)</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="postOperativeManagement">
              <Accordion.Header>Post Operative Management</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("postOperativeManagement")}>Post-Op Path</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      }
      case "elbow": {
        return (
          <>
            <Accordion.Item eventKey="3" className="diagnosis">
              <Accordion.Header>Diagnosis</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("diagnosis", "tendon")}>Tendon</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowLigamentInstability")}>Ligament/Instability</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOsteochondralLesion")}>Osteochondral Lesion</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOsteoarthritis")}>Osteoarthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOtherInflammatoryArthritis")}>Other Inflammatory Arthritis</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowLooseBody")}>Loose Body(s)</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowCapsuleSynovium")}>Capsule/Synovium</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowNerve")}>Nerve</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowFracture")}>Fracture</button>
                <button onClick={() => addCatalogEntry("diagnosis", "olecranonSpur")}>Olecranon Spur</button>
                <button onClick={() => addCatalogEntry("diagnosis", "elbowOther")}>Other</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="operations">
              <Accordion.Header>Operations</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("operations", "elbowCaseDetails")}>Case Details</button>
                <button onClick={() => addCatalogEntry("operations", "elbowTendonProcedures")}>Tendon Procedures</button>
                <button onClick={() => addCatalogEntry("operations", "elbowCollateralLigaments")}>Collateral Ligaments</button>
                <button onClick={() => addCatalogEntry("operations", "elbowCartilageTreatment")}>Cartilage Treatment</button>
                <button onClick={() => addCatalogEntry("operations", "elbowOsteochondralTreatment")}>Osteochondral Treatment</button>
                <button onClick={() => addCatalogEntry("operations", "elbowOsteophyteDebridementExcision")}>Osteophyte Debridement/Excision</button>
                <button onClick={() => addCatalogEntry("operations", "elbowRemovalLooseBody")}>Removal of Loose Body(s)</button>
                <button onClick={() => addCatalogEntry("operations", "elbowCapsuleSynovium")}>Capsule/Synovium</button>
                <button onClick={() => addCatalogEntry("operations", "elbowNerve")}>Nerve</button>
                <button onClick={() => addCatalogEntry("operations", "elobwOrif")}>ORIF</button>
                <button onClick={() => addCatalogEntry("operations", "radialHeadExcision")}>Radial Head Excision</button>
                <button onClick={() => addCatalogEntry("operations", "radialHeadImplant")}>Radial Head Implant</button>
                <button onClick={() => addCatalogEntry("operations", "totalElbowArthroplasty")}>Total Elbow Arthroplasty</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="grafts">
              <Accordion.Header>Grafts</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAutografts")}>Bone graft - Autografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftAllografts")}>Bone graft - allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftFreshAllografts")}>Bone graft - fresh allografts</button>
                <button onClick={() => addCatalogEntry("grafts", "boneGraftSyntheticBoneVoidFillers")}>Bone graft - synthetic bone void fillers</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftChondralGrafts")}>Cartilage graft - chondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "cartilageGraftOsteochondralGrafts")}>Cartilage graft - osteochondral grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "elbowSoftTissueTendonGrafts")}>Soft tissue - tendon grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAugmentationGrafts")}>Soft tissue - augmentation grafts</button>
                <button onClick={() => addCatalogEntry("grafts", "softTissueAdiposeTissueGraft")}>Soft tissue - adipose tissue graft (autograft)</button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="implants">
              <Accordion.Header>Implants</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("implants", "elbowTendonProcedures")}>Tendon Procedures</button>
                <button onClick={() => addCatalogEntry("implants", "elbowCollateralLigaments")}>Collateral Ligaments</button>
                <button onClick={() => addCatalogEntry("implants", "elbowCartilageTreatment")}>Cartilage Treatment</button>
                <button onClick={() => addCatalogEntry("implants", "elbowOsteochondralTreatment")}>Osteochondral Treatment</button>
                <button onClick={() => addCatalogEntry("implants", "elbowOrif")}>ORIF</button>
                <button onClick={() => addCatalogEntry("implants", "elbowRadialHeadImplant")}>Radial Head Implant</button>
                <button onClick={() => addCatalogEntry("implants", "elbowTotalElbowArthroplasty")}>Total Elbow Arthroplasty</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      }
      default: return
    }
  }
  return (
    <div className="surgery-details">
      { !surgeryEvent ? (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <>
          <div className="surgery-details-header">
            <div className="surgery-title">
              <div className='sugery-info'>
                <h2><FaUserDoctor /> Surgery</h2>
                <div className="patient-and-date">
                  <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/patients" }}>Patients</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/patients/${customPatientId}` }}>{customPatientId}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Surgery {studyAbbreviationMap[surgeryEvent.study]} ({surgeryEvent.treatment_side.charAt(0)}) - {moment(surgeryEvent.event_date).format("DD/MM/YYYY")}</Breadcrumb.Item>
                  </Breadcrumb> 
                </div>
              </div>
              <div className='buttons'>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Button onClick={() => navigate(`/patients/${customPatientId}/events/surgery/${eventId}/edit`)}>View/Edit</Button>
              </div>
            </div>
          </div>           
            <div className="surgery-metadata">
              <div className="surgery-metadata-overview">
                <h4>Catalog</h4>
                <Accordion>
                  <Accordion.Item eventKey="0" className="patient-information-and-surgical-time-and-personnel">
                    <Accordion.Header>Patient information and surgical time/personnel</Accordion.Header>
                    <Accordion.Body>
                      <button onClick={() => addCatalogEntry("patientInformationSurgicalTimeAndPersonnel", "patientInformation")}>Patient information</button>
                      <button onClick={() => addCatalogEntry("patientInformationSurgicalTimeAndPersonnel", "surgicalTimeAndPersonnel")}>Surgical time and personnel</button>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="hand-dominance">
                    <Accordion.Header>Hand dominance</Accordion.Header>
                    <Accordion.Body>
                      <button onClick={() => addCatalogEntry("handDominance")}>Hand dominance</button>
                    </Accordion.Body>
                  </Accordion.Item>
                  {
                    renderCatalog()
                  }
                </Accordion>
              </div>
              <div className="surgery-metadata-details">
                <div className="surgery-metadata-details-header">
                  <h4>Forms</h4>
                  <Button disabled={disableButton} onClick={() => handleEventUpdateSubmit()}>Update Event</Button>
                </div>
                {catalogEntries && catalogEntries.map((catalogEntry, index) => {
                  switch(catalogEntry.category) {
                    case "patientInformationSurgicalTimeAndPersonnel":
                      return <PatientInformationSurgicalTimeAndPersonnel key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    case "handDominance":
                      return <HandDominance key={index} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval} study={surgeryEvent.study}/>
                    case "diagnosis":
                      return <Diagnosis key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    case "operations":
                      return <Operations key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    case "operationsLabrumAndInstability":
                      return <OperationsLabrumAndInstability key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    case "implants":
                      return <Implants key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    case "surgicalDressing":
                      return <SurgicalDressing key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    case "postOperativeManagement":
                      return <PostOperativeManagement key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval} onSubmitHandler={handleEventUpdateSubmit}/>
                    case "grafts":
                      return <Grafts key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval} study={surgeryEvent.study}/>
                    case "operationsAndImplants":
                      return <OperationsAndImplants key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval}/>
                    default: return null
                  }
                })}
              </div>
            </div>
        </>
      )}
    </div>
  )
}

export default SurgeryDetails