import React from "react";
import { Form } from "react-bootstrap";

import './StandardLatePostOp.scss';

const StandardLatePostOp = ({ handleFormData, values }) => {
  return (
    <div className="self-assessment-test standard-late-postop">
      <p className="form-title">Standaard Laat Post-operatief Formulier</p>
      <Form.Group className="form-group">
        <Form.Label>1. Hoe goed voldeed de operatie/behandeling aan uw verwachtingen met betrekking tot afname van de pijn?</Form.Label>
        <Form.Select required value={values.slpostop.slpostopQuestion1} onChange={(event) => handleFormData("slpostop", "slpostopQuestion1", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voldeed niet aan verwachtingen">Voldeed niet aan verwachtingen</option>
          <option value="Voldeed aan verwachtingen">Voldeed aan verwachtingen</option>
          <option value="Overtrof verwachtingen">Overtrof verwachtingen</option>
        </Form.Select>
      </Form.Group>    

      <Form.Group className="form-group">
        <Form.Label>2. Hoe goed voldeed de operatie/behandeling aan uw verwachtingen met betrekking tot verbetering van beweging en kracht van het aangedane gewricht?</Form.Label>
        <Form.Select required value={values.slpostop.slpostopQuestion2} onChange={(event) => handleFormData("slpostop", "slpostopQuestion2", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voldeed niet aan verwachtingen">Voldeed niet aan verwachtingen</option>
          <option value="Voldeed aan verwachtingen">Voldeed aan verwachtingen</option>
          <option value="Overtrof verwachtingen">Overtrof verwachtingen</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>3. Hoe goed voldeed de operatie/behandeling aan uw verwachtingen met betrekking tot het opnieuw oppakken van uw normale functioneren in het dagelijks leven?</Form.Label>
        <Form.Select required value={values.slpostop.slpostopQuestion3} onChange={(event) => handleFormData("slpostop", "slpostopQuestion3", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voldeed niet aan verwachtingen">Voldeed niet aan verwachtingen</option>
          <option value="Voldeed aan verwachtingen">Voldeed aan verwachtingen</option>
          <option value="Overtrof verwachtingen">Overtrof verwachtingen</option>
          </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>4. Hoe goed voldeed de operatie/behandeling aan uw verwachtingen met betrekking tot het oppakken van normale sportieve activiteiten?</Form.Label>
        <Form.Select required value={values.slpostop.slpostopQuestion4} onChange={(event) => handleFormData("slpostop", "slpostopQuestion4", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voldeed niet aan verwachtingen">Voldeed niet aan verwachtingen</option>
          <option value="Voldeed aan verwachtingen">Voldeed aan verwachtingen</option>
          <option value="Overtrof verwachtingen">Overtrof verwachtingen</option>
          <option value="Niet van toepassing - Ik doe niet aan sport">Niet van toepassing - Ik doe niet aan sport</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}

export default StandardLatePostOp