import React from 'react'
import { Button, Card } from "react-bootstrap";

import "./TermsAndConditions.scss"

const TermsAndConditions = ({ handleTermsAndConditionsAccept, handleTermsAndConditionsReject }) => {
  return (
    <div className="terms-and-conditions">
      <Card.Body>
          <Card.Title>
            Algemene voorwaarden
          </Card.Title>
          <div className='content'>
            <p>
              Orthopaedic Treatment Outcomes System (OTOS)
            </p>   
            <p>
              Verklaring van toestemming  voor gebruik van persoonlijke gegevens in OTOS.
            </p>   
            <p>
              Aanbieder van het systeem is Dr. Dirk Petré, Orthopedie BV, Harelbekstraat 100, 8540 Deerlijk.
            </p>
            <p>
              Mijn behandelende arts is Dr. Dirk Petré, Sint Jozefsklineik, Ommegangstraat 7, 8870 Izegem.
            </p>
            <p>
              OTOS is een internet gebaseerd registratiesysteem voor het controleren van behandelingsresultaten op het gebied van orthopedie en meer specifiek voor ingrepen ter hoogte van de schouder en elleboog. Door middel van OTOS kan mijn behandelende arts de gezondheidstoestand van mijn schouder en/of elleboog controleren waardoor zijn medische kennis over deze gewrichten kan worden verbeterd.
            </p>
            <p>
              Mijn behandelende arts is de enige verantwoordelijke persoon voor het verzamelen, verwerken en gebruik van de gegevens. Het  verzamelen, verwerken en gebruik van de gegevens gebeurt met een privacyniveau dat adequaat is vanuit het oogpunt van Europees recht.
            </p>
            <p>
              Enkel mijn behandelende arts en zijn directe medewerkers hebben inzage in deze gegevens.
            </p>
            <p>
              Als patiënt verklaar ik mondeling en schriftelijk  te zijn ingelicht  over de relevantie van deelname aan OTOS . Ik heb genoeg tijd gehad om de bovenstaande patiënteninformatie te lezen, het te begrijpen en te beslissen of Ik deze toestemming wil verlenen. Ik heb genoeg mogelijkheden gehad om antwoorden te krijgen op mijn vragen door middel van een gesprek met mijn behandelend arts of een van zijn directe medewerkers.
            </p>
            <p>
              Als patiënt ben ik me bewust van het feit dat ik te allen tijde mijn toestemming mag intrekken, zonder opgave van reden en zonder dat daar kosten aan verbonden zijn, door het versturen van een e-mail naar dirk.petre@sjki.be.
            </p>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button className="btn-secondary" onClick={handleTermsAndConditionsReject}>Weigeren</Button>
          <Button className="btn-success" onClick={handleTermsAndConditionsAccept}>Accepteren</Button>
      </Card.Footer>
    </div>
  )
}

export default TermsAndConditions