import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { FaBell, FaBoxArchive } from "react-icons/fa6";
import { MdExitToApp } from "react-icons/md";
import { Table, Form, Button } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import Modal from 'react-bootstrap/Modal'
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import _ from "underscore"
import { AiFillWarning, AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";


import "./PatientCompliance.scss"

const studyMap = {
  "shoulder arthroscopy": "SAs",
  "shoulder arthroplasty": "SAp",
  "elbow": "E"
}

const PatientCompliance = ({ user, logUserOut }) => {
  const [loading, setLoading] = useState(false)
  const [nonCompliantPatients, setNonCompliantPatients] = useState([])
  const [sorting, setSorting] = useState({ field: 'alertDate', ascending: true })
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getNonCompliantPatients = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${BASE_URL}/api/v1/patient/non-compliant-patients/`, config)
        const nonCompliantPatients = response.data.nonCompliantPatients
        setNonCompliantPatients(nonCompliantPatients)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getNonCompliantPatients()
  }, [])

  useEffect(() => {
    setNonCompliantPatients(sorting.ascending? _.sortBy(nonCompliantPatients, sorting.field) : _.sortBy(nonCompliantPatients, sorting.field).reverse())
  }, [sorting]);

  return (
    <div className="patient-compliance">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <div className="patient-compliance-header">
            <h2 className="logo"><AiFillWarning /> Non-compliant Patients</h2> 
          </div>
          <div className='patient-compliance-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='custom-id' onClick={() => setSorting({field: "customPatientId", ascending: !sorting.ascending})}>Patient ID {sorting.field === "customPatientId"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='study'>Study</th>
                  <th className='event-date'>Event Date</th>
                  <th className='milestone'>Milestone</th>
                  <th className='date-of-submission'>Date of Submission</th>
                  <th className='survey-deadline'>Survey Deadline</th>
                  <th className='actions'>Actions</th>
                  <th className='action-buttons'></th>
                </tr>
              </thead>
              <tbody>
                {nonCompliantPatients.length > 0 ? nonCompliantPatients.map((surveyOutlier, index) => {
                  return (
                    <tr key={index}>
                      <td>{surveyOutlier.customPatientId}</td>
                      <td>{studyMap[surveyOutlier.study]} ({surveyOutlier.treatmentSide[0]})</td>
                      <td>{moment(surveyOutlier.eventDate).format("DD/MM/YYYY")}</td>
                      <td>{surveyOutlier.stage}</td>
                      <td>{surveyOutlier.dateOfSubmission? moment(surveyOutlier.dateOfSubmission).format("DD/MM/YYYY") : "n/a"}</td>
                      <td>{moment(surveyOutlier.surveyDeadline).format("DD/MM/YYYY")}</td>
                      <td>
                        <Link to={`/patient/${surveyOutlier.customPatientId}/event/${surveyOutlier.eventId}/${surveyOutlier.study.replace(" ", "-")}/${surveyOutlier.treatmentSide.toLowerCase()}/survey/${surveyOutlier.stage.replace(" ", "-")}/${surveyOutlier.dateOfSubmission? "edit": "new"}`}>View Survey</Link></td>
                      <td className='action-buttons'>
                        <div>
                          <Button variant="primary" onClick={() => navigate(`/patients/${surveyOutlier.customPatientId}`)}><MdExitToApp /></Button>
                        </div>
                      </td>
                    </tr>
                  )
                }) : <tr><td colSpan={100} style={{textAlign: "center"}}>100% compliance rate</td></tr>}
              </tbody>
            </Table>
          </div>
        </>)}    
    </div>
  )
}

export default PatientCompliance