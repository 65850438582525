import React from "react";
import { Form } from "react-bootstrap";

import './VAS.scss';

const VAS = ({handleFormData, values }) => {
  return (
    <div className="self-assessment-test vas">
      <p className="form-title">Visuele Analoge Pijnschaal</p>   
      <Form.Group className="form-group">
        <Form.Label>1. Hoe erg is uw pijn vandaag?</Form.Label>
        <Form.Text>Van 0 (helemaal geen pijn) tot 10 (ergst denkbare pijn)</Form.Text>
        <div className="pain-slider">
          <Form.Range
            required
            value={values.vasScore}
            min="0" max="10" step="0.01"
            onChange={(event) => handleFormData("vas", "vasScore", event.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Gelieve een keuze te maken
          </Form.Control.Feedback>
          <Form.Control required type="number" min="0" max="10" step="0.01" value={values.vasScore} onChange={(event) => handleFormData("vas", "vasScore", event.target.value.trim())}/>
        </div>
      </Form.Group>
    </div>
  );
}

export default VAS