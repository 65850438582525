import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RxCross1 } from "react-icons/rx";

import "./HandDominance.scss"

const HandDominance = ({ formData, arrayIndex, onChangeHandler, onRemoveHandler, study }) => {
  return (
    <div className="hand-dominance">
      <Accordion>
        <Accordion.Item eventKey="0">
          <div className='accordion-custom-header'>
            <div className='catalog-entry-header'>
              <Accordion.Header>
                Hand Dominance
              </Accordion.Header>
              <div className='catalog-entry-preview'>
                {
                  Object.entries(formData).map(([key, value], index) => {
                    return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                  })
                }
              </div>
            </div>
            <div className='remove-catalog-entry-button-wrapper'>
                <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
            </div>
          </div>
          <Accordion.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Label>Is the injured {study === "elbow"? "elbow" : "shoulder"} on the dominant hand side?</Form.Label>
                  <Form.Select
                    value={formData.handDominance}
                    onChange={(e) => {onChangeHandler(arrayIndex, "handDominance", e.target.value)}}
                  >
                    <option value="" hidden>Select an option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default HandDominance