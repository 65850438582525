import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select';
import { RxCross1 } from "react-icons/rx";

import "./OperationsLabrumAndInstability.scss"

const slapProcedureDetailOptions = [
  {value: "Debridement", label: "Debridement"},
  {value: "Repair", label: "Repair"},
]

const slapRepairDetailOptions = [
  {value: "Through muscle", label: "Through muscle"},
  {value: "Through interval only", label: "Through interval only"},
]

const labralRepairProcedureDetailOptions = [
  {value: "Debridement", label: "Debridement"},
  {value: "Repair", label: "Repair"},
]

const labralRepairRepairDetailOptions = [
  {value: "Knotless anchor - Inferior anchor", label: "Knotless anchor - Inferior anchor"},
  {value: "Knotless anchor - Other", label: "Knotless anchor - Other"},
  {value: "Suture Anchor - Inferior Anchor", label: "Suture Anchor - Inferior Anchor"},
  {value: "Suture anchor - Other", label: "Suture anchor - Other"},
  {value: "Combination knotless and suture anchor", label: "Combination knotless and suture anchor"},
]

const capsulorrhaphyProcedureDetailOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Inferior", label: "Inferior"},
  {value: "Superior", label: "Superior"},
]

const haglRepairTypeOptions = [
  {value: "Soft Tissue", label: "Soft Tissue"},
  {value: "Anchor", label: "Anchor"},
]

const alpsaRepairProcedureDetailOptions = [
  {value: "Soft Tissue", label: "Soft Tissue"},
  {value: "Anchor", label: "Anchor"},
]

const remplissageProcedureDetailOptions = [
  {value: "Anterior", label: "Anterior"},
  {value: "Posterior", label: "Posterior"},
  {value: "Other", label: "Other"},
]

const sugscapularisAugmentationProcedureDetailOptions = [
  {value: "Subscapularis elongation", label: "Subscapularis elongation"},
  {value: "Extrarotation degree", label: "Extrarotation degree"},
]

const sugscapularisAugmentationRepairDetailOptions = [
  {value: "Knotless anchor - Inferior anchor", label: "Knotless anchor - Inferior anchor"},
  {value: "Knotless anchor - Other", label: "Knotless anchor - Other"},
  {value: "Suture Anchor - Inferior Anchor", label: "Suture Anchor - Inferior Anchor"},
  {value: "Suture anchor - Other", label: "Suture anchor - Other"},
  {value: "Combination knotless and suture anchor", label: "Combination knotless and suture anchor"},
  {value: "Suture only", label: "Suture only"},
]

const OperationsLabrumAndInstability = ({ form, formData, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "slap": {
      return (
        <div className="operations-l-and-i-slap">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    SLAP
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={slapProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={slapProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Repair Details</Form.Label>
                      <Select
                        isMulti
                        value={slapRepairDetailOptions.filter(option => formData.repairDetails.split(",").includes(option.value))}
                        name="repairDetails"
                        options={slapRepairDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "repairDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type of Anchor Used</Form.Label>
                      <Form.Select
                        value={formData.typeOfAnchorUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "typeOfAnchorUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Knotless anchor">Knotless anchor</option>
                        <option value="Suture Anchor">Suture Anchor</option>
                        <option value="Combination knotless and suture anchor">Combination knotless and suture anchor</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "labralRepair": {
      return (
        <div className="operations-l-and-i-labral-repair">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Labral Repair
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={labralRepairProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={labralRepairProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Repair Details</Form.Label>
                      <Select
                        isMulti
                        value={labralRepairRepairDetailOptions.filter(option => formData.repairDetails.split(",").includes(option.value))}
                        name="repairDetails"
                        options={labralRepairRepairDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "repairDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "capsulorrhaphy": {
      return (
        <div className="operations-l-and-i-capsulorrhaphy">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Capsulorrhaphy
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={capsulorrhaphyProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={capsulorrhaphyProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.withAnchors}
                        label="With anchors" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "withAnchors", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "haglRepair": {
      return (
        <div className="operations-l-and-i-hagl-repair">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    HAGL Repair
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={haglRepairTypeOptions.filter(option => formData.haglRepairType.split(",").includes(option.value))}
                        name="procedures"
                        options={haglRepairTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "haglRepairType", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "alpsaRepair": {
      return (
        <div className="operations-l-and-i-alpsa-repair">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    ALPSA Repair
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={alpsaRepairProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={alpsaRepairProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "remplissage": {
      return (
        <div className="operations-l-and-i-remplissage">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Remplissage
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={remplissageProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={remplissageProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "subscapularisAugmentation": {
      return (
        <div className="operations-l-and-i-subscapularis-augmentation">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Subscapularis Augmentation
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={sugscapularisAugmentationProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={sugscapularisAugmentationProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Repair Details</Form.Label>
                      <Select
                        isMulti
                        value={sugscapularisAugmentationRepairDetailOptions.filter(option => formData.repairDetails.split(",").includes(option.value))}
                        name="repairDetails"
                        options={sugscapularisAugmentationRepairDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "repairDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Extrarotation degree</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={-360}
                        max={360}
                        value={formData.extrarotationDegree}
                        onChange={(e) => {onChangeHandler(arrayIndex, "extrarotationDegree", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Subscapularis elongation</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.subscapularisElongation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "subscapularisElongation", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "glenoidBoneGraftProcedure": {
      return (
        <div className="operations-l-and-i-glenoid-bone-graft-procedure">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Glenoid Bone Graft Procedure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Form.Select
                        value={formData.procedures}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedures", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Latarjet">Latarjet</option>
                        <option value="Other glenoid bone graft procedure">Other glenoid bone graft procedure</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type of bone graft used</Form.Label>
                      <Form.Select
                        value={formData.typeOfBoneGraftUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "typeOfBoneGraftUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Allograft">Allograft (Fill in details in graft catalog)</option>
                        <option value="Autograft">Autograft (Fill in details in graft catalog)</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler (Fill in details in graft catalog)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "humeralBoneGraftProcedure": {
      return (
        <div className="operations-l-and-i-humeral-bone-graft-procedure">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Humeral Bone Graft Procedure
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type of bone graft used</Form.Label>
                      <Form.Select
                        value={formData.typeOfBoneGraftUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "typeOfBoneGraftUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Allograft">Allograft (Fill in details in graft catalog)</option>
                        <option value="Autograft">Autograft (Fill in details in graft catalog)</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler (Fill in details in graft catalog)</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export default OperationsLabrumAndInstability