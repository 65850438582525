import React, { useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import SelfAssessmentHeader from './components/SelfAssessmentHeader/SelfAssessmentHeader';
import Protected from './components/Protected/Protected';
import Dashboard from './screens/Dashboard/Dashboard';
import Patients from './screens/Patients/Patients';
import Login from './screens/Login/Login';
import NotFound from './screens/NotFound/NotFound';
import Analysis from './screens/Analysis/Analysis';
import PreTreatment from './screens/SelfAssessment/PreTreatment/PreTreatment';
import AfterXWeeks from './screens/SelfAssessment/AfterXWeeks/AfterXWeeks';
import AfterXMonths from './screens/SelfAssessment/AfterXMonths/AfterXMonths';
import AfterXYears from './screens/SelfAssessment/AfterXYears/AfterXYears';
import PatientDashboard from './screens/PatientDashboard/PatientDashboard';
import { ToastContainer } from 'react-toastify';
import EnrollPatient from './screens/EnrollPatient/EnrollPatient';
import AddSurgery from './screens/AddSurgery/AddSurgery';
import ClinicVisit from './screens/ClinicVisit/ClinicVisit';
import SurgeryDetails from './screens/SurgeryDetails/SurgeryDetails';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import EditPreTreatmentSurvey from './screens/EditSurvey/EditPreTreatmentSurvey';
import Notifications from './screens/Notifications/Notifications';
import PatientCompliance from './screens/PatientCompliance/PatientCompliance';
import Complication from './screens/Complication/Complication';

const App = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("userInfo") !== null)
  const [user, setUser] = useState(localStorage.getItem("userInfo") !== null? JSON.parse(localStorage.getItem("userInfo")) : {})
  const [patientDateRange, setPatientDateRange] = useState((() => {
    const date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return [firstDay, lastDay]
  }));
  const [numberOfNotifications, setNumberOfNotifications] = useState(0)

  const logUserIn = user => {
    setUser(user)
    setLoggedIn(true)
    if(pathname === "/login" || pathname === "/") {
      navigate("/dashboard");
    }
    else {
      navigate(0)
    } 
  }

  const logUserOut = () => {
    setUser({})
    setLoggedIn(false)
    localStorage.removeItem('userInfo')
    navigate("/login", { replace: true });
  }
  
  return (
    <div className="App">
      <ToastContainer/>
      {pathname.includes("self-assessment")? 
      (
        <div className='header'>
          <SelfAssessmentHeader />
        </div>
      )
      :
      (
        <div className='header'>
          <Header loggedIn={loggedIn} user={user} logUserOut={logUserOut} numberOfNotifications={numberOfNotifications} setNumberOfNotifications={setNumberOfNotifications}/>
        </div>
      )
    }
      <div className="container-xxl otos-container">
        <Routes>
          <Route path="/" element={<Login logUserIn={logUserIn}/>} />
          <Route path="/login" element={<Login logUserIn={logUserIn}/>} />
          <Route path="/dashboard" element={<Protected loggedIn={loggedIn}><Dashboard user={user} logUserOut={logUserOut}/></Protected>} />
          
          <Route path="/patients" element={<Protected loggedIn={loggedIn}><Patients user={user} logUserOut={logUserOut} dateRange={patientDateRange} setDateRange={setPatientDateRange}/></Protected>} />
          <Route path="/patients/new" element={<Protected loggedIn={loggedIn}><EnrollPatient newPatient={true} user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId" element={<Protected loggedIn={loggedIn}><PatientDashboard user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/edit" element={<Protected loggedIn={loggedIn}><EnrollPatient newPatient={false} user={user} logUserOut={logUserOut}/></Protected>} />
         
          <Route path="/patients/:customPatientId/events/new/surgery" element={<Protected loggedIn={loggedIn}><AddSurgery newSurgery={true} user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/events/new/clinic-visit" element={<Protected loggedIn={loggedIn}><ClinicVisit newClinicVisit={true} user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/events/new/complication" element={<Protected loggedIn={loggedIn}><Complication newComplication={true} user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/events/surgery/:eventId/edit" element={<Protected loggedIn={loggedIn}><AddSurgery newSurgery={false} user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/events/surgery/:eventId" element={<Protected loggedIn={loggedIn}><SurgeryDetails user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/events/clinic-visit/:eventId/edit" element={<Protected loggedIn={loggedIn}><ClinicVisit newClinicVisit={false} user={user} logUserOut={logUserOut}/></Protected>} />
          <Route path="/patients/:customPatientId/events/complication/:eventId/edit" element={<Protected loggedIn={loggedIn}><Complication newComplication={false} user={user} logUserOut={logUserOut}/></Protected>} />

          
          <Route path="/notifications" element={<Protected loggedIn={loggedIn}><Notifications user={user} logUserOut={logUserOut} setNumberOfNotifications={setNumberOfNotifications}/></Protected>} />
          <Route path="/analysis" element={<Protected loggedIn={loggedIn}><Analysis /></Protected>} />

          <Route path="/patient-compliance" element={<Protected loggedIn={loggedIn}><PatientCompliance user={user} logUserOut={logUserOut}/></Protected>} />
          
          <Route path= '/patient/:customPatientId/event/:eventId/:study/:treatmentSide/self-assessment/pre-treatment' element={<PreTreatment />} />,
          <Route path= '/patient/:customPatientId/event/:eventId/:study/:treatmentSide/self-assessment/week/:week' element={<AfterXWeeks />} />,
          <Route path= '/patient/:customPatientId/event/:eventId/:study/:treatmentSide/self-assessment/month/:month' element={<AfterXMonths />} />,
          <Route path= '/patient/:customPatientId/event/:eventId/:study/:treatmentSide/self-assessment/year/:year' element={<AfterXYears />} />,
          
          <Route path= '/patient/:customPatientId/event/:eventId/:study/:treatmentSide/survey/:stage/new' element={<EditPreTreatmentSurvey newSurvey={true} user={user} logUserOut={logUserOut}/>} />,
          <Route path= '/patient/:customPatientId/event/:eventId/:study/:treatmentSide/survey/:stage/edit' element={<EditPreTreatmentSurvey newSurvey={false} user={user} logUserOut={logUserOut}/>} />,
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
