import React, { useState, useEffect } from 'react'
import { BsFillPersonFill } from "react-icons/bs";
import { Form, Button } from "react-bootstrap"
import { useParams, useNavigate } from 'react-router-dom';
import { Oval } from "react-loader-spinner"
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { toast } from 'react-toastify';

import validator from 'validator'
import moment from 'moment'

import "./EnrollPatient.scss"

const EnrollPatient = ({newPatient, user, logUserOut}) => {
  const navigate = useNavigate()
  const params = useParams()
  const [loading, setLoading] = useState(!newPatient)
  const [customPatientId, setCustomPatientId] = useState(newPatient? "" : params["customPatientId"])
  const [email, setEmail] = useState("")
  const [altEmail, setAltEmail] = useState("")
  const [cellPhoneNumber, setCellPhoneNumber] = useState("")
  const [altCellPhoneNumber, setAltCellPhoneNumber] = useState("")
  const [gender, setGender] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")
  const [country, setCountry] = useState("BE")
  const [language, setLanguage] = useState("nl")
  const [disableButton, setDisableButton] = useState(false)
  const [error, setError] = useState(false)
  const customIdRegex = /^[A-Z]{4}[0-9]{8}$/
  const cellPhoneRegex = /^\+?[1-9]?[0-9]{10}$/
  const phoneRegex = /^0{1}[0-9]{8}$/

  const getPatientEventsByCustomId = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}`, config)
      const patient = response.data.patient
      setCustomPatientId(patient.custom_patient_id)
      setEmail(patient.email)
      setAltEmail(patient.alt_email? patient.alt_email : "")
      setCellPhoneNumber(patient.cell_phone_number)
      setAltCellPhoneNumber(patient.alt_cell_phone_number? patient.alt_cell_phone_number : "")
      setDateOfBirth(moment(patient.date_of_birth).format("YYYY-MM-DD"))
      setLanguage(patient.language)
      setGender(patient.gender)
      setCountry(patient.country)
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  useEffect(() => {
    if(!newPatient) {
      getPatientEventsByCustomId()
    }
  }, [])

  const submitHandler = async (e) => {
    e.preventDefault()
    if(!customIdRegex.test(customPatientId) || (email !== "" && !validator.isEmail(email)) || (altEmail !== "" && !validator.isEmail(altEmail)) || !(cellPhoneRegex.test(cellPhoneNumber) || phoneRegex.test(cellPhoneNumber)) || (altCellPhoneNumber !== "" && !(cellPhoneRegex.test(altCellPhoneNumber) || phoneRegex.test(altCellPhoneNumber))) || dateOfBirth === "" || gender === "") {
      setError(true)
    }
    else {
      setError(false)
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const body = {
          customPatientId: customPatientId,
          email: email,
          altEmail: altEmail,
          cellPhoneNumber: cellPhoneNumber,
          altCellPhoneNumber: altCellPhoneNumber,
          gender: gender,
          dateOfBirth: dateOfBirth,
          country: country,
          language: language
        }
        if(newPatient) {
          setDisableButton(true)
          const response = await axios.post(`${BASE_URL}/api/v1/patient/${customPatientId}`, body, config)
          toast.success('Patient successfully enrolled', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setDisableButton(false)
          navigate(`/patients/${customPatientId}`)
        }
        else {
          const response = await axios.put(`${BASE_URL}/api/v1/patient/${customPatientId}`, body, config)
          toast.success('Patient updated', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          navigate(`/patients/${customPatientId}`)
        }
        
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if(error.response) {
          if(error.response.status === 403) {
            toast.error('Custom Patient ID already exists!', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
          if (error.response.status === 401 || message === 'Not authorized, token failed') {
            logUserOut()
          }
        }
      }

    }
  }

  return (
    <div className="enroll-patient">
      {loading? (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <>
          <div className='patient-header'>
          <h2 className="logo"><BsFillPersonFill />{newPatient? "New Patient" : customPatientId}</h2>
          </div>
          <Form onSubmit={submitHandler}>
            <div className="form-content">
              <div className="general-information">
              <h3>General Information</h3>
                <Form.Group className="form-group">
                    <Form.Label>Custom Patient ID</Form.Label>
                    <Form.Control
                      disabled={!newPatient}
                      style={{ border: error && !customIdRegex.test(customPatientId) ? "2px solid red" : "" }}
                      type="text"
                      value={customPatientId}
                      onChange={(e) => setCustomPatientId(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      style={{ border: error && email !== "" && !validator.isEmail(email) ? "2px solid red" : "" }}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Alternative Email</Form.Label>
                    <Form.Control
                      style={{ border: error && altEmail !== "" && !validator.isEmail(altEmail) ? "2px solid red" : "" }}
                      type="email"
                      value={altEmail}
                      onChange={(e) => setAltEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Cell Phone Number</Form.Label>
                    <Form.Control
                      style={{ border: error && !(cellPhoneRegex.test(cellPhoneNumber) || phoneRegex.test(cellPhoneNumber)) ? "2px solid red" : "" }}
                      type="tel"
                      value={cellPhoneNumber}
                      onChange={(e) => setCellPhoneNumber(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Alt Cell Phone Number</Form.Label>
                    <Form.Control
                      style={{ border: error && altCellPhoneNumber !== "" && !(cellPhoneRegex.test(altCellPhoneNumber) || phoneRegex.test(altCellPhoneNumber)) ? "2px solid red" : "" }}
                      type="tel"
                      value={altCellPhoneNumber}
                      onChange={(e) => setAltCellPhoneNumber(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      style={{ border: error && dateOfBirth === "" ? "2px solid red" : "" }}
                      type="date"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      style={{ border: error && gender === "" ? "2px solid red" : "" }}
                      value={gender}
                      onChange={(event) => setGender(event.target.value)}>
                      <option value="" hidden>Select gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Select>
                  </Form.Group>
              </div>   
              <div className="localization">
                <h3>Localization</h3>
                <Form.Group className="form-group">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    style={{ border: error && country === "" ? "2px solid red" : "" }}
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}>
                    <option value="BE" >Belgium</option>
                    <option value="FR">France</option>
                    <option value="GB">Great Britain</option>
                    <option value="DE">Germany</option>
                    <option value="NL">The Netherlands</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Language</Form.Label>
                  <Form.Select
                    style={{ border: error && language === "" ? "2px solid red" : "" }}
                    value={language}
                    onChange={(event) => setLanguage(event.target.value)}>
                    <option value="nl">Dutch</option>
                    <option value="fr">French</option>
                    <option value="en">English</option>
                    <option value="de">German</option>
                    <option value="pl">Polish</option>
                    <option value="it">Italian</option>
                  </Form.Select>
                </Form.Group>
              </div>        
            </div>
            <Button type="submit" variant="primary" disabled={disableButton}>
              {newPatient? "Create Patient" : "Update Patient"}
            </Button>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Form>
        </>
      )}
    </div>
  )
}

export default EnrollPatient