import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RxCross1 } from "react-icons/rx";

import "./PostOperativeManagement.scss"

const PostOperativeManagement = ({ formData, form, arrayIndex, onChangeHandler, onRemoveHandler, onSubmitHandler }) => {
  switch(form) {
    case "postOpPath": {
      return (
        <div className="post-op-path">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Post-Op Path
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form onSubmit={e => {e.preventDefault(); onSubmitHandler()}}>
                  <Row>
                    <Col>
                        <Form.Label>Post-Op Path</Form.Label>
                        <Form.Select
                          value={formData.postOpPath}
                          onChange={(e) => {onChangeHandler(arrayIndex, "postOpPath", e.target.value)}}
                        >
                          <option value="" hidden>Select an option</option>
                          <option value="Inpatient">Inpatient</option>
                          <option value="Outpatient">Outpatient</option>
                          <option value="Outpatient-23 hour observation">Outpatient-23 hour observation</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Label>Inpatient stay (days)</Form.Label>
                        <Form.Control
                          type="number"
                          step={1}
                          min={0}
                          max={365}
                          value={formData.inpatientStayDays}
                          onChange={(e) => {onChangeHandler(arrayIndex, "inpatientStayDays", e.target.value)}}
                        ></Form.Control>
                      </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )     
    }
    default: {
      return null
    }
  }
}

export default PostOperativeManagement