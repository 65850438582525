import React, { useState, useEffect } from 'react'
import { BsFillPersonFill } from "react-icons/bs";
import { Form, Button, Dropdown } from "react-bootstrap"
import { useParams, useNavigate } from 'react-router-dom';
import { GoMail, GoClock, GoGraph, GoAlert, GoPlus, GoStarFill, GoHourglass} from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { FaFemale, FaMale, FaRegCalendar } from "react-icons/fa";
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { toast } from 'react-toastify';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import _ from 'underscore'
import { LineChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';

import validator from 'validator'
import moment from 'moment'

import "./patientDashboard.scss"

const shoulderArthroplastyMilestones = [
  "pre_treatment",
  "two_weeks",
  "six_weeks",
  "three_months",
  "six_months",
  "one_year",
  "two_years",
  "three_years",
  "four_years",
  "five_years",
  "six_years",
  "seven_years",
  "eight_years",
  "nine_years",
  "ten_years",
  "eleven_years",
  "twelve_years",
  "thirteen_years",
  "fourteen_years",
  "fifteen_years"
]

const shoulderArthrscopyAndElbowMilestones = [
  "pre_treatment",
  "two_weeks",
  "six_weeks",
  "three_months",
  "six_months",
  "one_year",
  "two_years",
  "five_years",
]

const milestoneToTimelineEventNameMap = {
  "pre_treatment": "Pre-treatment Survey",
  "two_weeks": "2 Weeks Survey",
  "six_weeks": "6 Weeks Survey",
  "three_months": "3 Months Survey",
  "six_months": "6 Months Survey",
  "one_year": "1 Year Survey",
  "two_years": "2 Years Survey",
  "three_years": "3 Years Survey",
  "four_years": "4 Years Survey",
  "five_years": "5 Years Survey",
  "six_years": "6 Years Survey",
  "seven_years": "7 Years Survey",
  "eight_years": "8 Years Survey",
  "nine_years": "9 Years Survey",
  "ten_years": "10 Years Survey",
  "eleven_years": "11 Years Survey",
  "twelve_years": "12 Years Survey",
  "thirteen_years": "13 Years Survey",
  "fourteen_years": "14 Years Survey",
  "fifteen_years": "15 Years Survey"
}

const milestoneToUrlStageMap = {
  "pre_treatment": "pre-treatment",
  "two_weeks": "2-weeks",
  "six_weeks": "6-weeks",
  "three_months": "3-months",
  "six_months": "6-months",
  "one_year": "1-year",
  "two_years": "2-years",
  "three_years": "3-years",
  "four_years": "4-years",
  "five_years": "5-years",
  "six_years": "6-years",
  "seven_years": "7-years",
  "eight_years": "8-years",
  "nine_years": "9-years",
  "ten_years": "10-years",
  "eleven_years": "11-years",
  "twelve_years": "12-years",
  "thirteen_years": "13-years",
  "fourteen_years": "14-years",
  "fifteen_years": "15-years"
}

const PatientDashboard = ({ user, logUserOut }) => {
  const navigate = useNavigate()
  const { customPatientId } = useParams()
  const [showEmail, setShowEmail] = useState(false)
  const [loading, setLoading] = useState(true)
  const [patient, setPatient] = useState({})
  const [vasData, setVasData] = useState([])
  const [saneData, setSaneData] = useState([])
  const [asesFunctionData, setAsesFunctionData] = useState([])
  const [asesIndexData, setAsesIndexData] = useState([])
  const [patientEvents, setPatientEvents] = useState([])
  const [timelineEvents, setTimelineEvents] = useState([])
  const [activeMajorEvents, setActiveMajorEvents] = useState([])
  const [selectedActiveMajorEventId, setSelectedActiveMajorEventId] = useState("")
  const [selectedTest, setSelectedTest] = useState("vas")
  
  useEffect(() => {
    const getPatientByCustomId = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}`, config)
        const patient = response.data.patient
        setPatient(patient)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getPatientByCustomId()
  }, [customPatientId])

  
  useEffect(() => {
    const getPatientEventsByCustomId = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}/events`, config)
        const events = response.data.events
        setPatientEvents(events)
        setActiveMajorEvents(_.sortBy(events.filter(event => event.event_type === "surgery"), "event_date").reverse())
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getPatientEventsByCustomId()
  }, [customPatientId])

  useEffect(() => {
    if(activeMajorEvents.length > 0) {
      setSelectedActiveMajorEventId(activeMajorEvents[0].id)
    }
  }, [activeMajorEvents])

  
  useEffect(() => {
    const getGraphData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
        const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}/event/${selectedActiveMajorEventId}/graph-data`, config)
        const data = response.data
        setVasData(data.vasData)
        setSaneData(data.saneData)
        setAsesFunctionData(data.asesFunctionData)
        setAsesIndexData(data.asesIndexData)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(selectedActiveMajorEventId !== "") {
      getGraphData()
    }
  }, [selectedActiveMajorEventId])

  
  useEffect(() => {
    const mapPatientEventsToTimelineEvents = () => {
      const mappedTimelineEvents = []
      for(const event of patientEvents) {
        switch(event.event_type) {
          case "surgery": {
            mappedTimelineEvents.push({
              date: moment(event.event_date).add(12, 'hours'),
              id: event.id,
              name: "Surgery",
              type: "surgery",
              study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
              side: event.treatment_side === "Left"? "L" : "R",
              active: event.event_status === "Active",
              studyFull: event.study,
              sideFull: event.treatment_side,
            })
            if(["elbow", "shoulder arthroscopy"].includes(event.study)) {
              for(const milestone of shoulderArthrscopyAndElbowMilestones) {
                if(event[`${milestone}_submitted`]) {
                  mappedTimelineEvents.push({
                    date: moment(event[`${milestone}_date_of_submission`]),
                    id: event.id,
                    name: milestoneToTimelineEventNameMap[milestone],
                    type: "survey",
                    study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
                    side: event.treatment_side === "Left"? "L" : "R",
                    active: event.event_status === "Active",
                    studyFull: event.study,
                    sideFull: event.treatment_side,
                    stage: milestoneToUrlStageMap[milestone],
                  })
                }
                else {
                  if(event[`${milestone}_email_sent`] || event[`${milestone}_first_reminder_sent`]) {
                    mappedTimelineEvents.push({
                      date: moment(event[`${milestone}_email_sent_timestamp`]),
                      id: event.id,
                      name: `${milestoneToTimelineEventNameMap[milestone]}`,
                      type: "pending-survey",
                      study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
                      side: event.treatment_side === "Left"? "L" : "R",
                      active: event.event_status === "Active",
                      studyFull: event.study,
                      sideFull: event.treatment_side,
                      stage: milestoneToUrlStageMap[milestone],
                    })
                  }
                }
              }
            }
            else {
              for(const milestone of shoulderArthroplastyMilestones) {
                if(event[`${milestone}_submitted`]) {
                  mappedTimelineEvents.push({
                    date: moment(event[`${milestone}_date_of_submission`]),
                    id: event.id,
                    name: milestoneToTimelineEventNameMap[milestone],
                    type: "survey",
                    study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
                    side: event.treatment_side === "Left"? "L" : "R",
                    active: event.event_status === "Active",
                    studyFull: event.study,
                    sideFull: event.treatment_side,
                    stage: milestoneToUrlStageMap[milestone],
                  })
                }
                else {
                  if(event[`${milestone}_email_sent`]) {
                    mappedTimelineEvents.push({
                      date: moment(event[`${milestone}_email_sent_timestamp`]),
                      id: event.id,
                      name: milestoneToTimelineEventNameMap[milestone],
                      type: "pending-survey",
                      study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
                      side: event.treatment_side === "Left"? "L" : "R",
                      active: event.event_status === "Active",
                      studyFull: event.study,
                      sideFull: event.treatment_side,
                      stage: milestoneToUrlStageMap[milestone],
                    })
                  }
                }
              }
            }
            
          } break;
          case "clinic visit": {
            mappedTimelineEvents.push({
              date: moment(event.event_date),
              id: event.id,
              name: "Clinic Visit",
              type: "clinic-visit",
              study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
              side: patientEvents.filter(patientEvent => patientEvent.id === event.related_event_id)[0].treatment_side === "Left"? "L" : "R",
              studyFull: event.study,
              sideFull: event.treatment_side,
            })
          } break;
          case "complication": {
            mappedTimelineEvents.push({
              date: moment(event.event_date),
              id: event.id,
              name: "Complication",
              type: "complication",
              study: event.study === "elbow"? "E" : event.study === "shoulder arthroscopy"? "SAs" : "SAp",
              side: patientEvents.filter(patientEvent => patientEvent.id === event.related_event_id)[0].treatment_side === "Left"? "L" : "R",
              studyFull: event.study,
              sideFull: event.treatment_side,
            })
          } break;
        } 
      }
      setTimelineEvents(_.sortBy(mappedTimelineEvents, "date").reverse())
    }
    mapPatientEventsToTimelineEvents()
  }, [patientEvents])

  const onEventClickHandler = (eventType, eventId, study, side, stage) => {
    switch(eventType) {
      case "surgery": {
        navigate(`/patients/${customPatientId}/events/surgery/${eventId}/`)
      } break;
      case "clinic-visit": {
        navigate(`/patients/${customPatientId}/events/clinic-visit/${eventId}/edit`)
      } break;
      case "survey": {
        navigate(`/patient/${customPatientId}/event/${eventId}/${study.replace(" ", "-")}/${side.toLowerCase()}/survey/${stage}/edit`)
      } break;
      case "complication" : {
        navigate(`/patients/${customPatientId}/events/complication/${eventId}/edit`)
      } break;
      case "pending-survey" : {
        navigate(`/patient/${customPatientId}/event/${eventId}/${study.replace(" ", "-")}/${side.toLowerCase()}/survey/${stage}/new`)
      } 
    }
  }

  return (
    <div className="patient-dashboard">
      <div className='patient-dashboard-header'>
        <div className='patient-id'>
          <h2><BsFillPersonFill />{customPatientId}</h2>
          <Button onClick={() => navigate(`/patients/${customPatientId}/edit`)}>View/Edit</Button>
        </div>
        <div className='patient-dashboard-subheader'>
          <div className='patient-info'>
            <p><GoMail /> {showEmail && patient.email } (<span className='show-hide-anchor' onClick={() => setShowEmail(!showEmail)}>{showEmail? "hide" : "show"} email</span>)</p> 
            {patient.gender === "M"? <p><FaMale />Male</p> : <p><FaFemale />Female</p>}
            <p><FaRegCalendar /> {moment(patient.date_of_birth).format("DD/MM/YYYY")}</p>
          </div>
        </div>
      </div>
  
      
      <div className='patient-dashboard-body'>
        <div className='left-column'>
          <div className='recent-activity'>
            <div className='recent-activity-header'>
              <h4><GoClock /> Recent Activity</h4>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <GoPlus /> Add Event
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate(`/patients/${customPatientId}/events/new/surgery`)}>Surgery</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate(`/patients/${customPatientId}/events/new/clinic-visit`)}>Clinic visit</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate(`/patients/${customPatientId}/events/new/complication`)}>Complication</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='timeline'>
              <VerticalTimeline
                animate={false}
                layout='1-column-left'
                lineColor='rgb(222, 222, 222)'
              >
                {timelineEvents.map((event, index) => {
                  return (
                    <VerticalTimelineElement key={index} className={event.type} onTimelineElementClick={() => onEventClickHandler(event.type, event.id, event.studyFull, event.sideFull, event.stage)}>
                      <div className='timeline-event-study'>
                        <p>{event.study} {<i>({event.side})</i>}</p>
                      </div>
                      <div className='timeline-event-description'>
                        <p>{event.name}{event.type === "pending-survey"? (event.active? <> (<GoHourglass/>)</> : <> (<RxCross2/>)</>) : (event.type === "surgery" && event.active? <> (<GoStarFill/>)</> : "")}</p>
                        <p>{event.date.format("DD/MM/YYYY")}</p>
                      </div>
                    </VerticalTimelineElement>
                  )
                })}
              </VerticalTimeline>
            </div>
          </div>
          <div className='alerts'>
            <h4><GoAlert /> Alerts</h4>
            <p>Coming soon</p>
          </div>
        </div>
        <div className='right-column'>
          <div className='pain-scale'>
            <div className='pain-scale-header'>
              <h4><GoGraph /> Pain Scale</h4>
              <div className="active-major-event-filter">
                <Form.Group className="form-group">
                  <Form.Select
                    value={selectedActiveMajorEventId}
                    onChange={(event) => setSelectedActiveMajorEventId(event.target.value)}>
                    {
                      activeMajorEvents.map((activeMajorEvent, index) => {
                        return (
                          <option key={index} value={activeMajorEvent.id}>{activeMajorEvent.study.charAt(0).toUpperCase() + activeMajorEvent.study.slice(1)} ({activeMajorEvent.treatment_side})</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className='graphs'>
            {vasData.length > 0 &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  data={vasData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval="preserveStartEnd"
                    dy={10}
                  />
                  <YAxis 
                    label={{ value: 'VAS Score', angle: -90, offset:20, position:'insideBottomLeft'}} 
                    domain={[0, 10]}                
                    tickCount={10}  
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  <Area type="monotone" dataKey="limit" isAnimationActive={true} stroke="none" fill="lightgrey" strokeWidth={2} />
                  <Line type="monotone" dataKey="avg" isAnimationActive={true} connectNulls stroke="rgb(101, 201, 122)" fill="rgb(101, 201, 122)" strokeWidth={2} />
                  <Line type="monotone" dataKey="score" isAnimationActive={true} connectNulls stroke="rgb(177, 68, 52)" fill="rgb(177, 68, 52)" strokeWidth={2} activeDot={{ r: 8 }} />
                </ComposedChart>
              </ResponsiveContainer>
            }
            {saneData.length > 0 &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  data={saneData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval="preserveStartEnd"
                    dy={10}
                  />
                  <YAxis 
                    label={{ value: 'Sane Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={[0, 100]}                
                    tickCount={10}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  <Line type="monotone" dataKey="avg" isAnimationActive={true} connectNulls stroke="rgb(101, 201, 122)" fill="rgb(101, 201, 122)" strokeWidth={2} />
                  <Line type="monotone" dataKey="score" isAnimationActive={true} connectNulls stroke="rgb(177, 68, 52)" fill="rgb(177, 68, 52)" strokeWidth={2} activeDot={{ r: 8 }} />
                </ComposedChart>
              </ResponsiveContainer>
            }
            {asesFunctionData.length > 0 &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  data={asesFunctionData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval="preserveStartEnd"
                    dy={10}
                  />
                  <YAxis 
                    label={{ value: 'ASES Function Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={[0, 36]}                
                    tickCount={6}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  <Line type="monotone" dataKey="avg" isAnimationActive={true} connectNulls stroke="rgb(101, 201, 122)" fill="rgb(101, 201, 122)" strokeWidth={2} />
                  <Line type="monotone" dataKey="functionScore" isAnimationActive={true} connectNulls stroke="rgb(177, 68, 52)" fill="rgb(177, 68, 52)" strokeWidth={2} activeDot={{ r: 8 }} />
                </ComposedChart>
              </ResponsiveContainer>
            }
            {asesIndexData.length > 0 &&
              <ResponsiveContainer width="100%" height="50%">
                <ComposedChart
                  width={600}
                  height={400}
                  data={asesIndexData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 50,
                  }}
                  overflow="visible"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="stage" 
                    angle={45} 
                    textAnchor="start"
                    interval="preserveStartEnd"
                    dy={10}
                  />
                  <YAxis 
                    label={{ value: 'ASES Index Score', angle: -90, offset:10, position:'insideBottomLeft'}} 
                    domain={[0, 100]}                
                    tickCount={10}
                    // dx={10}
                  />
                  <Tooltip />
                  <Legend
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"/>
                  <Line type="monotone" dataKey="avg" isAnimationActive={true} connectNulls stroke="rgb(101, 201, 122)" fill="rgb(101, 201, 122)" strokeWidth={2} />
                  <Line type="monotone" dataKey="indexScore" isAnimationActive={true} connectNulls stroke="rgb(177, 68, 52)" fill="rgb(177, 68, 52)" strokeWidth={2} activeDot={{ r: 8 }} />
                </ComposedChart>
              </ResponsiveContainer>
            }
            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default PatientDashboard