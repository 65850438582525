import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RxCross1 } from "react-icons/rx";

import './PatientInformationSurgicalTimeAndPersonnel.scss'

const PatientInformationSurgicalTimeAndPersonnel = ({ form, formData, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "patientInformation": {
      return (
        <div className="patient-information">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Patient Information
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Weight</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.weight}
                        onChange={(e) => {onChangeHandler(arrayIndex, "weight", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Height</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.height}
                        onChange={(e) => {onChangeHandler(arrayIndex, "height", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Smoker?</Form.Label>
                      <Form.Select
                        value={formData.smoker}
                        onChange={(e) => {onChangeHandler(arrayIndex, "smoker", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Diabetic?</Form.Label>
                      <Form.Select
                        value={formData.diabetic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "diabetic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Workman's compensation case?</Form.Label>
                      <Form.Select
                        value={formData.workmansCompensationCase}
                        onChange={(e) => {onChangeHandler(arrayIndex, "workmansCompensationCase", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                  </Col>
                  <Col>
                    <Form.Label>Pre-operative use narcotics?</Form.Label>
                    <Form.Select
                      value={formData.preOperativeUseOfNarcotics}
                      onChange={(e) => {onChangeHandler(arrayIndex, "preOperativeUseOfNarcotics", e.target.value)}}
                    >
                      <option value="" hidden>Select an option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Duration of symptoms</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.1}
                        min={0}
                        max={999}
                        value={formData.durationOfSymptoms}
                        onChange={(e) => {onChangeHandler(arrayIndex, "durationOfSymptoms", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Profession</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.profession}
                        onChange={(e) => {onChangeHandler(arrayIndex, "profession", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Principal sport</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.principalSport}
                        onChange={(e) => {onChangeHandler(arrayIndex, "principalSport", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "surgicalTimeAndPersonnel": {
      return (
        <div className="surgical-time-and-personnel">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Surgical Time and Personnel
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
              <Form>
                  <Row>
                    <Col>
                      <Form.Label>Surgical Time</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.surgicalTime}
                        onChange={(e) => {onChangeHandler(arrayIndex, "surgicalTime", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of Surgical Techs</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.numberOfSurgicalTechs}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfSurgicalTechs", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of PAs</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.numberOfPas}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfPas", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of Surgical Assistants</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.numberOfSurgicalAssistants}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfSurgicalAssistants", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of Nurses</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.numberOfNurses}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfNurses", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of Surgeons</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.numberOfSurgeons}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfSurgeons", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of Fellows</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.numberOfFellows}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfFellows", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of Residents</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.numberOfResidents}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfResidents", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Number of NPs (Nurse practitioner)</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={500}
                        value={formData.numberOfNursePractitioners}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfNursePractitioners", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Number of Certified Orthopedic Technologist required</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={300}
                        value={formData.numberOfOrthopedicTechnologists}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfOrthopedicTechnologists", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: {
      return null
    }
  }
}

export default PatientInformationSurgicalTimeAndPersonnel