import React, { useState, useEffect } from 'react'
import { Form, Button } from "react-bootstrap"
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { Oval } from "react-loader-spinner"
import { toast } from 'react-toastify';
import { AiFillWarning } from "react-icons/ai";
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal'
import moment from "moment"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Dropdown from 'react-bootstrap/Dropdown'
import Complications from '../../components/ArthrexCatalog/General/Complications/Complications';

import "./Complication.scss"

const studyAbbreviationMap = {
  "shoulder arthroscopy": "SAs",
  "shoulder arthroplasty": "SAp",
  "elbow": "Elbow "
}

const Complication = ({ newComplication, user, logUserOut }) => {
  const navigate = useNavigate()
  const { customPatientId, eventId } = useParams()
  const [loading, setLoading] = useState(true)
  const [patientSurgeries, setPatientSurgeries] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [relatedSurgeryId, setRelatedSurgeryId] = useState("")
  const [relatedSurgery, setRelatedSurgery] = useState(null)
  const [complicationEvent, setComplicationEvent ] = useState(null)
  const [catalogEntries, setCatalogEntries] = useState([])
  const [deletedEntries, setDeletedEntries] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const handleClose = () => setShowModal(false)
  const [eventUpdated, setEventUpdated] = useState(false)

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getActivePatientSurgeryEventsByCustomId = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/patient/${customPatientId}/events/surgery/active`, config)
        const surgeries = response.data.surgeries
        setPatientSurgeries(surgeries)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(newComplication) {
      getActivePatientSurgeryEventsByCustomId()
    }
  }, [customPatientId])

  useEffect(() => {
    const getComplicationEventBydId = async () => {
      try {
        let response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}`, config)
        const complicationEvent = response.data.event
        setComplicationEvent(complicationEvent)

        response = await axios.get(`${BASE_URL}/api/v1/event/${complicationEvent.related_event_id}`, config)
        const relatedSurgeryEvent = response.data.event
        setRelatedSurgery(relatedSurgeryEvent)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(!newComplication) {
      getComplicationEventBydId(eventId)
    }
  }, [eventId])

  useEffect(() => {
    const getCatalogEntriesByEventId = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}/complication`, config)
        const catalogEntries = response.data.catalogEntries
        setCatalogEntries(catalogEntries)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    if(eventId) {
      getCatalogEntriesByEventId()
    }
  }, [eventId, eventUpdated])

  useEffect(() => {
    if(newComplication) {
      setCatalogEntries([])
      setDeletedEntries([])
      const relatedSurgery = (patientSurgeries.filter(surgery => surgery.id === relatedSurgeryId)[0])
      if(relatedSurgery) {
        setRelatedSurgery(relatedSurgery)
      }
    }
  }, [relatedSurgeryId])

  const addCatalogEntry = (form) => {
    switch(form) {
      case "rotatorCuff":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "rotatorCuff",
            formData: {
              "type": "",
              "softTissueAugmentationFailure": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "labrumAndInstability":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "labrumAndInstability",
            formData: {
              "type": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "acJoint":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "acJoint",
            formData: {
              "type": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "cartilageOsteochondral":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "cartilageOsteochondral",
            formData: {
              "type": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "bleedingHematologicClotting":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "bleedingHematologicClotting",
            formData: {
              "type": "",
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "nervousSystem":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "nervousSystem",
            formData: {
              "type": "",
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "infection":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "infection",
            formData: {
              "type": "",
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "excessiveSwellingSepsisWound":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "excessiveSwellingSepsisWound",
            formData: {
              "wound": "",
              "excessiveSwelling": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "motionLoss":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "motionLoss",
            formData: {
              "type": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "fractureAndDislocation":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "fractureAndDislocation",
            formData: {
              "fracture": "",
              "dislocation": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "heterotopicOssification":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "heterotopicOssification",
            formData: {
              "details": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "nonUnionPseudoarthrosisMalunion":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "nonUnionPseudoarthrosisMalunion",
            formData: {
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "avn":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "avn",
            formData: {
              "weeksAfterSurgery": "",
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "hardwareFailure":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "hardwareFailure",
            formData: {
              "type": "",
              "intraArticularHardwarePenetration": false,
              "hardwareRemoval": false,
              "revisionRequired": false,
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "generalMedical":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "generalMedical",
            formData: {
              "type": "",
              "manufacturer": "",
            }
          }  
        ])
        break;
      case "arthroplastyFailure":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "arthroplastyFailure",
            formData: {
              "type": "",
              "manufacturer": "",
              "revisionRequired": false,
            }
          }  
        ])
        break;
      case "graftFailure":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "graftFailure",
            formData: {
              "type": "",
              "manufacturer": "",
              "revisionRequired": false,
            }
          }  
        ])
        break;
      case "tendonRepairOrReconstruction":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "tendonRepairOrReconstruction",
            formData: {
              "associatedProcedure": "",
              "type": "",
              "manufacturer": "",
              "revisionRequired": false,
            }
          }  
        ])
        break;
      case "instability":
        setCatalogEntries(prevState => [
          ...prevState,
          {
            form: "instability",
            formData: {
              "associatedProcedure": "",
              "type": "",
              "manufacturer": "",
              "revisionRequired": false,
            }
          }  
        ])
        break;

      default: 
        return
    }
  }

  const handleDeleteComplication = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/v1/event/${eventId}/complication`, config)
      toast.success('Complication deleted!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      handleClose()
      navigate(-1)
    }
    catch(error) {
      console.log(error)
      toast.error('Error during survey deletion!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleCatalogEntryFormDataUpdate = (arrayIndex, key, value) => {
    let newCatalogEntries = [...catalogEntries]
    newCatalogEntries[arrayIndex].formData[key] = value
    setCatalogEntries(newCatalogEntries)
  }

  const handleCatalogEntryRemoval = (arrayIndex) => {
    const catalogEntryId = catalogEntries[arrayIndex].id
    if(catalogEntryId) {
      deletedEntries.push({
        category: catalogEntries[arrayIndex].category,
        form: catalogEntries[arrayIndex].form,
        eventId: complicationEvent.id,
        id: catalogEntryId
      })
    }
    setCatalogEntries(catalogEntries.filter((catalogEntry, index) => index !== arrayIndex))
  }

  const handleEventUpdateSubmit = async () => {
    try {
      const body = {
        eventId: eventId,
        catalogEntries: catalogEntries,
        deletedEntries: deletedEntries,
        ...(newComplication && {
          eventType: "complication",
          eventDate: moment(Date.now()).format("YYYY-MM-DD"),
          relatedEventId: relatedSurgeryId,
          customPatientId: customPatientId,
          study: relatedSurgery.study,
          treatmentSide: relatedSurgery.treatment_side
        })
      }

      setDisableButton(true)
      await axios.post(`${BASE_URL}/api/v1/event/${eventId}/complication`, body, config)
      setEventUpdated(!eventUpdated)
      toast.success(`Complication ${newComplication? "saved" : "updated"}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setDisableButton(false)
      navigate(`/patients/${customPatientId}`)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }
  
  const renderCatalog = () => {
    switch(relatedSurgery.study) {
      case "shoulder arthroscopy":
        return (
          <>
            <Accordion.Item eventKey="1" className="complications">
              <Accordion.Header>Complications</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("rotatorCuff")}>Rotator Cuff</button>
                <button onClick={() => addCatalogEntry("labrumAndInstability")}>Labrum and Instability</button>
                <button onClick={() => addCatalogEntry("acJoint")}>AC Joint</button>
                <button onClick={() => addCatalogEntry("cartilageOsteochondral")}>Cartilage/Osteochondral</button>
                <button onClick={() => addCatalogEntry("bleedingHematologicClotting")}>Bleeding/Hematologic/Clotting</button>
                <button onClick={() => addCatalogEntry("nervousSystem")}>Nervous System</button>
                <button onClick={() => addCatalogEntry("infection")}>infection</button>
                <button onClick={() => addCatalogEntry("excessiveSwellingSepsisWound")}>Excessive Swelling, Sepsis, Wound</button>
                <button onClick={() => addCatalogEntry("motionLoss")}>Motion Loss</button>
                <button onClick={() => addCatalogEntry("fractureAndDislocation")}>Fracture And Dislocation</button>
                <button onClick={() => addCatalogEntry("heterotopicOssification")}>Heterotopic Ossification</button>
                <button onClick={() => addCatalogEntry("nonUnionPseudoarthrosisMalunion")}>Non-union/pseudoarthrosis, Malunion</button>
                <button onClick={() => addCatalogEntry("avn")}>AVN</button>
                <button onClick={() => addCatalogEntry("hardwareFailure")}>Hardware Failure (for operations not listed above)</button>
                <button onClick={() => addCatalogEntry("generalMedical")}>General Medical</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      case "shoulder arthroplasty":
        return (
          <>
            <Accordion.Item eventKey="1" className="complications">
              <Accordion.Header>Complications</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("arthroplastyFailure")}>Arthroplasty Failure</button>
                <button onClick={() => addCatalogEntry("bleedingHematologicClotting")}>Bleeding/Hematologic/Clotting</button>
                <button onClick={() => addCatalogEntry("nervousSystem")}>Nervous System</button>
                <button onClick={() => addCatalogEntry("infection")}>infection</button>
                <button onClick={() => addCatalogEntry("excessiveSwellingSepsisWound")}>Excessive Swelling, Sepsis, Wound</button>
                <button onClick={() => addCatalogEntry("motionLoss")}>Motion Loss</button>
                <button onClick={() => addCatalogEntry("fractureAndDislocation")}>Fracture And Dislocation</button>
                <button onClick={() => addCatalogEntry("heterotopicOssification")}>Heterotopic Ossification</button>
                <button onClick={() => addCatalogEntry("nonUnionPseudoarthrosisMalunion")}>Non-union/pseudoarthrosis, Malunion</button>
                <button onClick={() => addCatalogEntry("avn")}>AVN</button>
                <button onClick={() => addCatalogEntry("hardwareFailure")}>Hardware Failure (for operations not listed above)</button>
                <button onClick={() => addCatalogEntry("graftFailure")}>Graft Failure</button>
                <button onClick={() => addCatalogEntry("generalMedical")}>General Medical</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      case "elbow":
        return (
          <>
            <Accordion.Item eventKey="1" className="diagnosis">
              <Accordion.Header>Complications</Accordion.Header>
              <Accordion.Body>
                <button onClick={() => addCatalogEntry("tendonRepairOrReconstruction")}>Tendon Repair/Reconstruction</button>
                <button onClick={() => addCatalogEntry("instability")}>Instability</button>
                <button onClick={() => addCatalogEntry("cartilageOsteochondral")}>Cartilage/Osteochondral</button>
                <button onClick={() => addCatalogEntry("arthroplastyFailure")}>Arthroplasty Failure</button>
                <button onClick={() => addCatalogEntry("bleedingHematologicClotting")}>Bleeding/Hematologic/Clotting</button>
                <button onClick={() => addCatalogEntry("nervousSystem")}>Nervous System</button>
                <button onClick={() => addCatalogEntry("infection")}>Infection</button>
                <button onClick={() => addCatalogEntry("excessiveSwellingSepsisWound")}>Excessive Swelling, Sepsis, Wound</button>
                <button onClick={() => addCatalogEntry("motionLoss")}>Motion Loss</button>
                <button onClick={() => addCatalogEntry("fractureAndDislocation")}>Fracture And Dislocation</button>
                <button onClick={() => addCatalogEntry("heterotopicOssification")}>Heterotopic Ossification</button>
                <button onClick={() => addCatalogEntry("nonUnionPseudoarthrosisMalunion")}>Non-union/pseudoarthrosis, Malunion</button>
                <button onClick={() => addCatalogEntry("avn")}>AVN</button>
                <button onClick={() => addCatalogEntry("hardwareFailure")}>Hardware Failure (for operations not listed above)</button>
                <button onClick={() => addCatalogEntry("generalMedical")}>General Medical</button>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )
      default:
        break;
    }
  }
  return (
    <div className="complication">
      {loading ? (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>You are about to delete a survey</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this survey?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="danger" onClick={handleDeleteComplication}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <div className='complication-header'>
            <div className='complication-title'>
              <h2 className="logo"><AiFillWarning /> Complication</h2>
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/patients" }}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/patients/${customPatientId}` }}>{customPatientId}</Breadcrumb.Item>
                <Breadcrumb.Item active>{newComplication? "New Complication" : `Complication ${studyAbbreviationMap[relatedSurgery.study]} (${relatedSurgery.treatment_side.charAt(0)}) - ${moment(relatedSurgery.event_date).format("DD/MM/YYYY")}`}</Breadcrumb.Item>
              </Breadcrumb> 
            </div>
            { 
              newComplication && (
                <div className="surgery-filter">
                  <Form.Group className="form-group">
                    <Form.Select
                      value={relatedSurgeryId}
                      onChange={(event) => setRelatedSurgeryId(parseInt(event.target.value))}>
                      <option value="" hidden>Select surgery</option>
                      {
                        patientSurgeries.map((surgery, index) => {
                          return (
                            <option key={index} value={surgery.id}>{studyAbbreviationMap[surgery.study]} ({surgery.treatment_side.charAt(0)}) - {moment(surgery.event_date).format("DD/MM/YYYY")}</option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                </div>
              )
            }
          </div>          
            {relatedSurgery && 
              (
                <div className="complication-metadata">
                  <div className="complication-metadata-overview">
                    <div className="complication-metadata-catalog-header">
                      <h4>Catalog</h4>
                    </div>
                    <Accordion>
                      {
                        renderCatalog()
                      }
                    </Accordion>
                  </div>
                  <div className="complication-metadata-details">
                    <div className="complication-metadata-details-header">
                      <h4>Forms</h4>
                      <div className='action-buttons'>
                        <Dropdown>
                          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Actions
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {setShowModal(true)}}>Delete survey</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Button disabled={disableButton} onClick={() => handleEventUpdateSubmit()}>{newComplication? "Save" : "Update"}</Button>
                      </div>
                    </div>
                    {catalogEntries && catalogEntries.map((catalogEntry, index) => {
                      return <Complications  key={index} form={catalogEntry.form} formData={catalogEntry.formData} arrayIndex={index} onChangeHandler={handleCatalogEntryFormDataUpdate} onRemoveHandler={handleCatalogEntryRemoval} />
                    })}
                  </div>
                </div>
              )
            }
        </>
      )}
    </div>
  )
}

export default Complication