import React from "react";
import { Form } from "react-bootstrap";

import './SANE.scss';

const SANE = ({ handleFormData, study, values, error}) => {
  return (
    <div className="self-assessment-test sane">
      <p className="form-title">SANE Score</p>   
      <Form.Label>1. Hoe zou u vandaag uw {study === "elbow"? "elbow" : "shoulder"} beoordelen op een schaal van 0 tot 100?</Form.Label>
      <Form.Text>Waarbij 100 uw normale functieniveau is en 0 niet in staat is om te functioneren</Form.Text>
      <Form.Group className="form-group">
          <div className="pain-slider">
            <Form.Range required min="0" max="100" step="1" style={{ border: error && values.saneScore === ""? "2px solid red" : "" }} value={values.saneScore} onChange={(event) => handleFormData("sane", "saneScore", event.target.value)} />
            <Form.Control required type="number" min="0" max="100" step="1" value={values.saneScore} onChange={(event) => handleFormData("sane", "saneScore", event.target.value.trim())}/>
          </div>
          {error && values.saneScore === "" ? (<Form.Text style={{ color: "red" }}>Gelieve een waarde te selecteren op de schaal</Form.Text>) : null}
      </Form.Group>
    </div>
  );
};

export default SANE;