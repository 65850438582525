import { React, useState, useEffect } from "react"
import { Form, Button, Card } from "react-bootstrap"
import axios from "axios"
import { Oval } from "react-loader-spinner"
import { BASE_URL } from "../../utils/constants.js"

import "./Login.scss"

const Login = ({ logUserIn }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showLogin, setShowLogin] = useState(false)
  const [wrongLoginCredentials, setWrongLoginCredentials] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const user = localStorage.getItem("userInfo")
    if(user) {
      logUserIn(JSON.parse(user))
    }
    else(
      setShowLogin(true)
    )
  }, []);


  const submitHandler = async event => {
    event.preventDefault()
    if(username === "" || password === "") {
      setError(true)
      return 
    }
    setError(false)
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/user/auth`, {
        username: username,
        password: password,
      }, {headers:{"Content-Type" : "application/json"}})
      if(response.data.success) {
        logUserIn(response.data.user)
        localStorage.setItem('userInfo', JSON.stringify(response.data.user))
      }
    }
    catch(error) {
      console.log(error)
      if(error.response && error.response.status === 401) {
        setWrongLoginCredentials(true)
      }
      // switch to error screen
    }
  }

  return (
    <div className="login">

      {showLogin? (<Form noValidate onSubmit={submitHandler}>
      <Card>
        <Card.Body>
        <h1 className="logo">OTOS</h1>
        <p>Login to your account</p>      
        {/* {error && <Message variant="danger">{error}</Message>} */}
        {/* {loading && <Loader />} */}
          <Form.Group className="form-group">
            {/* <Form.Label>username Address</Form.Label> */}
            <Form.Control
              style={{ border: (error && username === "") || wrongLoginCredentials ? "2px solid red" : "" }}
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => {setWrongLoginCredentials(false); setUsername(e.target.value)}}
            ></Form.Control>
            {wrongLoginCredentials? <div className="text-danger"><small>Wrong username or password</small></div> : null}
            {error && username === ""? <div className="text-danger"><small>Please provide your username</small></div> : null}
            <Form.Control.Feedback type="valid">Wrong username or password</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="form-group">
            {/* <Form.Label>Password</Form.Label> */}
            <Form.Control
              style={{ border: (error && password === "") || wrongLoginCredentials ? "2px solid red" : "" }}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {setWrongLoginCredentials(false); setPassword(e.target.value)}}
            ></Form.Control>
            {wrongLoginCredentials? <div className="text-danger"><small>Wrong username or password</small></div> : null}
            {error && password === ""? <div className="text-danger"><small>Please provide your password</small></div> : null}
          </Form.Group>
          <Button type="submit" variant="primary">
            Login
          </Button>
          </Card.Body>
          
          </Card>
        </Form>) : (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        )}
    </div>
  );
}

export default Login