import React from "react";
import { Form } from "react-bootstrap";

import './StandardPreOp.scss';

const StandardPreOp = ({ handleFormData, values }) => {
  return (
    <div className="self-assessment-test standard-preop">
      <p className="form-title">Standaard Pre-operatief Formulier</p>
      <Form.Group className="form-group">
        <Form.Label>1. Verwacht u een afname van de pijn als direct resultaat na de geplande operatie/behandeling?</Form.Label>
        <Form.Select required value={values.spreop.spreopQuestion1} onChange={(event) => handleFormData("spreop", "spreopQuestion1", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
          required
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Gelieve een keuze te maken
        </Form.Control.Feedback>
      </Form.Group>    

      <Form.Group className="form-group">
        <Form.Label>2. Verwacht u een verbetering qua beweging en kracht van het aangedane gewricht als direct resultaat na de geplande operatie/behandeling?</Form.Label>
        <Form.Select required value={values.spreop.spreopQuestion2} onChange={(event) => handleFormData("spreop", "spreopQuestion2", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Gelieve een keuze te maken
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>3. Verwacht u de normale activiteiten in het dagelijks leven weer op te kunnen pakken als direct resultaat na de geplande operatie/behandeling?</Form.Label>
        <Form.Select required value={values.spreop.spreopQuestion3} onChange={(event) => handleFormData("spreop", "spreopQuestion3", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
          Gelieve een keuze te maken
        </Form.Control.Feedback>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>4. Verwacht u de normale functioneren in sportieve activiteiten weer op te kunnen pakken als direct resultaat na de geplande operatie/behandeling?</Form.Label>
        <Form.Select required value={values.spreop.spreopQuestion4} onChange={(event) => handleFormData("spreop", "spreopQuestion4", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
          <option value="Niet van toepassing - Ik doe niet aan sport">Niet van toepassing - Ik doe niet aan sport</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Gelieve een keuze te maken
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export default StandardPreOp