import React from "react";
import { Form } from "react-bootstrap";

import './VR12.scss';

const VR12 = ({ handleFormData, values }) => {
  return (
    <div className="self-assessment-test ases">
      <p className="form-title">VR12</p>      
      <p className="form-sub-title">
        Deze vragenlijst vraagt naar uw mening over uw gezondheid.
        De informatie helpt u bijhouden hoe u zich voelt en hoe goed u in staat bent om uw normale activiteiten uit te voeren. 
        Beantwoord elke vraag door het antwoord te markeren zoals aangegeven.
        Indien u niet zeker bent over het antwoord op een vraag, kies dan het antwoord dat volgens u het beste is
      </p>      
      <Form.Group className="form-group">
        <Form.Label>1. Hoe beoordeelt u uw gezondheid over het algemeen?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question1} onChange={(event) => handleFormData("vr12", "vr12Question1", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Uitstekend">Uitstekend</option>
          <option value="Zeer goed">Zeer goed</option>
          <option value="Goed">Goed</option>
          <option value="Redelijk">Redelijk</option>
          <option value="Slecht">Slecht</option>
        </Form.Select>
      </Form.Group>

      <p className="form-sub-title">
        De volgende 2 vragen gaan over activiteiten die u op een normale dag uitvoert.
        Beperkt uw gezondheid u nu in deze activiteiten? Zo ja, in welke mate?
      </p>      

      <Form.Group className="form-group">
        <Form.Label>2. Gematigde activiteiten, zoals een tafel verplaatsen, een stofzuiger voortduwen, bowlen of golfen?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question2} onChange={(event) => handleFormData("vr12", "vr12Question2", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja heel erg">Ja heel erg</option>
          <option value="Ja een beetje">Ja een beetje</option>
          <option value="Nee, helemaal niet">Nee, helemaal niet</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>3. Traplopen?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question3} onChange={(event) => handleFormData("vr12", "vr12Question3", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja heel erg">Ja heel erg</option>
          <option value="Ja een beetje">Ja een beetje</option>
          <option value="Nee, helemaal niet">Nee, helemaal niet</option>
          </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>4. Hebt u de afgelopen 4 weken een van de volgende problemen ondervonden in uw werk of bij andere regelmatige dagelijkse activiteiten als gevolg van uw lichamelijke gezondheid? U hebt minder bereikt dan u zou willen?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question4} onChange={(event) => handleFormData("vr12", "vr12Question4", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Nee, nooit">Nee, nooit</option>
          <option value="Ja, zelden">Ja, zelden</option>
          <option value="Ja, soms">Ja, soms</option>
          <option value="Ja, bijna altijd">Ja, bijna altijd</option>
          <option value="Ja, voortdurend">Ja, voortdurend</option>
        </Form.Select>
      </Form.Group>
      
      <Form.Group className="form-group">
        <Form.Label>5. U werd beperkt in het soort werk of andere activiteiten.</Form.Label>
        <Form.Select required value={values.vr12.vr12Question5} onChange={(event) => handleFormData("vr12", "vr12Question5", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Nee, nooit">Nee, nooit</option>
          <option value="Ja, zelden">Ja, zelden</option>
          <option value="Ja, soms">Ja, soms</option>
          <option value="Ja, bijna altijd">Ja, bijna altijd</option>
          <option value="Ja, voortdurend">Ja, voortdurend</option>
        </Form.Select>
      </Form.Group>
      
      
      <Form.Group className="form-group">
        <Form.Label>6. U hebt minder bereikt dan u zou willen.</Form.Label>
        <Form.Select required value={values.vr12.vr12Question6} onChange={(event) => handleFormData("vr12", "vr12Question6", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Nee, nooit">Nee, nooit</option>
          <option value="Ja, zelden">Ja, zelden</option>
          <option value="Ja, soms">Ja, soms</option>
          <option value="Ja, bijna altijd">Ja, bijna altijd</option>
          <option value="Ja, voortdurend">Ja, voortdurend</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>7. U voerde uw werk of andere activiteiten niet zo zorgvuldig uit als normaal.</Form.Label>
        <Form.Select required value={values.vr12.vr12Question7} onChange={(event) => handleFormData("vr12", "vr12Question7", event.target.value)}>
            <option value="" hidden>Maak een keuze</option>
            <option value="Nee, nooit">Nee, nooit</option>
            <option value="Ja, zelden">Ja, zelden</option>
            <option value="Ja, soms">Ja, soms</option>
            <option value="Ja, bijna altijd">Ja, bijna altijd</option>
            <option value="Ja, voortdurend">Ja, voortdurend</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>8. In welke mate hebt u de afgelopen 4 weken pijn ondervonden tijdens uw normale werk (zowel werk buitenshuis als huishoudelijk werk)?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question8} onChange={(event) => handleFormData("vr12", "vr12Question8", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Helemaal niet">Helemaal niet</option>
          <option value="Een klein beetje">Een klein beetje</option>
          <option value="Matig">Matig</option>
          <option value="Heel veel">Heel veel</option>
          <option value="Extreem veel">Extreem veel</option>
        </Form.Select>
      </Form.Group>
    
      <p className="form-sub-title">De volgende 4 vragen gaan over hoe u zich voelt en hoe het u de afgelopen 4 weken is vergaan. Voorzie elke vraag van het antwoord dat uw gevoel van de afgelopen periode het beste beschrijft.</p>      

      <Form.Group className="form-group">
        <Form.Label>9. Voelde u zich kalm en tevreden?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question9} onChange={(event) => handleFormData("vr12", "vr12Question9", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voortdurend">Voortdurend</option>
          <option value="Bijna altijd">Bijna altijd</option>
          <option value="Vaak">Vaak</option>
          <option value="Soms">Soms</option>
          <option value="Zelden">Zelden</option>
          <option value="Nooit">Nooit</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>10. Voelde u zich zeer energiek?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question10} onChange={(event) => handleFormData("vr12", "vr12Question10", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voortdurend">Voortdurend</option>
          <option value="Bijna altijd">Bijna altijd</option>
          <option value="Vaak">Vaak</option>
          <option value="Soms">Soms</option>
          <option value="Zelden">Zelden</option>
          <option value="Nooit">Nooit</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>11. Voelde u zich neerslachtig en gedeprimeerd?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question11} onChange={(event) => handleFormData("vr12", "vr12Question11", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voortdurend">Voortdurend</option>
          <option value="Bijna altijd">Bijna altijd</option>
          <option value="Vaak">Vaak</option>
          <option value="Soms">Soms</option>
          <option value="Zelden">Zelden</option>
          <option value="Nooit">Nooit</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>12. Hoe vaak heeft uw lichamelijke gezondheid of emotionele problemen de afgelopen 4 weken invloed gehad op uw sociale activiteiten (zoals het bezoeken van vrienden, bekenden, enz.)?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question12} onChange={(event) => handleFormData("vr12", "vr12Question12", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Voortdurend">Voortdurend</option>
          <option value="Bijna altijd">Bijna altijd</option>
          <option value="Vaak">Vaak</option>
          <option value="Soms">Soms</option>
          <option value="Zelden">Zelden</option>
          <option value="Nooit">Nooit</option>
        </Form.Select>
      </Form.Group>
      
      <p className="form-sub-title">Nu willen we u een paar vragen stellen over hoe uw gezondheid mogelijk is veranderd.</p>      
    
      <Form.Group className="form-group">
        <Form.Label>13. Hoe zou u nu, vergeleken met een jaar geleden, uw lichamelijke gezondheid over het algemeen beoordelen?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question13} onChange={(event) => handleFormData("vr12", "vr12Question13", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Veel beter">Veel beter</option>
          <option value="Iets beter">Iets beter</option>
          <option value="Ongeveer hetzelfde">Ongeveer hetzelfde</option>
          <option value="Iets slechter">Iets slechter</option>
          <option value="Veel slechter">Veel slechter</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>14. Hoe zou u nu, vergeleken met een jaar geleden, uw emotionele problemen (bijvoorbeeld angstige, depressieve of prikkelbare gevoelens) beoordelen?</Form.Label>
        <Form.Select required value={values.vr12.vr12Question14} onChange={(event) => handleFormData("vr12", "vr12Question14", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Veel beter">Veel beter</option>
          <option value="Iets beter">Iets beter</option>
          <option value="Ongeveer hetzelfde">Ongeveer hetzelfde</option>
          <option value="Iets slechter">Iets slechter</option>
          <option value="Veel slechter">Veel slechter</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}

export default VR12