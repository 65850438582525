import React from "react";
import { Form } from "react-bootstrap";

import './StandardEarlyPostOp.scss';

const StandardEarlyPostOp = ({ handleFormData, values }) => {
  return (
    <div className="self-assessment-test standard-early-postop">
      <p className="form-title">Standaard Vroeg Post-operatief Formulier</p>
      <Form.Group className="form-group">
        <Form.Label>1. Gebruikt u pijnmedicatie op recept vanwege uw recente operatie/behandeling?</Form.Label>
        <Form.Select required value={values.sepostop.sepostopQuestion1} onChange={(event) => handleFormData("sepostop", "sepostopQuestion1", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>    

      <Form.Group className="form-group">
        <Form.Label>2. Gebruikt u vrij verkrijgbare pijnmedicatie (Aspirine, Advil, Tylenol enz.) vanwege uw recente operatie/behandeling?</Form.Label>
        <Form.Select required value={values.sepostop.sepostopQuestion2} onChange={(event) => handleFormData("sepostop", "sepostopQuestion2", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>3. Gebruikt u slaapmedicatie vanwege het ongemak door uw recente operatie/behandeling?</Form.Label>
        <Form.Select required value={values.sepostop.sepostopQuestion3} onChange={(event) => handleFormData("sepostop", "sepostopQuestion3", event.target.value)}>
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
          </Form.Select>
      </Form.Group>
    </div>
  );
}

export default StandardEarlyPostOp