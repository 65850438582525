import React from 'react'

import "./FormCompletion.scss"

const FormCompletion = () => {
  return (
    <div className="self-assessment-test formCompletion">
      <p className="form-title">Bedankt voor uw input!</p>   
    </div>
  )
}

export default FormCompletion