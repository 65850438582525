import React, { useEffect, useState} from 'react'
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { FaBell, FaBoxArchive } from "react-icons/fa6";
import { MdExitToApp } from "react-icons/md";
import { Table, Button } from 'react-bootstrap'
import { Oval } from "react-loader-spinner"
import Modal from 'react-bootstrap/Modal'
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import _ from "underscore"
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

import "./Notifications.scss"

const studyMap = {
  "shoulder arthroscopy": "SAs",
  "shoulder arthroplasty": "SAp",
  "elbow": "E"
}

const Notifications = ({ user, logUserOut, setNumberOfNotifications }) => {
  const [loading, setLoading] = useState(false)
  const [surveyOutliers, setSurveyOutliers] = useState([])
  const [showModal, setShowModal] = useState("");
  const [outlierToArchive, setOutlierToArchive] = useState({})
  const [sorting, setSorting] = useState({ field: 'alertDate', ascending: true })
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  }

  useEffect(() => {
    const getSurveyOutliers = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${BASE_URL}/api/v1/self-assessment/outliers/`, config)
        const surveyOutliers = response.data.surveyOutliers
        setNumberOfNotifications(surveyOutliers.length)
        setSurveyOutliers(surveyOutliers)
        setLoading(false)
      }
      catch (error) {
        console.log(error)
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
          logUserOut()
        }
      }
    }
    getSurveyOutliers()
  }, [])

  useEffect(() => {
    setSurveyOutliers(sorting.ascending? _.sortBy(surveyOutliers, sorting.field) : _.sortBy(surveyOutliers, sorting.field).reverse())
  }, [sorting]);

  const handleArchive = async () => {
    try {
      const body = {
        vasId: outlierToArchive.vasId
      }
      setShowModal(false)
      await axios.post(`${BASE_URL}/api/v1/self-assessment/outliers/handle`, body, config)
      setSurveyOutliers(surveyOutliers.filter(surveyOutlier => surveyOutlier.vasId !== outlierToArchive.vasId))
      setNumberOfNotifications(surveyOutliers.length)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }


  return (
    <div className="notifications">
      {loading ? (<Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
        ) : (
        <>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Please confirm action</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to archive outlier (VAS = {outlierToArchive.vasScore}) from {outlierToArchive.customPatientId}?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                No
              </Button>
              <Button variant="success" onClick={handleArchive}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="notifications-header">
            <h2 className="logo"><FaBell /> Notifications</h2> 
          </div>
          <div className='notifications-table'>
            <Table striped bordered={false} hover>
              <thead>
                <tr>
                  <th className='alert-date' onClick={() => setSorting({field: "alertDate", ascending: !sorting.ascending})}>Alert Date {sorting.field === "alertDate"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='custom-id' onClick={() => setSorting({field: "customPatientId", ascending: !sorting.ascending})}>Custom Patient ID {sorting.field === "customPatientId"? sorting.ascending? <AiFillCaretUp/>: <AiFillCaretDown/> : ""}</th>
                  <th className='study'>Study</th>
                  <th className='alert-details'>Alert Details</th>
                  <th className='milestone'>Milestone</th>
                  <th className='actions'>Actions</th>
                  <th className='action-buttons'></th>
                </tr>
              </thead>
              <tbody>
                {surveyOutliers.length > 0 ? surveyOutliers.map((surveyOutlier, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(surveyOutlier.alertDate).format("DD/MM/YYYY")}</td>
                      <td>{surveyOutlier.customPatientId}</td>
                      <td>{studyMap[surveyOutlier.study]} ({surveyOutlier.treatmentSide[0]})</td>
                      <td>VAS = {surveyOutlier.vasScore}</td>
                      <td>{surveyOutlier.stage}</td>
                      <td>
                        <Link to={`/patient/${surveyOutlier.customPatientId}/event/${surveyOutlier.eventId}/${surveyOutlier.study.replace(" ", "-")}/${surveyOutlier.treatmentSide.toLowerCase()}/survey/${surveyOutlier.stage.replace(" ", "-")}/edit`}>View Survey</Link></td>
                      <td className='action-buttons'>
                        <div>
                          <Button variant="success" onClick={() => {setOutlierToArchive(surveyOutliers[index]); setShowModal(true)}}><FaBoxArchive /></Button>
                          <Button variant="primary" onClick={() => navigate(`/patients/${surveyOutlier.customPatientId}`)}><MdExitToApp /></Button>
                        </div>
                      </td>
                    </tr>
                  )
                }) : <tr><td colSpan={100} style={{textAlign: "center"}}>No active notifications</td></tr>}
              </tbody>
            </Table>
          </div>
        </>)}    
    </div>
  )
}

export default Notifications