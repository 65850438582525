import React from 'react'
import { Card } from "react-bootstrap";

import "./SurveyAlreadyCompleted.scss"

const SurveyAlreadyCompleted = () => {
  return (
    <div className="terms-and-conditions">
      <Card.Body>
          <Card.Title>
            U heeft deze zelfevaluatie reeds ingevuld
          </Card.Title>
        </Card.Body>
    </div>
  )
}

export default SurveyAlreadyCompleted