import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select';
import { RxCross1 } from "react-icons/rx";

import "./OperationsAndImplants.scss"

const applicationOrBraceOptions = [
  {value: "Sling", label: "Sling"},
  {value: "Abducted sling", label: "Abducted sling"},
  {value: "Neutral wedge", label: "Neutral wedge"},
]

const hemiarthroplastyProcedureDetailOptions = [
  {value: "Biologic - Humeral", label: "Biologic - Humeral"},
  {value: "Biologic - Glenoid", label: "Biologic - Glenoid"},
  {value: "Glenoid untouched", label: "Glenoid untouched"},
  {value: "Glenoid reaming (Ream and run)", label: "Glenoid reaming (Ream and run)"},
]

const stemDetailOptions = [
  {value: "Stemless", label: "Stemless"}, 
  {value: "Short stem", label: "Short stem"}, 
  {value: "Standard length", label: "Standard length"}, 
  {value: "Long stem", label: "Long stem"}, 
  {value: "Cemented", label: "Cemented"}, 
  {value: "Pressfit", label: "Pressfit"}, 
]

const humeralHeadResurfacingTypeOptions = [
  {value: "Focal", label: "Focal"}, 
  {value: "Complete", label: "Complete"}, 
]

const humeralHeadResurfacingConcomitantProceduresOptions = [
  {value: "SCR", label: "SCR"}, 
  {value: "Glenoid resurfacing - Biologic", label: "Glenoid resurfacing - Biologic"}, 
  {value: "Glenoid resurfacing - Polyethylene", label: "Glenoid resurfacing - Polyethylene"}, 
]

const glenoidDeficiencyOptions = [
  {value: "Anterior", label: "Anterior"}, 
  {value: "Posterior", label: "Posterior"}, 
  {value: "Central", label: "Central"}, 
  {value: "Superior", label: "Superior"}, 
]

const clavicleFractureProcedureOptions = [
  {value: "Pin", label: "Pin"},
  {value: "Plate", label: "Plate"},
  {value: "Intramedullary fixation", label: "Intramedullary fixation"},
  {value: "Other type fixation", label: "Other type fixation"},
  {value: "Autograft bone", label: "Autograft bone"},
  {value: "Allograft bone", label: "Allograft bone"},
  {value: "Synthetic bone void filler", label: "Synthetic bone void filler"},
]

const clavicleDeviceManufacturereOptions = [
  {value: "Arthrex - Mid Shaft/Central Third plate", label: "Arthrex - Mid Shaft/Central Third plate"},
  {value: "Arthrex - Distal plate", label: "Arthrex - Distal plate"},
  {value: "Arthrex - Distal plate with AC Dogbone", label: "Arthrex - Distal plate with AC Dogbone"},
  {value: "Arthrex - other type implant", label: "Arthrex - other type implant"},
]

const greaterTuberosityFractureProcedureOptions = [
  {value: "Screw", label: "Screw"},
  {value: "SpeedBridge", label: "SpeedBridge"},
  {value: "SpeedFix", label: "SpeedFix"},
  {value: "Other type fixation", label: "Other type fixation"},
]

const proximalHumerusFractureOptions = [
  {value: "Percutaneous pinning", label: "Percutaneous pinning"},
  {value: "Plate", label: "Plate"},
  {value: "IM implant", label: "IM implant"},
  {value: "Hemiarthroplasty for fracture", label: "Hemiarthroplasty for fracture"},
  {value: "Cerclage", label: "Cerclage"},
  {value: "Reverse Shoulder Arthroplasty", label: "Reverse Shoulder Arthroplasty"},
]

const proximalHumerusFractureDeviceManufacturerOptions = [
  {value: "Arthrex - Percutaneous pins and screws", label: "Arthrex - Percutaneous pins and screws"},
  {value: "Arthrex - Humeral SuturePlate", label: "Arthrex - Humeral SuturePlate"},
  {value: "Arthrex - Univers Fracture ", label: "Arthrex - Univers Fracture "},
  {value: "Arthrex - pins/screws", label: "Arthrex - pins/screws"},
]

const glenoidFractureProcedureOptions = [
  {value: "Excision", label: "Excision"}, 
  {value: "ORIF", label: "ORIF"}, 
  {value: "ARIF", label: "ARIF"}, 
  {value: "Screw", label: "Screw"}, 
  {value: "Anchor", label: "Anchor"}, 
]

const OperationsAndImplants = ({ form, formData, arrayIndex, onChangeHandler, onRemoveHandler }) => {
  switch(form) {
    case "approachSupscapRepairAndApplicationBraces": {
      return (
        <div className="operations-approach-supscap-repair-and-application-braces">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Approach, Supscap Repair and Application/Braces
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Approach</Form.Label>
                      <Form.Select
                        value={formData.approach}
                        onChange={(e) => {onChangeHandler(arrayIndex, "approach", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Lesser tuberosity osteotomy">Lesser tuberosity osteotomy</option>
                        <option value="Subscapularis Tenotomy">Subscapularis Tenotomy</option>
                        <option value="Subscapularis Peel">Subscapularis Peel</option>
                        <option value="Kessel's (superior)">Kessel's (superior)</option>
                        <option value="Humeral Split (revision)">Humeral Split (revision)</option>
                        <option value="Subscapularis Sparing (through rotator cuff interval)">Subscapularis Sparing (through rotator cuff interval)</option>
                        <option value="Subscapularis Sparing (through partial suscap take down)">Subscapularis Sparing (through partial suscap take down)</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Subscap Repair Method</Form.Label>
                      <Form.Select
                        value={formData.subscapRepairMethod}
                        onChange={(e) => {onChangeHandler(arrayIndex, "subscapRepairMethod", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="N/A">N/A</option>
                        <option value="Suture and anchors (SpeedScap)">Suture and anchors (SpeedScap)</option>
                        <option value="Suture through bone tunnels">Suture through bone tunnels</option>
                        <option value="Suture through bone and around prosthesis">Suture through bone and around prosthesis</option>
                        <option value="Suture through bone and around prosthesis (FiberTape Tendon Compression Bridge (TCB))">Suture through bone and around prosthesis (FiberTape Tendon Compression Bridge (TCB))</option>
                        <option value="Suture through bone and plate">Suture through bone and plate</option>
                        <option value="Suture through bone and prosthesis suture hole (Apex)">Suture through bone and prosthesis suture hole (Apex)</option>
                        <option value="Suture only">Suture only</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Application/Braces</Form.Label>
                      <Select
                        isMulti
                        value={applicationOrBraceOptions.filter(option => formData.applicationOrBrace.split(",").includes(option.value))}
                        name="applicationOrBrace"
                        options={applicationOrBraceOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "applicationOrBrace", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "hemiarthroplasty": {
      return (
        <div className="operations-hemiarthroplasty">
          <Accordion>
            <Accordion.Item eventKey="0">
            <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Hemiarthroplasty
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedures</Form.Label>
                      <Select
                        isMulti
                        value={hemiarthroplastyProcedureDetailOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={hemiarthroplastyProcedureDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Stem Details</Form.Label>
                      <Select
                        isMulti
                        value={stemDetailOptions.filter(option => formData.stemDetails.split(",").includes(option.value))}
                        name="stemDetails"
                        options={stemDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "stemDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Stem Size</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.size}
                        onChange={(e) => {onChangeHandler(arrayIndex, "size", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Head Size Width</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Head Size Height</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.height}
                        onChange={(e) => {onChangeHandler(arrayIndex, "height", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.titaniumHead}
                        label="Titanium head for metal allergy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "titaniumHead", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Cage Screw</Form.Label>
                      <Form.Select
                        value={formData.cageScrew}
                        onChange={(e) => {onChangeHandler(arrayIndex, "cageScrew", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                        <option value="Extra Large">Extra Large</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Trunion Size</Form.Label>
                      <Form.Select
                        value={formData.trunionSize}
                        onChange={(e) => {onChangeHandler(arrayIndex, "trunionSize", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="39">39</option>
                        <option value="41">41</option>
                        <option value="43">43</option>
                        <option value="45">45</option>
                        <option value="47">47</option>
                        <option value="49">49</option>
                        <option value="51">51</option>
                        <option value="53">53</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Bone Graft</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bone allograft">Bone allograft</option>
                        <option value="Bone autograft">Bone autograft</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Apex">Arthrex - Apex</option>
                        <option value="Arthrex - Univers II">Arthrex - Univers II</option>
                        <option value="Arthrex - Univers Fracture Stem">Arthrex - Univers Fracture Stem</option>
                        <option value="Arthrex - Univers CA head">Arthrex - Univers CA head</option>
                        <option value="Arthrex - Eclipse">Arthrex - Eclipse</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "humeralHeadResurfacing": {
      return (
        <div className="operations-humeral-head-resurfacing">
          <Accordion>
            <Accordion.Item eventKey="0">
            <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Humeral Head Resurfacing
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Type</Form.Label>
                      <Select
                        isMulti
                        value={humeralHeadResurfacingTypeOptions.filter(option => formData.type.split(",").includes(option.value))}
                        name="type"
                        options={humeralHeadResurfacingTypeOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "type", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Focal resurfacing size</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.size}
                        onChange={(e) => {onChangeHandler(arrayIndex, "size", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Complete head size width</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Complete head size height</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.height}
                        onChange={(e) => {onChangeHandler(arrayIndex, "height", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Eclipse">Arthrex - Eclipse</option>
                        <option value="Arthrex - Partial Eclipse">Arthrex - Partial Eclipse</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                   <Col>
                      <Form.Label>Procedures</Form.Label>
                      <Select
                        isMulti
                        value={humeralHeadResurfacingConcomitantProceduresOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={humeralHeadResurfacingConcomitantProceduresOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "biologicTotalShoulder": {
      return (
        <div className="operations-biologic-total-shoulder">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Biologic Total Shoulder
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic">Arthroscopic</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Concomitant Procedures</Form.Label>
                      <Form.Select
                        value={formData.procedures}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedures", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="SCR">SCR</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "totalShoulderReplacement": {
      return (
        <div className="operations-total-shoulder-replacement">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                      Total Shoulder Replacement
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Stem Details</Form.Label>
                      <Select
                        isMulti
                        value={stemDetailOptions.filter(option => formData.stemDetails.split(",").includes(option.value))}
                        name="stemDetails"
                        options={stemDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "stemDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Stem Size</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.size}
                        onChange={(e) => {onChangeHandler(arrayIndex, "size", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Head Size Width</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.width}
                        onChange={(e) => {onChangeHandler(arrayIndex, "width", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Head Size Height</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.height}
                        onChange={(e) => {onChangeHandler(arrayIndex, "height", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.titaniumHead}
                        label="Titanium head for metal allergy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "titaniumHead", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Size of Glenoid</Form.Label>
                      <Form.Select
                        value={formData.glenoid}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoid", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                        <option value="Extra Large">Extra Large</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Size</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.glenoidSize}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidSize", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid Deficiency</Form.Label>
                      <Select
                        isMulti
                        value={glenoidDeficiencyOptions.filter(option => formData.glenoidDeficiency.split(",").includes(option.value))}
                        name="glenoidDeficiency"
                        options={glenoidDeficiencyOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "glenoidDeficiency", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Walch Classification</Form.Label>
                      <Form.Select
                        value={formData.walchClassification}
                        onChange={(e) => {onChangeHandler(arrayIndex, "walchClassification", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Type A1">Type A1</option>
                        <option value="Type A2">Type A2</option>
                        <option value="Type B1">Type B1</option>
                        <option value="Type B2">Type B2</option>
                        <option value="Type B3">Type B3</option>
                        <option value="Type C">Type C</option>
                        <option value="Type D">Type D</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid Retroversion</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={30}
                        value={formData.glenoidRetroversion}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidRetroversion", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Bone Graft</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bone allograft">Bone allograft</option>
                        <option value="Bone autograft">Bone autograft</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Implant Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Apex">Arthrex - Apex</option>
                        <option value="Arthrex - Univers II">Arthrex - Univers II</option>
                        <option value="Arthrex - Eclipse">Arthrex - Eclipse</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Implant Type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="VaultLock">VaultLock</option>
                        <option value="Pegged Glenoid">Pegged Glenoid</option>
                        <option value="Keeled Glenoid">Keeled Glenoid</option>
                        <option value="Convertible Universal Glenoid">Convertible Universal Glenoid</option>
                        <option value="Metal back Glenoid">Metal back Glenoid</option>
                        <option value="Augmented Glenoid">Augmented Glenoid</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Convertible Universal Glenoid details</Form.Label>
                      <Form.Select
                        value={formData.convertibleUniversalGlenoid}
                        onChange={(e) => {onChangeHandler(arrayIndex, "convertibleUniversalGlenoid", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="With standard poly">With standard poly</option>
                        <option value="With +1 mm poly">With +1 mm poly</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Offset</Form.Label>
                      <Form.Select
                        value={formData.humeralOffset}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralOffset", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="0mm">0mm</option>
                        <option value="2mm posterior">2mm posterior</option>
                        <option value="2mm anterior">2mm anterior</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Cage Screw</Form.Label>
                      <Form.Select
                        value={formData.cageScrew}
                        onChange={(e) => {onChangeHandler(arrayIndex, "cageScrew", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                        <option value="Extra Large">Extra Large</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Trunion Size</Form.Label>
                      <Form.Select
                        value={formData.trunionSize}
                        onChange={(e) => {onChangeHandler(arrayIndex, "trunionSize", e.target.value)}}
                        >
                        <option value="" hidden>Select an option</option>
                        <option value="37">37</option>
                        <option value="39">39</option>
                        <option value="41">41</option>
                        <option value="43">43</option>
                        <option value="45">45</option>
                        <option value="47">47</option>
                        <option value="49">49</option>
                        <option value="51">51</option>
                        <option value="53">53</option>
                        <option value="55">55</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>3D Planning</Form.Label>
                      <Form.Select
                        value={formData.planning3d}
                        onChange={(e) => {onChangeHandler(arrayIndex, "planning3d", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex 3D planning used without instrumentation">Arthrex 3D planning used without instrumentation</option>
                        <option value="Arthrex 3D planning used with instrumentation">Arthrex 3D planning used with instrumentation</option>
                        <option value="Other 3D planning used without instrumentation">Other 3D planning used without instrumentation</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Concomitant Procedures</Form.Label>
                      <Form.Select
                        value={formData.procedures}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedures", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="SCR">SCR</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Cerclage used</Form.Label>
                      <Form.Select
                        value={formData.cerclage}
                        onChange={(e) => {onChangeHandler(arrayIndex, "cerclage", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberTape Cerclage">FiberTape Cerclage</option>
                        <option value="Metal cable/wire">Metal cable/wire</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "inverseReverseTotalShoulderReplacement": {
      return (
        <div className="operations-inverse-reverse-total-shoulder-replacement">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Inverse/Reverse Total Shoulder Replacement
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenosphere Size</Form.Label>
                      <Form.Select
                        value={formData.size}
                        onChange={(e) => {onChangeHandler(arrayIndex, "size", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="32 mm">32 mm</option>
                        <option value="33 mm">33 mm</option>
                        <option value="34 mm">34 mm</option>
                        <option value="35 mm">35 mm</option>
                        <option value="36 mm">36 mm</option>
                        <option value="37 mm">37 mm</option>
                        <option value="38 mm">38 mm</option>
                        <option value="39 mm">39 mm</option>
                        <option value="40 mm">40 mm</option>
                        <option value="41 mm">41 mm</option>
                        <option value="42 mm">42 mm</option>
                        <option value="45 mm">45 mm</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.titaniumHead}
                        label="Titanium head for metal allergy" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "titaniumHead", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Deficiency</Form.Label>
                      <Select
                        isMulti
                        value={glenoidDeficiencyOptions.filter(option => formData.glenoidDeficiency.split(",").includes(option.value))}
                        name="glenoidDeficiency"
                        options={glenoidDeficiencyOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "glenoidDeficiency", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                      <Form.Label>Walch Classification</Form.Label>
                      <Form.Select
                        value={formData.walchClassification}
                        onChange={(e) => {onChangeHandler(arrayIndex, "walchClassification", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Walch A1">Walch A1</option>
                        <option value="Walch A2">Walch A2</option>
                        <option value="Walch B1">Walch B1</option>
                        <option value="Walch B2">Walch B2</option>
                        <option value="Walch B3">Walch B3</option>
                        <option value="Walch C">Walch C</option>
                        <option value="Walch D">Walch D</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid Retroversion</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={60}
                        value={formData.glenoidRetroversion}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidRetroversion", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Bone Graft</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bone allograft">Bone allograft</option>
                        <option value="Bone autograft">Bone autograft</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                        <option value="Materialise glenius base plate and glenosphere">Materialise glenius base plate and glenosphere</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                   <Col>
                      <Form.Label>Lateral Offset</Form.Label>
                      <Form.Select
                        value={formData.lateralOffset}
                        onChange={(e) => {onChangeHandler(arrayIndex, "lateralOffset", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.eccentric}
                        label="Eccentric" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "eccentric", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                   <Col>
                      <Form.Label>Non-augmented Baseplate size/type</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                        <option value="MGS- 24 Diameter">MGS- 24 Diameter</option>
                        <option value="MGS- 24 + 2">MGS- 24 + 2</option>
                        <option value="MGS- 24 + 4">MGS- 24 + 4</option>
                        <option value="MGS- 28 Diameter">MGS- 28 Diameter</option>
                        <option value="MGS- 28 + 2">MGS- 28 + 2</option>
                        <option value="MGS- 28 + 4">MGS- 28 + 4</option>  
                      </Form.Select>
                    </Col>
                   <Col>
                      <Form.Label>Augmented Baseplate Size/Type</Form.Label>
                      <Form.Select
                        value={formData.type2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Aug MGS 24 - 10° Full Wedge">Aug MGS 24 - 10° Full Wedge</option>
                        <option value="Aug MGS 24 - 20° Full Wedge">Aug MGS 24 - 20° Full Wedge</option>
                        <option value="Aug MGS 24 - 10° + 2 Full Wedge">Aug MGS 24 - 10° + 2 Full Wedge</option>
                        <option value="Aug MGS 24 - 20° + 2 Full Wedge">Aug MGS 24 - 20° + 2 Full Wedge</option>  
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Spacer Poly Size</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.spacerPolySize}
                        onChange={(e) => {onChangeHandler(arrayIndex, "spacerPolySize", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Metal Spacer Trays</Form.Label>
                      <Form.Select
                        value={formData.metalSpacerTrays}
                        onChange={(e) => {onChangeHandler(arrayIndex, "metalSpacerTrays", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="6 mm">6 mm</option>
                        <option value="9 mm">9 mm</option>
                        <option value="12 mm">12 mm</option>
                        <option value="15 mm">15 mm</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Central Fixation Type - MGS ONLY</Form.Label>
                      <Form.Select
                        value={formData.type3}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type3", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="15 mm Post">15 mm Post</option>
                        <option value="15 mm Screw">15 mm Screw</option>
                        <option value="20 mm Post">20 mm Post</option>
                        <option value="20 mm Screw">20 mm Screw</option>
                        <option value="25 mm Post">25 mm Post</option>
                        <option value="25 mm Screw">25 mm Screw</option>
                        <option value="30 mm Post">30 mm Post</option>
                        <option value="30 mm Screw">30 mm Screw</option>
                        <option value="35 mm Post">35 mm Post</option>
                        <option value="35 mm Screw">35 mm Screw</option>  
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                      <Form.Label>Stem Details</Form.Label>
                      <Select
                        isMulti
                        value={stemDetailOptions.filter(option => formData.stemDetails.split(",").includes(option.value))}
                        name="stemDetails"
                        options={stemDetailOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "stemDetails", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Stem Size</Form.Label>
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        max={10000}
                        value={formData.size2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "size2", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Cup</Form.Label>
                      <Form.Select
                        value={formData.humeralCup}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralCup", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Semiconstrained">Semiconstrained</option>
                        <option value="Constrained">Constrained</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Offset</Form.Label>
                      <Form.Select
                        value={formData.humeralOffset}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralOffset", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="0mm">0mm</option>
                        <option value="2mm posterior">2mm posterior</option>
                        <option value="2mm anterior">2mm anterior</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Inclination</Form.Label>
                      <Form.Select
                        value={formData.humeralInclination}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralInclination", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="135">135</option>
                        <option value="145">145</option>
                        <option value="155">155</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Version (Primary case)</Form.Label>
                      <Form.Select
                        value={formData.humeralVersion}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralVersion", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="0">0</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Version Change (Revision to Inverse)</Form.Label>
                      <Form.Select
                        value={formData.humeralVersion2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralVersion2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="-10">-10</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="-20">-20</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Implant Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex - Univers Revers">Arthrex - Univers Revers</option>
                        <option value="Arthrex - Univers Revers Apex (short stem)">Arthrex - Univers Revers Apex (short stem)</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.latTransfer}
                        label="Lat Transfer" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "latTransfer", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.osAcromiale}
                        label="OS Acromiale" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "osAcromiale", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>3D Planning</Form.Label>
                      <Form.Select
                        value={formData.planning3d}
                        onChange={(e) => {onChangeHandler(arrayIndex, "planning3d", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex 3D planning used without instrumentation">Arthrex 3D planning used without instrumentation</option>
                        <option value="Arthrex 3D planning used with instrumentation">Arthrex 3D planning used with instrumentation</option>
                        <option value="Other 3D planning used without instrumentation">Other 3D planning used without instrumentation</option>
                        <option value="Other 3D planning used wih instrumentation">Other 3D planning used wih instrumentation</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Cerclage used</Form.Label>
                      <Form.Select
                        value={formData.cerclage}
                        onChange={(e) => {onChangeHandler(arrayIndex, "cerclage", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberTape Cerclage">FiberTape Cerclage</option>
                        <option value="Metal cable/wire">Metal cable/wire</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "superiorCapsuleReconstruction": {
      return (
        <div className="operations-superior-capsule-reconstruction">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Superior Capsule Reconstruction (SCR)
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.open}
                        label="Open" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "open", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid side graft fixation</Form.Label>
                      <Form.Select
                        value={formData.glenoidSideGraftFixation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidSideGraftFixation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Medial Pulley - 2 SutureTaks">Medial Pulley - 2 SutureTaks</option>
                        <option value="Medial Pulley - 2 SutureTaks with PushLock(s)">Medial Pulley - 2 SutureTaks with PushLock(s)</option>
                        <option value="PASTA Bridge - SwiveLock with 2 SutureTaks">PASTA Bridge - SwiveLock with 2 SutureTaks</option>
                        <option value="2-3 SwiveLocks with LabralTape">2-3 SwiveLocks with LabralTape</option>
                        <option value="2-3 PushLocks with LabralTape">2-3 PushLocks with LabralTape</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon}
                        label="Tie any sutures from the glenoid anchor to the supraspinatus tendon?" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "tieSuturesFromTheGlenoidAnchorToTheSupraspinatusTendon", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral side graft fixation</Form.Label>
                      <Form.Select
                        value={formData.humeralSideGraftFixation}
                        onChange={(e) => {onChangeHandler(arrayIndex, "humeralSideGraftFixation", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="4 anchor knotless SpeedBridge">4 anchor knotless SpeedBridge</option>
                        <option value="6 anchor knotless SpeedBridge">6 anchor knotless SpeedBridge</option>
                        <option value="4 anchor SpeedBridge with double pulley">4 anchor SpeedBridge with double pulley</option>
                        <option value="SutureBridge with lateral SwiveLocks">SutureBridge with lateral SwiveLocks</option>
                        <option value="SutureBridge with lateral PushLocks">SutureBridge with lateral PushLocks</option>
                        <option value="Single row repair">Single row repair</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Graft Used</Form.Label>
                      <Form.Select
                        value={formData.graftUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "graftUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="ArthroFlex">ArthroFlex</option>
                        <option value="Xenograft-Arthrex DX Reinforcement Matrix">Xenograft-Arthrex DX Reinforcement Matrix</option>
                        <option value="Fascia Lata">Fascia Lata</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Arm position for measuring, positioning and securing graft</Form.Label>
                      <Form.Select
                        value={formData.armPositionForMeasuringPositioningAndSecuringGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "armPositionForMeasuringPositioningAndSecuringGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>              
                        <option value="0 degrees (arm at side)">0 degrees (arm at side)</option>
                        <option value="10 degrees of abduction">10 degrees of abduction</option>
                        <option value="20 degrees of abduction">20 degrees of abduction</option>
                        <option value="30 degrees of abduction">30 degrees of abduction</option>
                        <option value="40 degrees of abduction">40 degrees of abduction</option>
                        <option value="50 degrees of abduction">50 degrees of abduction</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.posteriorMarginConvergencePerformed}
                        label="Posterior margin convergence performed?" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "posteriorMarginConvergencePerformed", e.target.checked)}}
                      />
                    </Col>
                    <Col>
                      <Form.Check 
                        type="checkbox" 
                        checked={formData.anteriorMarginConvergencePerformed}
                        label="Anterior margin convergence performed?" 
                        onChange={(e) => {onChangeHandler(arrayIndex, "anteriorMarginConvergencePerformed", e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Concomitant Procedures</Form.Label>
                      <Form.Select
                        value={formData.procedures}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedures", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Partial RC Repair">Partial RC Repair</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid - 3.0 SutureTak (material)</Form.Label>
                      <Form.Select
                        value={formData.glenoidSutureTak}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidSutureTak", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid - Number of SutureTaks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={5}
                        value={formData.numberOfSutureTaksUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfSutureTaksUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid - 3.5 SwiveLock (material)</Form.Label>
                      <Form.Select
                        value={formData.glenoidSwiveLock}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidSwiveLock", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid - Suture used with SwiveLock</Form.Label>
                      <Form.Select
                        value={formData.sutureUsedWithSwiveLock}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureUsedWithSwiveLock", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid - Number of SwiveLocks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={5}
                        value={formData.numberOfSwiveLocksUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfSwiveLocksUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid - 2.9 PushLock (material)</Form.Label>
                      <Form.Select
                        value={formData.glenoidPushLock}
                        onChange={(e) => {onChangeHandler(arrayIndex, "glenoidPushLock", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                        <option value="LabralTape">LabralTape</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Glenoid - Suture used with PushLock</Form.Label>
                      <Form.Select
                        value={formData.sutureUsedWithPushLock}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureUsedWithPushLock", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid - Number of PushLocks used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={5}
                        value={formData.numberOfPushLocksUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfPushLocksUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Type of knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.typeOfKnotlessAnchor}
                        onChange={(e) => {onChangeHandler(arrayIndex, "typeOfKnotlessAnchor", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="4.75 SwiveLock">4.75 SwiveLock</option>
                        <option value="4.75 Double Loaded SwiveLock">4.75 Double Loaded SwiveLock</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.materialKnotlessAnchor}
                        onChange={(e) => {onChangeHandler(arrayIndex, "materialKnotlessAnchor", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Suture used with knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.sutureUsedWithKnotlessAnchor}
                        onChange={(e) => {onChangeHandler(arrayIndex, "sutureUsedWithKnotlessAnchor", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberTape">FiberTape</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={5}
                        value={formData.numberOfKnotlessAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfKnotlessAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Type of suture anchor </Form.Label>
                      <Form.Select
                        value={formData.typeOfSutureAnchor}
                        onChange={(e) => {onChangeHandler(arrayIndex, "typeOfSutureAnchor", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="5.5 Corkscrew">5.5 Corkscrew</option>
                        <option value="5.5 Corkscrew FT">5.5 Corkscrew FT</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Material suture anchor</Form.Label>
                      <Form.Select
                        value={formData.materialSutureAnchor}
                        onChange={(e) => {onChangeHandler(arrayIndex, "materialSutureAnchor", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bioabsorbable (PLLA, PLDLA, etc)">Bioabsorbable (PLLA, PLDLA, etc)</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="Metal">Metal</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Medial Row - Number of suture anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={5}
                        value={formData.numberOfSutureAnchorsUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfSutureAnchorsUsed", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Lateral Row - Type of knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.typeOfKnotlessAnchor2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "typeOfKnotlessAnchor2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="4.75 SwiveLock">4.75 SwiveLock</option>
                        <option value="5.5 SwiveLock">5.5 SwiveLock</option>
                        <option value="4.5 PushLock">4.5 PushLock</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Side Lateral Row - Material knotless anchor</Form.Label>
                      <Form.Select
                        value={formData.materialKnotlessAnchor2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "materialKnotlessAnchor2", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="BioComposite">BioComposite</option>
                        <option value="PEEK">PEEK</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Humeral Side Lateral Row - Accessory suture used</Form.Label>
                      <Form.Select
                        value={formData.accessorySutureUsed}
                        onChange={(e) => {onChangeHandler(arrayIndex, "accessorySutureUsed", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="FiberWire">FiberWire</option>
                        <option value="FiberLink">FiberLink</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Humeral Side Lateral Row - Number of knotless anchors used</Form.Label>
                      <Form.Control
                        type="number"
                        step={1}
                        min={0}
                        max={5}
                        value={formData.numberOfKnotlessAnchorsUsed2}
                        onChange={(e) => {onChangeHandler(arrayIndex, "numberOfKnotlessAnchorsUsed2", e.target.value)}}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Superior labrum management</Form.Label>
                      <Form.Select
                        value={formData.superiorLabrumManagement}
                        onChange={(e) => {onChangeHandler(arrayIndex, "superiorLabrumManagement", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="removal/debridement">removal/debridement</option>
                        <option value="graft fixed medial to">graft fixed medial to</option>
                        <option value="other">other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "clavicleFracture": {
      return (
        <div className="operations-clavicle-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Clavicle Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Clavicle Fracture Procedure</Form.Label>
                      <Select
                        isMulti
                        value={clavicleFractureProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={clavicleFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Clavicle Device Manufacturer</Form.Label>
                      <Select
                        isMulti
                        value={clavicleDeviceManufacturereOptions.filter(option => formData.manufacturer.split(",").includes(option.value))}
                        name="manufacturer"
                        options={clavicleDeviceManufacturereOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "manufacturer", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "greaterTuberosityFracture": {
      return (
        <div className="operations-greater-tuberosity-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Greater Tuberosity Fracture 
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Greater Tuberosity Fracture Procedure</Form.Label>
                      <Select
                        isMulti
                        value={greaterTuberosityFractureProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={greaterTuberosityFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Bone graft used</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Autograft bone">Autograft bone</option>
                        <option value="Allograft bone">Allograft bone</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "proximalHumerusFracture": {
      return (
        <div className="operations-proximal-humerus-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Proximal Humerus Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedure Details</Form.Label>
                      <Select
                        isMulti
                        value={proximalHumerusFractureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={proximalHumerusFractureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Bone graft used</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Autograft bone">Autograft bone</option>
                        <option value="Allograft bone">Allograft bone</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Proximal Humerus Device Manufacturer</Form.Label>
                      <Select
                        isMulti
                        value={proximalHumerusFractureDeviceManufacturerOptions.filter(option => formData.manufacturer.split(",").includes(option.value))}
                        name="manufacturer"
                        options={proximalHumerusFractureDeviceManufacturerOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "manufacturer", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "humeralShaftFracture": {
      return (
        <div className="operations-humeral-shaft-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Humeral Shaft Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                     <Form.Label>Procedure details</Form.Label>
                      <Form.Select
                        value={formData.procedures}
                        onChange={(e) => {onChangeHandler(arrayIndex, "procedures", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="ORIF">ORIF</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "glenoidFracture": {
      return (
        <div className="operations-glenoid-fracture">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Glenoid Fracture
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Procedures</Form.Label>
                      <Select
                        isMulti
                        value={glenoidFractureProcedureOptions.filter(option => formData.procedures.split(",").includes(option.value))}
                        name="procedures"
                        options={glenoidFractureProcedureOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues) => {onChangeHandler(arrayIndex, "procedures", selectedValues.map(selectedValue => selectedValue.value).join(","))}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Bone graft used</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Autograft bone">Autograft bone</option>
                        <option value="Allograft bone">Allograft bone</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Glenoid Fracture Device Manufacturer</Form.Label>
                      <Form.Select
                        value={formData.manufacturer}
                        onChange={(e) => {onChangeHandler(arrayIndex, "manufacturer", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Arthrex">Arthrex</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "fusion": {
      return (
        <div className="operations-fusion">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Fusion
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Primary or Revision</Form.Label>
                      <Form.Select
                        value={formData.primaryOrRevision}
                        onChange={(e) => {onChangeHandler(arrayIndex, "primaryOrRevision", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Primary">Primary</option>
                        <option value="Revision">Revision</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Open or Arthroscopic Assistsed</Form.Label>
                      <Form.Select
                        value={formData.openOrArthroscopic}
                        onChange={(e) => {onChangeHandler(arrayIndex, "openOrArthroscopic", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Arthroscopic Assisted">Arthroscopic Assisted</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Type of Fusion</Form.Label>
                      <Form.Select
                        value={formData.type}
                        onChange={(e) => {onChangeHandler(arrayIndex, "type", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Glenohumeral fusion">Glenohumeral fusion</option>
                        <option value="Scapulothoracic fusion">Scapulothoracic fusion</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Label>Bone Graft or Bone Graft Subsitute</Form.Label>
                      <Form.Select
                        value={formData.boneGraft}
                        onChange={(e) => {onChangeHandler(arrayIndex, "boneGraft", e.target.value)}}
                      >
                        <option value="" hidden>Select an option</option>
                        <option value="Bone allograft">Bone allograft</option>
                        <option value="Bone autograft">Bone autograft</option>
                        <option value="Synthetic bone void filler">Synthetic bone void filler</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    case "prostalac": {
      return (
        <div className="operations-prostalac">
          <Accordion>
            <Accordion.Item eventKey="0">
              <div className='accordion-custom-header'>
                <div className='catalog-entry-header'>
                  <Accordion.Header>
                    Prostalac
                  </Accordion.Header>
                  <div className='catalog-entry-preview'>
                    {
                      Object.entries(formData).map(([key, value], index) => {
                        return (typeof(value) === "string" && value !== "") || (typeof(value === "boolean") && value) ? <span key={index}><span className='catalog-entry-preview-key'>✔ {key.replace(/([a-z]+|[A-Z][a-z]+|\d+)/g, "$1 ").toLowerCase()}:</span> {String(value).toLowerCase()}&emsp;&emsp;&emsp;</span> : null
                      })
                    }
                  </div>
                </div>
                <div className='remove-catalog-entry-button-wrapper'>
                    <div className="remove-catalog-entry-button" onClick={() => onRemoveHandler(arrayIndex)}><RxCross1/></div>
                </div>
              </div>
              <Accordion.Body>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>No further details</Form.Label>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )
    }
    default: {
      return null
    }
  }

}

export default OperationsAndImplants
