import React from "react";
import { Form } from "react-bootstrap";

import './ASESElbow.scss';

const ASESElbow = ({ handleFormData, values }) => {
  return (
    <div className="self-assessment-test ases">
      <p className="form-title">ASES</p>      
      <Form.Group className="form-group">
        <Form.Label>1. Heeft u pijn in uw elleboog?</Form.Label>
        <Form.Text className="text-muted">
        </Form.Text>
        <Form.Select
          required
          name="question1"
          value={values.ases.asesQuestion1}
          onChange={(event) => handleFormData("ases", "asesQuestion1", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Ja">Ja</option>
          <option value="Nee">Nee</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
      <Form.Label>2. Beoordeel uw pijn: Wanneer het op zijn ergst is</Form.Label>
      <Form.Text>Van 0 (helemaal geen pijn) tot 10 (ergst denkbare pijn)</Form.Text>
      <div className="pain-slider">
        <Form.Range
          required
          value={values.ases.asesQuestion2}
          min="0" max="10" step="1"
          onChange={(event) => handleFormData("ases", "asesQuestion2", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="10" step="1" value={values.ases.asesQuestion2} onChange={(event) => handleFormData("ases", "asesQuestion2", event.target.value.trim())}/>
      </div>
      </Form.Group>

      <Form.Group className="form-group">
      <Form.Label>3. Beoordeel uw pijn: In ruststand</Form.Label>
      <Form.Text>Van 0 (helemaal geen pijn) tot 10 (ergst denkbare pijn)</Form.Text>
      <div className="pain-slider">
        <Form.Range
          required
          value={values.ases.asesQuestion3}
          min="0" max="10" step="1"
          onChange={(event) => handleFormData("ases", "asesQuestion3", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="10" step="1" value={values.ases.asesQuestion3} onChange={(event) => handleFormData("ases", "asesQuestion3", event.target.value.trim())}/>
      </div>
      </Form.Group>
      
      <Form.Group className="form-group">
      <Form.Label>4. Beoordeel uw pijn: Bij het tillen van een zwaar voorwerp</Form.Label>
      <Form.Text>Van 0 (helemaal geen pijn) tot 10 (ergst denkbare pijn)</Form.Text>
      <div className="pain-slider">
        <Form.Range
          required
          value={values.ases.asesQuestion4}
          min="0" max="10" step="1"
          onChange={(event) => handleFormData("ases", "asesQuestion4", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="10" step="1" value={values.ases.asesQuestion4} onChange={(event) => handleFormData("ases", "asesQuestion4", event.target.value)}/>
      </div>
      </Form.Group>
      
      <Form.Group className="form-group">
      <Form.Label>5. Beoordeel uw pijn: Bij het doen van een taak met herhaalde elleboogbewegingen</Form.Label>
      <Form.Text>Van 0 (helemaal geen pijn) tot 10 (ergst denkbare pijn)</Form.Text>
      <div className="pain-slider">
        <Form.Range
          required
          value={values.ases.asesQuestion5}
          min="0" max="10" step="1"
          onChange={(event) => handleFormData("ases", "asesQuestion5", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="10" step="1" value={values.ases.asesQuestion5} onChange={(event) => handleFormData("ases", "asesQuestion5", event.target.value.trim())}/>
      </div>
      </Form.Group>
      
      <Form.Group className="form-group">
      <Form.Label>6. Beoordeel uw pijn: 's Nachts</Form.Label>
      <Form.Text>Van 0 (helemaal geen pijn) tot 10 (ergst denkbare pijn)</Form.Text>
      <div className="pain-slider">
        <Form.Range
          required
          value={values.ases.asesQuestion6}
          min="0" max="10" step="1"
          onChange={(event) => handleFormData("ases", "asesQuestion6", event.target.value)}
        />
        <Form.Control required type="number" min="0" max="10" step="1" value={values.ases.asesQuestion6} onChange={(event) => handleFormData("ases", "asesQuestion6", event.target.value.trim())}/>
      </div>
      </Form.Group>

      <p className="form-sub-title">Kies het antwoord dat uw huidige status het best beschrijft bij het doen van de volgende activiteiten:</p>      

      <Form.Group className="form-group">
        <Form.Label>7. Bovenste knoop van overhemd openen/sluiten</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion7}
          onChange={(event) => handleFormData("ases", "asesQuestion7", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="form-group">
        <Form.Label>8. Gebruik van toilet</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion8}
          onChange={(event) => handleFormData("ases", "asesQuestion8", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>9. Haar kammen</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion9}
          onChange={(event) => handleFormData("ases", "asesQuestion9", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>10. Schoenveters strikken</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion10}
          onChange={(event) => handleFormData("ases", "asesQuestion10", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>11. Eten met bestek</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion11}
          onChange={(event) => handleFormData("ases", "asesQuestion11", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>12. Een zwaar voorwerp dragen</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion12}
          onChange={(event) => handleFormData("ases", "asesQuestion12", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>13. Uit stoel opstaan met hulp van arm</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion13}
          onChange={(event) => handleFormData("ases", "asesQuestion13", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>14. Zware huishoudelijke taken uitvoeren</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion14}
          onChange={(event) => handleFormData("ases", "asesQuestion14", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>15. Een sleutel omdraaien</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion15}
          onChange={(event) => handleFormData("ases", "asesQuestion15", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>16. Een bal gooien</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion16}
          onChange={(event) => handleFormData("ases", "asesQuestion16", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>17. Gebruikelijke werkzaamheden uitvoeren</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion17}
          onChange={(event) => handleFormData("ases", "asesQuestion17", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    
      <Form.Group className="form-group">
        <Form.Label>18. Gebruikelijke sport beoefenen</Form.Label>
        <Form.Select
          required
          value={values.ases.asesQuestion18}
          onChange={(event) => handleFormData("ases", "asesQuestion18", event.target.value)}
        >
          <option value="" hidden>Maak een keuze</option>
          <option value="Niet in staat om te doen">Niet in staat om te doen</option>
          <option value="Zeer moeilijk te doen">Zeer moeilijk te doen</option>
          <option value="Enigszins moeilijk">Enigszins moeilijk</option>
          <option value="Niet moeilijk">Niet moeilijk</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}

export default ASESElbow