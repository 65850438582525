import { React, useState, useEffect} from 'react'
import validator from 'validator';
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Form, Card } from "react-bootstrap";

import VAS from "../../../components/SelfAssessment/VAS/VAS";
import ASESElbow from '../../../components/SelfAssessment/ASESElbow/ASESElbow';
import ASESShoulder from '../../../components/SelfAssessment/ASESShoulder/ASESShoulder';
import SANE from "../../../components/SelfAssessment/SANE/SANE";
import VR12 from '../../../components/SelfAssessment/VR12/VR12';
import StandardPreOp from '../../../components/SelfAssessment/StandardPreOp/StandardPreOp';
import FormCompletion from '../../../components/SelfAssessment/FormCompletion/FormCompletion';
import MultiStepProgressBar from '../../../components/MultiStepProgressBar/MultiStepProgressBar';
import { BASE_URL } from "../../../utils/constants";
// import Final from "./Components/Forms/Final";

import './PreTreatment.scss'
import TermsAndConditions from '../../../components/SelfAssessment/TermsAndConditions/TermsAndConditions';
import SurveyAlreadyCompleted from '../../../components/SelfAssessment/SurveyAlreadyCompleted/SurveyAlreadyCompleted';

const PreTreatment = () => {
  //state for steps
  const numberOfSteps = 5;
  const [termsAndConditionsStatus, setTermsAndConditionsStatus] = useState({completed: false, accepted: false})
  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const [step, setStep] = useState(1);

  const { eventId, customPatientId } = useParams();
  const treatmentSide = useParams()["treatmentSide"] === "left"? "Links" : "Rechts"
  const study = useParams()["study"].replace("-", " ")
  const navigate = useNavigate();
  useEffect(() => {
    if (!["elbow", "shoulder arthroscopy", "shoulder arthroplasty"].includes(study)) {
      navigate("/404")
    }
  });
  const [validated, setValidated] = useState(false);
  
  const [formData, setFormData] = useState({
    vasScore: "",
    saneScore: "",
    ases: {
      asesQuestion1: "",
      asesQuestion2: "",
      asesQuestion3: "",
      asesQuestion4: "",
      asesQuestion5: "",
      asesQuestion6: "",
      asesQuestion7: "",
      asesQuestion8: "",
      asesQuestion9: "",
      asesQuestion10: "",
      asesQuestion11: "",
      asesQuestion12: "",
      asesQuestion13: "",
      asesQuestion14: "",
      asesQuestion15: "",
      asesQuestion16: "",
      asesQuestion17: "",
      asesQuestion18: ""
    },
    vr12: {
      vr12Question1: "",
      vr12Question2: "",
      vr12Question3: "",
      vr12Question4: "",
      vr12Question5: "",
      vr12Question6: "",
      vr12Question7: "",
      vr12Question8: "",
      vr12Question9: "",
      vr12Question10: "",
      vr12Question11: "",
      vr12Question12: "",
      vr12Question13: "",
      vr12Question14: ""
    },
    spreop: {
      spreopQuestion1: "",
      spreopQuestion2: "",
      spreopQuestion3: "",
      spreopQuestion4: ""
    }
  })
  
  useEffect(() => {
    const checkSurveyCompletion = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/v1/self-assessment/check-survey-completion/${customPatientId}/${eventId}/pre_treatment`)
        setSurveyCompleted(response.data.completed)
      }
      catch (error) {
        console.log(error)
      }
    }
    checkSurveyCompletion()
  }, [customPatientId, eventId])


  const handleTermsAndConditionsAccept = () => {
    setTermsAndConditionsStatus({
      completed: true,
      accepted: true
    })
  }

  const handleTermsAndConditionsReject = async () => {
    setTermsAndConditionsStatus({
      completed: true,
      accepted: false
    })
    const data = {
      customPatientId: customPatientId,
      eventId: eventId
    }
    try {
      await axios.post(`${BASE_URL}/api/v1/self-assessment/terms-and-conditions-rejected`, data)

      setStep(numberOfSteps + 1)
    }
    catch (error) {
      console.log(error)
    }
  }
  
  const nextStep = () => {
    const formError = validateCurrentForm()
    setValidated(true);
    if(!formError) {
      setValidated(false);
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleInputData = (type, input, value) => {
    switch(type) {
      case "ases":
        setFormData(prevState => {
          prevState.ases[input] = value
          return{...prevState}
        });
     break; 
      case "vr12":
        setFormData(prevState => {
          prevState.vr12[input] = value
          return{...prevState}
        });
      break;
      case "spreop":
        setFormData(prevState => {
          prevState.spreop[input] = value
          return{...prevState}
        });
      break;
      default:
        setFormData(prevState => {
          prevState[input] = value
          return{...prevState}
        });
      
    } 
  }

  const handleSubmit = async (event, type) => {
    event.preventDefault();
    const formError = validateCurrentForm()
    if(!formError) {
      const data = {
        eventId: eventId,
        customPatientId: customPatientId,
        study: study,
        termsAndConditionsAccepted: termsAndConditionsStatus.accepted,
        formAnswers: formData
      }
      try {
        await axios.post(`${BASE_URL}/api/v1/self-assessment/pre-treatment`, data)
        setStep(numberOfSteps + 1);
      }
      catch(error) {
        console.log(error.response.data)
        // switch to error screen
      }
    }
    else {
      setValidated(true);
    }
  };

  const validateCurrentForm = () =>{
    let formError = false
    switch(step) {
      case 1: 
        formError = validator.isEmpty(formData.vasScore) || !validator.isFloat(formData.vasScore, { min: 0, max: 10 })
        break;
      case 2: 
        const j = (study === "elbow"? 18 : 17)
        for(let i = 1; i <= j; i++) {
          if(validator.isEmpty(formData.ases[`asesQuestion${i}`])){
            formError = true
            break; 
          }
          if(study !== "elbow" && i === 5 && !validator.isInt(formData.ases[`asesQuestion${i}`], { min: 0, max: 20 })) {
            formError = true
            break;
          }
          if(study !== "elbow" && i === 7 && !validator.isFloat(formData.ases[`asesQuestion${i}`], { min: 0, max: 10 })) {
            formError = true
            break;
          }
          if(study === "elbow" && [2, 3, 4, 5, 6].includes(i) && !validator.isInt(formData.ases[`asesQuestion${i}`], { min: 0, max: 10 })) {
            formError = true
            break;
          }
        }
        break;
      case 3: 
        formError = validator.isEmpty(formData.saneScore) || !validator.isInt(formData.saneScore, { min: 0, max: 100 }); 
        break;
      case 4: {
        for(let i = 1; i <= 4; i++) {
          if(validator.isEmpty(formData.spreop[`spreopQuestion${i}`])){
            formError = true;
            break;
          }
        } break;
      }
      case 5: {
        for(let i = 1; i <= 14; i++) {
          if(validator.isEmpty(formData.vr12[`vr12Question${i}`])){
            formError = true;
            break;
          }
        } break;
      }
      default: return formError;
    };
    return formError
  }

  const renderFormContent = step => {
    switch (step){
      case 1:
        return <VAS handleFormData={handleInputData} values={formData}/>
      case 2:
        if(study === "elbow") {
          return <ASESElbow handleFormData={handleInputData} values={formData}/>
        }
        else {
          return <ASESShoulder handleFormData={handleInputData} values={formData}/>
        }
      case 3:
        return <SANE handleFormData={handleInputData} study={study} values={formData}/>
      case 4:
        return <StandardPreOp handleFormData={handleInputData} values={formData}/>
      case 5:
        return <VR12 handleFormData={handleInputData} values={formData}/>
      case 6:
        return <FormCompletion />
      default:
        return <div></div>
    }
  }

  return (
    <div className="pre-treatment">
    <Form onSubmit={handleSubmit} validated={validated}>
      <Card>
        <Card.Header>{study === "elbow"? "Elleboog" : "Schouder"} ({treatmentSide}) Pre-behandeling Zelfevaluatie</Card.Header>
        {!surveyCompleted? 
          (
            <>
              {!termsAndConditionsStatus.completed? 
                (
                  <TermsAndConditions handleTermsAndConditionsAccept={handleTermsAndConditionsAccept} handleTermsAndConditionsReject={handleTermsAndConditionsReject}/>
                )
                :
                (
                  <>
                    <Card.Body>
                    {termsAndConditionsStatus.accepted ? 
                    (
                      <Card.Title>
                        <MultiStepProgressBar className="form-progress-bar" currentStep={step} numberOfSteps={numberOfSteps + 1} />
                      </Card.Title>
                    ) : null}
                      <Container className="form-content">
                        {
                          renderFormContent(step)
                        }
                      </Container>
                    </Card.Body>
                    {step < (numberOfSteps + 1) ? (
                      <Card.Footer style={step === 1? {justifyContent: "flex-end"} : {justifyContent: "space-between"}}>
                      {step !== 1? <Button className="btn-secondary float-start" onClick={prevStep}>Vorige Stap</Button> : null}
                      {step < numberOfSteps? <Button className="btn-primary float-end" onClick={nextStep}>Volgende Stap</Button> : null}
                      {step === numberOfSteps? <Button className="btn-primary float-end" onClick={handleSubmit}>Indienen</Button> : null}
                      </Card.Footer>
                    ) : null}
                  </>
                )
              }
            </>
            ) :
          (
            <SurveyAlreadyCompleted />
          )
        }
      </Card>
    </Form>
    </div>
  )
}

export default PreTreatment
    