import React from 'react'
import { useRouteError } from "react-router-dom";

import './NotFound.scss'

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>Oops!</h1>
      <p>The page u are looking for can't be found.</p>
    </div>
  )
}

export default NotFound